import AutoFillGrid from "@/components/AutoFillGrid";
import { BodyContainer } from "@/components/Layout";
import { InlineSpinner } from "@/components/Spinner";
import SupportResource from "@/components/SupportResource";
import useDialogController from "@/hooks/useDialogController.tsx";
import { useQueryOptionsFactory } from "@/queries";
import ClearIcon from "@mui/icons-material/Clear";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import HelpIcon from "@mui/icons-material/Help";
import SearchIcon from "@mui/icons-material/Search";
import {
    Alert,
    Box,
    Button,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { type ReactNode, useMemo, useState } from "react";
import AddFileDialog from "./-components/AddFileDialog.tsx";
import FileCard from "./-components/FileCard.tsx";

const Root = (): ReactNode => {
    const { clientId } = Route.useParams();
    const qof = useQueryOptionsFactory();
    const dialogController = useDialogController();
    const { data: files } = useSuspenseQuery(qof.fileLocker.listFiles(clientId));
    const [filter, setFilter] = useState("");

    const filteredFiles = useMemo(
        () =>
            files.filter((file) => {
                if (!filter) {
                    return true;
                }

                const normalizedFilter = filter.toLocaleLowerCase();
                const filename = file.filename.toLocaleLowerCase();
                const description = file.description.toLocaleLowerCase();

                return (
                    filename.includes(normalizedFilter) || description.includes(normalizedFilter)
                );
            }),
        [filter, files],
    );

    let content: ReactNode;

    if (files.length === 0) {
        content = (
            <Alert severity="info">You haven't added any files to your FileLocker yet.</Alert>
        );
    } else {
        const searchField = (
            <TextField
                label="Search"
                size="small"
                value={filter}
                onChange={(event) => {
                    setFilter(event.target.value);
                }}
                slotProps={{
                    input: {
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: filter ? (
                            <InputAdornment position="end">
                                <IconButton
                                    size="small"
                                    edge="end"
                                    onClick={() => {
                                        setFilter("");
                                    }}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        ) : undefined,
                    },
                }}
                sx={{ mb: 2, width: "100%", maxWidth: 400 }}
            />
        );

        if (filteredFiles.length === 0) {
            content = (
                <>
                    {searchField}
                    <Typography>No files match your search.</Typography>
                </>
            );
        } else {
            content = (
                <>
                    {searchField}
                    <AutoFillGrid minWidth={350}>
                        {filteredFiles.map((file) => (
                            <FileCard file={file} clientId={clientId} key={file.id} />
                        ))}
                    </AutoFillGrid>
                </>
            );
        }
    }

    return (
        <BodyContainer
            header={
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h5" sx={{ mr: "auto" }}>
                        File Locker
                    </Typography>
                    <Button
                        size="small"
                        startIcon={<CloudUploadIcon />}
                        onClick={dialogController.open}
                    >
                        Add file
                    </Button>
                    <SupportResource
                        type="file_locker"
                        renderButton={(open) => (
                            <Tooltip title="Managing File Locker">
                                <IconButton onClick={open} edge="end" sx={{ ml: 2 }}>
                                    <HelpIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    />
                </Box>
            }
        >
            {content}
            {dialogController.mount && (
                <AddFileDialog dialogProps={dialogController.dialogProps} clientId={clientId} />
            )}
        </BodyContainer>
    );
};
export const Route = createFileRoute("/$clientId/file-locker/")({
    component: Root,
    pendingComponent: () => (
        <BodyContainer>
            <InlineSpinner />
        </BodyContainer>
    ),
    loader: async ({ context, params }) => {
        await context.queryClient.ensureQueryData(
            context.qof.fileLocker.listFiles(params.clientId),
        );
    },
});
