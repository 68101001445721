import AutoFillGrid from "@/components/AutoFillGrid";
import { BodyContainer } from "@/components/Layout";
import { InlineSpinner } from "@/components/Spinner";
import SupportResource from "@/components/SupportResource";
import { useQueryOptionsFactory } from "@/queries";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { sortCollator } from "@/utils/intl.ts";
import HelpIcon from "@mui/icons-material/Help";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import type { ReactNode } from "react";
import HostCard from "./-components/HostCard.tsx";

const Root = (): ReactNode => {
    const { id: clientId } = useActiveClient();
    const qof = useQueryOptionsFactory();
    const hosts = [...useSuspenseQuery(qof.host.list(clientId)).data].sort((a, b) =>
        sortCollator.compare(a.name, b.name),
    );

    return (
        <BodyContainer
            header={
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h5" sx={{ mr: "auto" }}>
                        Hosts
                    </Typography>

                    <SupportResource
                        type="hosts"
                        renderButton={(open) => (
                            <Tooltip title="Managing Hosts">
                                <IconButton onClick={open} edge="end">
                                    <HelpIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    />
                </Box>
            }
        >
            <AutoFillGrid minWidth={350}>
                {hosts.map((host) => (
                    <HostCard key={host.id} host={host} />
                ))}
            </AutoFillGrid>
        </BodyContainer>
    );
};

export const Route = createFileRoute("/$clientId/hosts/")({
    component: Root,
    pendingComponent: () => (
        <BodyContainer>
            <InlineSpinner />
        </BodyContainer>
    ),
    loader: async ({ context, params }) => {
        await context.queryClient.ensureQueryData(context.qof.host.list(params.clientId));
    },
});
