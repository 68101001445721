import { useActiveClient } from "@/routes/$clientId.tsx";
import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton, type IconButtonProps } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { type ReactNode, useState } from "react";

type Props = {
    hostId: string;
    edge?: IconButtonProps["edge"];
};

const RefreshButton = ({ hostId, edge }: Props): ReactNode => {
    const { id: clientId } = useActiveClient();
    const queryClient = useQueryClient();
    const [refreshing, setRefreshing] = useState(false);

    const handleRefresh = () => {
        setRefreshing(true);

        queryClient
            .refetchQueries({
                queryKey: ["client", clientId, "host", hostId],
            })
            .finally(() => {
                setRefreshing(false);
            });
    };

    return (
        <IconButton
            disabled={refreshing}
            edge={edge}
            onClick={handleRefresh}
            sx={
                refreshing
                    ? {
                          animation: "spin 1s linear infinite",
                          "@keyframes spin": {
                              "0%": {
                                  transform: "rotate(0deg)",
                              },
                              "100%": {
                                  transform: "rotate(360deg)",
                              },
                          },
                      }
                    : undefined
            }
        >
            <RefreshIcon />
        </IconButton>
    );
};

export default RefreshButton;
