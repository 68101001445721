import CardField from "@/components/CardField";
import type { DisasterRecoveryConfiguration } from "@/queries/disaster-recovery.ts";
import { Paper, Stack } from "@mui/material";
import type { ReactNode } from "react";
import { match } from "ts-pattern";

type Props = {
    configuration: DisasterRecoveryConfiguration;
};

const ConfigurationCard = ({ configuration }: Props): ReactNode => {
    return (
        <Paper sx={{ p: 2 }}>
            <Stack
                direction={{ xs: "column", lg: "row" }}
                sx={{
                    columnGap: 4,
                    rowGap: 2,
                }}
            >
                <Stack
                    sx={{
                        columnGap: 4,
                        rowGap: 2,
                        mr: { lg: "auto" },
                    }}
                >
                    <CardField
                        label="Model"
                        value={match(configuration.model)
                            .with("on_premise", () => "On Premise")
                            .with("cross_region", () => "Cross Region")
                            .exhaustive()}
                    />
                    <CardField label="Mode" value={configuration.modeDescription} />
                    <CardField label="Package source" value={configuration.packageSource} />
                </Stack>
                <Stack
                    sx={{
                        columnGap: 4,
                        rowGap: 2,
                    }}
                >
                    <CardField label="Identity" value={configuration.identity} />
                    {configuration.additionalFolders.length > 0 && (
                        <CardField
                            label="Additional folders"
                            value={configuration.additionalFolders
                                .map((value) =>
                                    match(value)
                                        .with("container", () => "Container")
                                        .with("database", () => "Database")
                                        .exhaustive(),
                                )
                                .join(", ")}
                        />
                    )}
                </Stack>
            </Stack>
        </Paper>
    );
};
export default ConfigurationCard;
