import type { ControlledDialogProps } from "@/hooks/useDialogController.tsx";
import { useUpdateSnapBackMutation } from "@/mutations/snapback.ts";
import type { SnapBack } from "@/queries/snapback.ts";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { useActiveHost } from "@/routes/$clientId/hosts/$hostId.tsx";
import { getErrorMessage } from "@/utils/api.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormHelperText,
} from "@mui/material";
import { RhfSwitch, RhfTextField } from "mui-rhf-integration";
import { useSnackbar } from "notistack";
import { type ReactNode, useCallback } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z.object({
    preserved: z.boolean(),
    description: z.string().trim().min(1),
});

type FieldValues = z.input<typeof schema>;
type TransformedValues = z.output<typeof schema>;

type Props = {
    dialogProps: ControlledDialogProps;
    snapBack: SnapBack;
};

const EditSnapBackDialog = ({ dialogProps, snapBack }: Props): ReactNode => {
    const { id: clientId } = useActiveClient();
    const { id: hostId } = useActiveHost();
    const updateMutation = useUpdateSnapBackMutation(clientId);
    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema),
        defaultValues: {
            preserved: snapBack.preserved,
            description: snapBack.description,
        },
    });
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = useCallback(
        (values: TransformedValues) => {
            updateMutation.mutate(
                {
                    hostId,
                    snapBackId: snapBack.id,
                    ...values,
                },
                {
                    onSuccess: (message) => {
                        enqueueSnackbar(message, { variant: "success" });
                        dialogProps.onClose();
                    },
                    onError: (error) => {
                        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                    },
                },
            );
        },
        [enqueueSnackbar, hostId, snapBack.id, updateMutation, dialogProps.onClose],
    );

    return (
        <Dialog
            {...dialogProps}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                component: "form",
                noValidate: true,
                onSubmit: form.handleSubmit(handleSubmit),
            }}
        >
            <DialogTitle>Edit SnapBack</DialogTitle>
            <DialogContent dividers>
                <FormControlLabel
                    control={<RhfSwitch control={form.control} name="preserved" />}
                    label="Preserved"
                />
                <FormHelperText sx={{ mb: 3 }}>
                    Turning preservation off may remove your ability to modify based on the age and
                    default retention rules.
                </FormHelperText>

                <RhfTextField
                    control={form.control}
                    name="description"
                    label="Description"
                    required
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button color="inherit" onClick={dialogProps.onClose}>
                    Cancel
                </Button>
                <LoadingButton loading={updateMutation.isPending} type="submit">
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default EditSnapBackDialog;
