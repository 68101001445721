import useDialogController from "@/hooks/useDialogController.tsx";
import type { HostVolume } from "@/queries/host-volume.ts";
import FreeSpaceState from "@/routes/$clientId/hosts/$hostId/volumes/-components/FreeSpaceState.tsx";
import BuildIcon from "@mui/icons-material/Build";
import StorageIcon from "@mui/icons-material/Storage";
import {
    Card,
    CardContent,
    CardHeader,
    IconButton,
    LinearProgress,
    Typography,
} from "@mui/material";
import humanFormat from "human-format";
import type { ReactNode } from "react";
import { createPortal } from "react-dom";
import ResizeDialog from "./ResizeDialog.tsx";

type Props = {
    hostId: string;
    volume: HostVolume;
};

const VolumeCard = ({ hostId, volume }: Props): ReactNode => {
    const dialogController = useDialogController();

    return (
        <>
            <Card>
                <CardHeader
                    avatar={<StorageIcon />}
                    title={volume.usage}
                    subheader={<FreeSpaceState volume={volume} />}
                    action={
                        <IconButton onClick={dialogController.open} disabled={!volume.modifiable}>
                            <BuildIcon />
                        </IconButton>
                    }
                />
                <CardContent>
                    <LinearProgress
                        variant="determinate"
                        value={(volume.size.used / volume.size.allocated) * 100}
                        sx={{ height: 10, borderRadius: 5 }}
                    />
                    <Typography
                        sx={{
                            mt: 1,
                        }}
                    >
                        {humanFormat(
                            (volume.size.allocated - volume.size.used) * 1000 * 1000 * 1000,
                            { unit: "B" },
                        )}
                        {" free of "}
                        {humanFormat(volume.size.allocated * 1000 * 1000 * 1000, { unit: "B" })}
                    </Typography>
                </CardContent>
            </Card>
            {dialogController.mount &&
                createPortal(
                    <ResizeDialog
                        dialogProps={dialogController.dialogProps}
                        volume={volume}
                        hostId={hostId}
                    />,
                    document.body,
                )}
        </>
    );
};
export default VolumeCard;
