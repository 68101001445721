import CardField from "@/components/CardField";
import ConfirmDialog from "@/components/ConfirmDialog";
import { usePerformSnapBackMutation } from "@/mutations/snapback.ts";
import type { SnapBackConfiguration } from "@/queries/snapback.ts";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { useActiveHost } from "@/routes/$clientId/hosts/$hostId.tsx";
import { getErrorMessage } from "@/utils/api.ts";
import { Button, Paper, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { type ReactNode, useCallback } from "react";
import { useConfirm } from "react-confirm-hook";

type Props = {
    configuration: SnapBackConfiguration;
    performDisabled: boolean;
};

const ConfigurationCard = ({ configuration, performDisabled }: Props): ReactNode => {
    const { id: clientId } = useActiveClient();
    const { id: hostId } = useActiveHost();
    const performMutation = usePerformSnapBackMutation(clientId);
    const { enqueueSnackbar } = useSnackbar();
    const confirm = useConfirm(ConfirmDialog);

    const handlePerform = useCallback(() => {
        confirm({
            title: "Perform SnapBack",
            message: "Do you really want to perform a SnapBack?",
            onConfirm: async () => {
                try {
                    const message = await performMutation.mutateAsync({ hostId });
                    enqueueSnackbar(message, { variant: "success" });
                } catch (error) {
                    enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                }
            },
        });
    }, [performMutation, hostId, enqueueSnackbar, confirm]);

    const retention = [
        `${configuration.retention.daily} daily`,
        `${configuration.retention.weekly} weekly`,
        `${configuration.retention.monthly} monthly`,
    ].join(" / ");

    return (
        <Paper sx={{ p: 2 }}>
            <Stack
                direction={{ xs: "column", sm: "row" }}
                sx={{
                    columnGap: 4,
                    rowGap: 2,
                }}
            >
                <CardField label="Model" value={configuration.model} />
                <CardField label="Retention" value={retention} />

                <Button
                    disabled={performDisabled}
                    onClick={handlePerform}
                    sx={{ ml: { xs: 0, sm: "auto" }, mr: { xs: "auto", sm: 0 } }}
                    variant="outlined"
                >
                    Perform SnapBack
                </Button>
            </Stack>
        </Paper>
    );
};
export default ConfigurationCard;
