import type { AuthenticatedFetch } from "@/hooks/useAuthenticatedFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { queryOptions } from "@tanstack/react-query";
import {
    createDataSelector,
    createResourceCollectionSelector,
    createResourceSelector,
    handleJsonApiError,
} from "jsonapi-zod-query";
import { z } from "zod";
import { zj } from "zod-joda";

const workerAttributesSchema = z.object({
    hostname: z.string().min(1),
    startedAt: zj.zonedDateTime(),
    statusMessage: z.string().min(1),
});

const workersSelector = createDataSelector(
    createResourceCollectionSelector({
        type: "webdirect_auto_scaling_worker",
        attributesSchema: workerAttributesSchema,
    }),
);

export type WebdirectAutoScalingWorker = ReturnType<typeof workersSelector>[number];

const configurationAttributesSchema = z.object({
    instanceType: z.string().min(1),
    cooldownTime: z.number().int().nonnegative(),
    masterUsers: z.number().int().nonnegative(),
    utilizationThreshold: z.number().int().min(0).max(100),
    dailySchedule: z
        .object({
            workers: z.number().int().min(1),
            startsAt: zj.localTime(),
            endsAt: zj.localTime(),
            includeWeekends: z.boolean(),
        })
        .nullable(),
});

const configurationMetaSchema = z.object({
    availableOptions: z.object({
        instanceTypes: z.array(z.string().min(1)),
        cooldownTimes: z.array(z.number().int().nonnegative()),
        maxWorkers: z.number().int().min(1),
        maxMasterUsers: z.number().int().min(1),
        utilizationThresholds: z.array(z.number().int().min(0).max(100)),
    }),
});

const configurationSelector = createResourceSelector({
    type: "webdirect_auto_scaling_configuration",
    attributesSchema: configurationAttributesSchema,
    documentMetaSchema: configurationMetaSchema,
});

export type WebdirectAutoScalingConfigurationAvailableOptions = ReturnType<
    typeof configurationSelector
>["meta"]["availableOptions"];
export type WebdirectAutoScalingConfiguration = ReturnType<typeof configurationSelector>["data"];

export const createWebdirectAutoScalingQueryOptionsFactory = (authFetch: AuthenticatedFetch) => ({
    getConfiguration: (clientId: string, hostId: string) =>
        queryOptions({
            queryKey: [
                "client",
                clientId,
                "host",
                hostId,
                "webdirect-auto-scaling",
                "configuration",
            ],
            queryFn: async ({ signal }) => {
                const url = apiUrl(
                    `/clients/${clientId}/hosts/${hostId}/webdirect-auto-scaling/configuration`,
                );
                const response = await authFetch(url, { signal });
                await handleJsonApiError(response);
                return configurationSelector(await response.json());
            },
        }),
    listWorkers: (clientId: string, hostId: string) =>
        queryOptions({
            queryKey: ["client", clientId, "host", hostId, "webdirect-auto-scaling", "workers"],
            queryFn: async ({ signal }) => {
                const url = apiUrl(
                    `/clients/${clientId}/hosts/${hostId}/webdirect-auto-scaling/workers`,
                );
                const response = await authFetch(url, { signal });
                await handleJsonApiError(response);
                return workersSelector(await response.json());
            },
        }),
});
