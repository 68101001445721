import { TimeZone, useTimeZoneContext } from "@/components/TimeZoneProvider";
import TuneIcon from "@mui/icons-material/Tune";
import {
    IconButton,
    type IconButtonProps,
    ListItem,
    Menu,
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import type { ReactNode } from "react";

type Props = {
    edge?: IconButtonProps["edge"];
};

const ViewSettingsButton = ({ edge }: Props): ReactNode => {
    const state = usePopupState({ variant: "popover", popupId: "host-menu" });
    const { timeZone, updateTimeZone } = useTimeZoneContext();

    return (
        <>
            <IconButton edge={edge} {...bindTrigger(state)}>
                <TuneIcon />
            </IconButton>

            <Menu
                {...bindMenu(state)}
                slotProps={{
                    paper: { sx: { width: 400 } },
                }}
            >
                <ListItem>Display times in timezone:</ListItem>
                <ListItem>
                    <ToggleButtonGroup
                        fullWidth
                        exclusive
                        value={timeZone}
                        size="small"
                        onChange={(_event, timeZone) => {
                            if (timeZone) {
                                updateTimeZone(timeZone as TimeZone);
                            }
                        }}
                    >
                        <ToggleButton value={TimeZone.Host}>Host</ToggleButton>
                        <ToggleButton value={TimeZone.Local}>Local</ToggleButton>
                        <ToggleButton value={TimeZone.Utc}>UTC</ToggleButton>
                    </ToggleButtonGroup>
                </ListItem>
            </Menu>
        </>
    );
};

export default ViewSettingsButton;
