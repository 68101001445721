import { Box, type SxProps, Typography, type TypographyProps } from "@mui/material";
import type { ReactNode } from "react";

type Props = {
    label: ReactNode;
    value: ReactNode;
    valueColor?: TypographyProps["color"];
    preventOverflow?: boolean;
    sx?: SxProps;
};

const CardField = ({
    label,
    value,
    valueColor = "textPrimary",
    preventOverflow,
    sx,
}: Props): ReactNode => (
    <Box sx={sx}>
        <Typography
            component="div"
            variant="body2"
            sx={{
                color: "textSecondary",
            }}
        >
            {label}
        </Typography>
        <Typography
            component="div"
            variant="body1"
            sx={{
                color: valueColor,
                ...(preventOverflow
                    ? {
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                      }
                    : undefined),
            }}
        >
            {value}
        </Typography>
    </Box>
);
export default CardField;
