import { BodyContainer } from "@/components/Layout";
import { InlineSpinner } from "@/components/Spinner";
import SupportResource from "@/components/SupportResource";
import HelpIcon from "@mui/icons-material/Help";
import { Box, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import { createFileRoute } from "@tanstack/react-router";
import type { ReactNode } from "react";
import InvoicesSection from "./-components/InvoicesSection";
import PaymentMethodSection from "./-components/PaymentMethodSection";

const Root = (): ReactNode => {
    return (
        <BodyContainer
            header={
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h5" sx={{ mr: "auto" }}>
                        Billing
                    </Typography>

                    <SupportResource
                        type="billing"
                        renderButton={(open) => (
                            <Tooltip title="Managing Billing">
                                <IconButton onClick={open} edge="end">
                                    <HelpIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    />
                </Box>
            }
        >
            <PaymentMethodSection />
            <Divider sx={{ my: 2 }} />
            <InvoicesSection />
        </BodyContainer>
    );
};

export const Route = createFileRoute("/$clientId/billing/")({
    component: Root,
    pendingComponent: () => (
        <BodyContainer>
            <InlineSpinner />
        </BodyContainer>
    ),
    loader: async ({ context, params }) => {
        await Promise.all([
            context.queryClient.ensureQueryData(context.qof.invoice.list(params.clientId)),
            context.queryClient.ensureQueryData(context.qof.stripe.getSetupIntent(params.clientId)),
            context.queryClient.ensureQueryData(
                context.qof.stripe.getPaymentMethod(params.clientId),
            ),
        ]);
    },
});
