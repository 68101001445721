import CardField from "@/components/CardField";
import { useTimeZoneContext } from "@/components/TimeZoneProvider";
import useDialogController from "@/hooks/useDialogController.tsx";
import type { PrivateBackupLogEntry } from "@/queries/private-backup.ts";
import { zonedDateTimeFormatter } from "@/utils/format.ts";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SourceIcon from "@mui/icons-material/Source";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import prettyMilliseconds from "pretty-ms";
import type { ReactNode } from "react";

type Props = {
    logEntry: PrivateBackupLogEntry;
};

const LogEntryListItem = ({ logEntry }: Props): ReactNode => {
    const dialogController = useDialogController();
    const { zoneId } = useTimeZoneContext();
    const totalDuration = logEntry.durations.request
        .plus(logEntry.durations.copy)
        .plus(logEntry.durations.compression);
    const theme = useTheme();
    const compactView = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <ListItem
            secondaryAction={
                <IconButton edge="end" onClick={dialogController.open}>
                    <SourceIcon />
                </IconButton>
            }
        >
            <ListItemIcon>
                {logEntry.successful ? (
                    <CheckCircleIcon color="success" />
                ) : (
                    <CancelIcon color="error" />
                )}
            </ListItemIcon>
            <ListItemText
                primary={logEntry.completedAt
                    .withZoneSameInstant(zoneId)
                    .format(zonedDateTimeFormatter)}
                secondary={compactView && prettyMilliseconds(totalDuration.toMillis())}
                sx={{
                    minWidth: !compactView ? 330 : undefined,
                    maxWidth: !compactView ? 330 : undefined,
                    width: !compactView ? 330 : undefined,
                }}
            />
            {!compactView && (
                <ListItemText secondary={prettyMilliseconds(totalDuration.toMillis())} />
            )}
            {dialogController.mount && (
                <Dialog {...dialogController.dialogProps} maxWidth="sm" fullWidth>
                    <DialogTitle>Log message</DialogTitle>
                    <DialogContent dividers>
                        <Stack
                            direction={{ xs: "column", sm: "row" }}
                            sx={{
                                rowGap: 2,
                                columnGap: 4,
                            }}
                        >
                            <CardField
                                label="Compression"
                                value={prettyMilliseconds(
                                    logEntry.durations.compression.toMillis(),
                                )}
                            />
                            <CardField
                                label="Copy"
                                value={prettyMilliseconds(logEntry.durations.copy.toMillis())}
                            />
                            <CardField
                                label="Request"
                                value={prettyMilliseconds(logEntry.durations.request.toMillis())}
                            />
                        </Stack>
                        <Divider sx={{ my: 2 }} />
                        <DialogContentText fontFamily="monospace">
                            {logEntry.message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="inherit" onClick={dialogController.dialogProps.onClose}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </ListItem>
    );
};

export default LogEntryListItem;
