import useDialogController from "@/hooks/useDialogController.tsx";
import { useCreateSetupIntentMutation } from "@/mutations/setup-intent.ts";
import { useQueryOptionsFactory } from "@/queries";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { LoadingButton } from "@mui/lab";
import { Alert, Link, Stack, Typography } from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { type ReactNode, useCallback } from "react";
import PaymentMethodCard from "./PaymentMethodCard.tsx";
import SetupDialog from "./SetupDialog.tsx";
import SetupIntentCard from "./SetupIntentCard.tsx";

const PaymentMethodSection = (): ReactNode => {
    const { id: clientId, stripe } = useActiveClient();
    const qof = useQueryOptionsFactory();
    const setupIntentQuery = useSuspenseQuery(qof.stripe.getSetupIntent(clientId));
    const paymentMethodQuery = useSuspenseQuery(qof.stripe.getPaymentMethod(clientId));
    const createSetupIntentMutation = useCreateSetupIntentMutation(clientId);
    const setupDialogController = useDialogController();
    const { enqueueSnackbar } = useSnackbar();

    const startPaymentMethodSetup = useCallback(() => {
        createSetupIntentMutation.mutate(undefined, {
            onError: () => {
                enqueueSnackbar("Failed to start setup", { variant: "error" });
            },
            onSuccess: () => {
                setupDialogController.open();
            },
        });
    }, [enqueueSnackbar, setupDialogController.open, createSetupIntentMutation]);

    const setupIntent = setupIntentQuery.data;
    const paymentMethod = paymentMethodQuery.data;

    const refresh = useCallback(() => {
        // The order here is important. If we let both fetch at the same time it can happen
        // that the setup intent query finished first and returns null. This leads to the
        // "no payment method setup" alert being shown for a split second.
        //
        // Instead, we reload the payment method first before reloading the setup intent.
        paymentMethodQuery.refetch().then(() => {
            void setupIntentQuery.refetch();
        });
    }, [setupIntentQuery.refetch, paymentMethodQuery.refetch]);

    if (!(paymentMethod || setupIntent)) {
        return (
            <>
                <Typography
                    variant="h6"
                    sx={{
                        mb: 2,
                    }}
                >
                    Payment Method
                </Typography>
                {stripe ? (
                    <Alert
                        severity="warning"
                        action={
                            <LoadingButton
                                color="inherit"
                                loading={createSetupIntentMutation.isPending}
                                onClick={startPaymentMethodSetup}
                            >
                                Start setup
                            </LoadingButton>
                        }
                    >
                        You haven't set up a payment method yet.
                    </Alert>
                ) : (
                    <Alert severity="success">
                        Your payment method has been successfully set up by our support team. If you
                        have any questions or would like to change your payment method please
                        contact{" "}
                        <Link href="mailto:support@soliant.cloud">support@soliant.cloud</Link>.
                    </Alert>
                )}
            </>
        );
    }
    return (
        <>
            <Typography
                variant="h6"
                sx={{
                    mb: 2,
                }}
            >
                Payment Method
            </Typography>
            <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
                {paymentMethod && (
                    <PaymentMethodCard
                        paymentMethod={paymentMethod}
                        setup={
                            setupIntent
                                ? null
                                : {
                                      start: startPaymentMethodSetup,
                                      isStarting: createSetupIntentMutation.isPending,
                                  }
                        }
                    />
                )}
                {setupIntent && (
                    <SetupIntentCard
                        clientId={clientId}
                        setupIntent={setupIntent}
                        setupDialogController={setupDialogController}
                        refresh={refresh}
                        isRefreshing={setupIntentQuery.isFetching || paymentMethodQuery.isFetching}
                    />
                )}
            </Stack>
            {setupIntent && setupDialogController.mount && (
                <SetupDialog
                    dialogProps={setupDialogController.dialogProps}
                    setupIntent={setupIntent}
                />
            )}
        </>
    );
};

export default PaymentMethodSection;
