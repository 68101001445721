import ConfirmDialog from "@/components/ConfirmDialog";
import {
    type RestartComponentValues,
    useRestartComponentMutation,
} from "@/mutations/filemaker-server.ts";
import type { FileMakerServer } from "@/queries/filemaker-server.ts";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { getErrorMessage } from "@/utils/api.ts";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Button, ListItemIcon, ListItemText, Menu, MenuItem, type SxProps } from "@mui/material";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { useSnackbar } from "notistack";
import { type ReactNode, useCallback } from "react";
import { useConfirm } from "react-confirm-hook";

type Props = {
    server: FileMakerServer;
    sx?: SxProps;
};

type FmsComponent = {
    actionStateKey: keyof FileMakerServer["actions"];
    mutationComponent: RestartComponentValues["component"];
    label: string;
    confirmation: {
        title: string;
        message: string;
    };
};

const fmsComponents: FmsComponent[] = [
    {
        actionStateKey: "restartScriptingEngine",
        mutationComponent: "scripting_engine",
        label: "Scripting Engine",
        confirmation: {
            title: "Restart Scripting Engine",
            message: "Do you really want to restart the Scripting Engine?",
        },
    },
    {
        actionStateKey: "restartWebPublishingEngine",
        mutationComponent: "wpe",
        label: "Web Publishing Engine",
        confirmation: {
            title: "Restart Web Publishing Engine",
            message: "Do you really want to restart the Web Publishing Engine?",
        },
    },
    {
        actionStateKey: "restartDataApi",
        mutationComponent: "data_api",
        label: "Data API",
        confirmation: {
            title: "Restart Data API",
            message: "Do you really want to restart the Data API?",
        },
    },
    {
        actionStateKey: "restartODataApi",
        mutationComponent: "odata_api",
        label: "OData API",
        confirmation: {
            title: "Restart OData API",
            message: "Do you really want to restart the OData API?",
        },
    },
    {
        actionStateKey: "restartOttoFms",
        mutationComponent: "otto_fms",
        label: "OttoFMS",
        confirmation: {
            title: "Restart OttoFMS",
            message: "Do you really want to restart the OttoFMS?",
        },
    },
];

export const shouldRenderRestartComponentButton = (server: FileMakerServer): boolean => {
    for (const component of fmsComponents) {
        if (server.actions[component.actionStateKey] !== "disallowed") {
            return true;
        }
    }

    return false;
};

const RestartComponentButton = ({ server, sx }: Props): ReactNode => {
    const { id: clientId } = useActiveClient();
    const menuState = usePopupState({ variant: "popover", popupId: "fms-restart-component" });
    const restartMutation = useRestartComponentMutation(clientId);
    const confirm = useConfirm(ConfirmDialog);
    const { enqueueSnackbar } = useSnackbar();

    const handleRestart = useCallback(
        async (component: RestartComponentValues["component"]) => {
            try {
                const message = await restartMutation.mutateAsync({
                    hostId: server.hostId,
                    component,
                });
                enqueueSnackbar(message, { variant: "success" });
            } catch (error) {
                enqueueSnackbar(getErrorMessage(error), { variant: "error" });
            }
        },
        [restartMutation, enqueueSnackbar, server.hostId],
    );

    if (!shouldRenderRestartComponentButton(server)) {
        return null;
    }

    return (
        <>
            <Button
                {...bindTrigger(menuState)}
                variant="outlined"
                endIcon={<KeyboardArrowDownIcon />}
                sx={sx}
                startIcon={<RestartAltIcon />}
            >
                Restart component
            </Button>

            <Menu {...bindMenu(menuState)}>
                {fmsComponents.map((component) => {
                    const state = server.actions[component.actionStateKey];

                    if (state === "disallowed") {
                        return null;
                    }

                    return (
                        <MenuItem
                            key={component.actionStateKey}
                            disabled={state === "pending"}
                            onClick={() => {
                                menuState.close();
                                confirm({
                                    title: component.confirmation.title,
                                    message: component.confirmation.message,
                                    onConfirm: () => handleRestart(component.mutationComponent),
                                });
                            }}
                        >
                            <ListItemIcon>
                                {state === "pending" ? <PendingOutlinedIcon /> : <RestartAltIcon />}
                            </ListItemIcon>
                            <ListItemText>{component.label}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};

export default RestartComponentButton;
