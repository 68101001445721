import SmoothTransitionBox from "@/components/SmoothTransitionBox";
import type { ControlledDialogProps } from "@/hooks/useDialogController.tsx";
import type { SideCarDeployment } from "@/queries/sidecar.ts";
import { Dialog, DialogTitle, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import { type ReactNode, useState } from "react";
import ConfigTab from "./ConfigTab.tsx";
import GeneralTab from "./GeneralTab.tsx";

type Props = {
    dialogProps: ControlledDialogProps;
    deployment: SideCarDeployment;
};

const DeploymentDialog = ({ dialogProps, deployment }: Props): ReactNode => {
    const [activeTab, setActiveTab] = useState<"general" | "config">("general");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Dialog {...dialogProps} maxWidth="sm" fullWidth fullScreen={fullScreen}>
            <DialogTitle>{deployment.title}</DialogTitle>

            <Tabs
                value={activeTab}
                onChange={(_event, value) => setActiveTab(value)}
                variant="fullWidth"
            >
                <Tab value="general" label="General" />
                <Tab value="config" label="Configuration" />
            </Tabs>

            <SmoothTransitionBox disableTransition={fullScreen}>
                {activeTab === "general" && (
                    <GeneralTab deployment={deployment} onClose={dialogProps.onClose} />
                )}
                {activeTab === "config" && (
                    <ConfigTab deployment={deployment} onClose={dialogProps.onClose} />
                )}
            </SmoothTransitionBox>
        </Dialog>
    );
};

export default DeploymentDialog;
