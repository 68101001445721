import type { AuthenticatedFetch } from "@/hooks/useAuthenticatedFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { queryOptions } from "@tanstack/react-query";
import {
    createDataSelector,
    createResourceCollectionSelector,
    handleJsonApiError,
} from "jsonapi-zod-query";
import { z } from "zod";
import { zj } from "zod-joda";

const attributesSchema = z.object({
    createdAt: zj.zonedDateTime(),
    uploadedBy: z.string().min(1),
    filename: z.string().min(1),
    size: z.number().int().min(0),
    description: z.string().min(1),
    objectKey: z.string().min(1),
});

const filesSelector = createDataSelector(
    createResourceCollectionSelector({
        type: "file_locker_file",
        attributesSchema: attributesSchema,
    }),
);

export type FileLockerFile = ReturnType<typeof filesSelector>[number];

export const createFileLockerQueryOptionsFactory = (authFetch: AuthenticatedFetch) => ({
    listFiles: (clientId: string) =>
        queryOptions({
            queryKey: ["client", clientId, "file-locker", "files"],
            queryFn: async ({ signal }) => {
                const url = apiUrl(`/clients/${clientId}/file-locker/files`);
                const response = await authFetch(url, { signal });
                await handleJsonApiError(response);
                return filesSelector(await response.json());
            },
        }),
});
