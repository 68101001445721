import type { AuthenticatedFetch } from "@/hooks/useAuthenticatedFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { queryOptions } from "@tanstack/react-query";
import {
    createDataSelector,
    createNullableResourceSelector,
    handleJsonApiError,
} from "jsonapi-zod-query";
import { z } from "zod";

const setupIntentAttributesSchema = z.object({
    clientSecret: z.string(),
    status: z.enum([
        "canceled",
        "processing",
        "requires_action",
        "requires_confirmation",
        "requires_payment_method",
        "succeeded",
    ]),
});

export const setupIntentSelector = createDataSelector(
    createNullableResourceSelector({
        type: "stripe_setup_intent",
        attributesSchema: setupIntentAttributesSchema,
    }),
);

export type StripeSetupIntent = NonNullable<ReturnType<typeof setupIntentSelector>>;

const paymentMethodAttributesSchema = z.discriminatedUnion("type", [
    z.object({
        type: z.literal("card"),
        lastFourDigits: z.string(),
        expiration: z.object({
            month: z.number(),
            year: z.number(),
        }),
    }),
    z.object({
        type: z.literal("us_bank_account"),
        lastFourDigits: z.string().nullable(),
        bankName: z.string().nullable(),
    }),
    z.object({
        type: z.literal("other"),
    }),
]);

const paymentMethodSelector = createDataSelector(
    createNullableResourceSelector({
        type: "stripe_payment_method",
        attributesSchema: paymentMethodAttributesSchema,
    }),
);

export type StripePaymentMethod = NonNullable<ReturnType<typeof paymentMethodSelector>>;

export const createStripeQueryOptionsFactory = (authFetch: AuthenticatedFetch) => ({
    getSetupIntent: (clientId: string) =>
        queryOptions({
            queryKey: ["client", clientId, "stripe", "setup-intent"],
            queryFn: async ({ signal }) => {
                const response = await authFetch(
                    apiUrl(`/clients/${clientId}/stripe/setup-intent`),
                    {
                        signal,
                    },
                );
                await handleJsonApiError(response);
                return setupIntentSelector(await response.json());
            },
        }),
    getPaymentMethod: (clientId: string) =>
        queryOptions({
            queryKey: ["client", clientId, "stripe", "payment-method"],
            queryFn: async ({ signal }) => {
                const response = await authFetch(
                    apiUrl(`/clients/${clientId}/stripe/payment-method`),
                    {
                        signal,
                    },
                );
                await handleJsonApiError(response);
                return paymentMethodSelector(await response.json());
            },
        }),
});
