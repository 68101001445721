import type { AuthenticatedFetch } from "@/hooks/useAuthenticatedFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { queryOptions } from "@tanstack/react-query";
import { createDataSelector, createResourceSelector, handleJsonApiError } from "jsonapi-zod-query";
import { z } from "zod";

const attributesSchema = z.object({
    url: z.string().min(1),
    expiresIn: z.number().int(),
});

export const signedGetUrlSelector = createDataSelector(
    createResourceSelector({
        type: "signed_get_url",
        attributesSchema,
    }),
);

export type SignedGetUrl = ReturnType<typeof signedGetUrlSelector>;

export const createSignedGetUrlQueryOptionsFactory = (authFetch: AuthenticatedFetch) => ({
    get: (clientId: string, path: string) =>
        queryOptions({
            queryKey: ["client", clientId, "signed-get-url", path],
            queryFn: async ({ signal }) => {
                const url = apiUrl(`/clients/${clientId}${path}`);
                const response = await authFetch(url, { signal });
                await handleJsonApiError(response);
                return signedGetUrlSelector(await response.json());
            },
        }),
});
