import AutoFillGrid from "@/components/AutoFillGrid";
import { BodyContainer } from "@/components/Layout";
import { InlineSpinner } from "@/components/Spinner";
import { useQueryOptionsFactory } from "@/queries";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { Alert } from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import type { ReactNode } from "react";
import VolumeCard from "./-components/VolumeCard.tsx";

const Root = (): ReactNode => {
    const { id: clientId } = useActiveClient();
    const params = Route.useParams();
    const qof = useQueryOptionsFactory();
    const { data: volumes } = useSuspenseQuery(qof.hostVolume.list(clientId, params.hostId));

    if (volumes.length === 0) {
        return <Alert severity="info">The host has no volumes attached.</Alert>;
    }

    return (
        <AutoFillGrid minWidth={350}>
            {volumes.map((volume) => (
                <VolumeCard key={volume.id} volume={volume} hostId={params.hostId} />
            ))}
        </AutoFillGrid>
    );
};

export const Route = createFileRoute("/$clientId/hosts/$hostId/volumes/")({
    component: Root,
    pendingComponent: () => (
        <BodyContainer>
            <InlineSpinner />
        </BodyContainer>
    ),
    loader: async ({ context, params }) => {
        await context.queryClient.ensureQueryData(
            context.qof.hostVolume.list(params.clientId, params.hostId),
        );
    },
});
