import IntegerField from "@/components/IntegerField";
import type { ControlledDialogProps } from "@/hooks/useDialogController.tsx";
import { useUpdateConfigurationMutation } from "@/mutations/webdirect-auto-scaling.ts";
import type {
    WebdirectAutoScalingConfiguration,
    WebdirectAutoScalingConfigurationAvailableOptions,
} from "@/queries/webdirect-auto-scaling.ts";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { useActiveHost } from "@/routes/$clientId/hosts/$hostId.tsx";
import { getErrorMessage } from "@/utils/api.ts";
import { localTimeToDayJs } from "@/utils/datetime.ts";
import { timeSchema } from "@/utils/zod.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    MenuItem,
    Stack,
} from "@mui/material";
import { RhfSwitch, RhfTextField } from "mui-rhf-integration";
import { RhfTimePicker } from "mui-rhf-integration/date-picker";
import { useSnackbar } from "notistack";
import { type ReactNode, useCallback } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z
    .object({
        instanceType: z.string().min(1),
        cooldownTime: z.string().transform((value) => Number.parseInt(value, 10)),
        masterUsers: z.number().int().nonnegative(),
        utilizationThreshold: z.string().transform((value) => Number.parseInt(value, 10)),
    })
    .and(
        z.discriminatedUnion("dailyScheduleEnabled", [
            z.object({
                dailyScheduleEnabled: z.literal(false),
            }),
            z.object({
                dailyScheduleEnabled: z.literal(true),
                dailySchedule: z.object({
                    workers: z.number().int().min(1),
                    startsAt: timeSchema,
                    endsAt: timeSchema,
                    includeWeekends: z.boolean(),
                }),
            }),
        ]),
    )
    .transform(({ dailyScheduleEnabled, ...values }) => {
        if (!(dailyScheduleEnabled && "dailySchedule" in values)) {
            return {
                ...values,
                dailySchedule: null,
            };
        }

        return values;
    });

type FieldValues = z.input<typeof schema>;
type TransformedValues = z.output<typeof schema>;

type Props = {
    dialogProps: ControlledDialogProps;
    configuration: WebdirectAutoScalingConfiguration;
    availableOptions: WebdirectAutoScalingConfigurationAvailableOptions;
};

const EditConfigurationDialog = ({
    dialogProps,
    configuration,
    availableOptions,
}: Props): ReactNode => {
    const { id: clientId } = useActiveClient();
    const { id: hostId } = useActiveHost();
    const updateMutation = useUpdateConfigurationMutation(clientId);
    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema),
        defaultValues: {
            ...configuration,
            cooldownTime: configuration.cooldownTime.toString(),
            utilizationThreshold: configuration.utilizationThreshold.toString(),
            dailyScheduleEnabled: configuration.dailySchedule !== null,
            dailySchedule:
                configuration.dailySchedule !== null
                    ? {
                          ...configuration.dailySchedule,
                          startsAt: localTimeToDayJs(configuration.dailySchedule.startsAt),
                          endsAt: localTimeToDayJs(configuration.dailySchedule.endsAt),
                      }
                    : undefined,
        },
    });
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = useCallback(
        (values: TransformedValues) => {
            updateMutation.mutate(
                {
                    hostId,
                    ...values,
                },
                {
                    onSuccess: (message) => {
                        enqueueSnackbar(message, { variant: "success" });
                        dialogProps.onClose();
                    },
                    onError: (error) => {
                        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                    },
                },
            );
        },
        [enqueueSnackbar, hostId, updateMutation, dialogProps.onClose],
    );

    const dailyScheduleEnabled = form.watch("dailyScheduleEnabled");

    return (
        <Dialog
            {...dialogProps}
            maxWidth="md"
            fullWidth
            PaperProps={{
                component: "form",
                noValidate: true,
                onSubmit: form.handleSubmit(handleSubmit),
            }}
        >
            <DialogTitle>Edit configuration</DialogTitle>
            <DialogContent dividers>
                <Stack spacing={2}>
                    <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                        <RhfTextField
                            control={form.control}
                            name="instanceType"
                            label="Instance type"
                            select
                            fullWidth
                            required
                        >
                            {availableOptions.instanceTypes.map((instanceType) => (
                                <MenuItem key={instanceType} value={instanceType}>
                                    {instanceType}
                                </MenuItem>
                            ))}
                        </RhfTextField>
                        <RhfTextField
                            control={form.control}
                            name="utilizationThreshold"
                            label="Utilization threshold"
                            select
                            fullWidth
                            required
                        >
                            {availableOptions.utilizationThresholds.map((threshold) => (
                                <MenuItem key={threshold} value={threshold.toString()}>
                                    {threshold}%
                                </MenuItem>
                            ))}
                        </RhfTextField>
                    </Stack>
                    <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                        <RhfTextField
                            control={form.control}
                            name="cooldownTime"
                            label="Cooldown time"
                            select
                            fullWidth
                            required
                        >
                            {availableOptions.cooldownTimes.map((minutes) => (
                                <MenuItem key={minutes} value={minutes.toString()}>
                                    {minutes} minutes
                                </MenuItem>
                            ))}
                        </RhfTextField>
                        <IntegerField
                            control={form.control}
                            name="masterUsers"
                            label="Master users"
                            min={1}
                            max={availableOptions.maxMasterUsers}
                            fullWidth
                            required
                        />
                    </Stack>
                </Stack>

                <Divider sx={{ mt: 2, mb: 1 }} />

                <Stack direction={{ xs: "column", md: "row" }} spacing={2} sx={{ mb: 1 }}>
                    <FormControlLabel
                        control={<RhfSwitch control={form.control} name="dailyScheduleEnabled" />}
                        label="Daily schedule"
                    />
                    <FormControlLabel
                        control={
                            <RhfSwitch
                                control={form.control}
                                name="dailySchedule.includeWeekends"
                                disabled={!dailyScheduleEnabled}
                            />
                        }
                        label="Include weekends"
                    />
                </Stack>

                <Stack spacing={2}>
                    <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                        <IntegerField
                            control={form.control}
                            name="dailySchedule.workers"
                            label="Workers"
                            min={1}
                            max={availableOptions.maxWorkers}
                            fullWidth
                            disabled={!dailyScheduleEnabled}
                            required={dailyScheduleEnabled}
                        />
                        <RhfTimePicker
                            control={form.control}
                            name="dailySchedule.startsAt"
                            label="Start"
                            disabled={!dailyScheduleEnabled}
                            textFieldProps={{
                                required: dailyScheduleEnabled,
                                fullWidth: true,
                            }}
                        />
                        <RhfTimePicker
                            control={form.control}
                            name="dailySchedule.endsAt"
                            label="End"
                            disabled={!dailyScheduleEnabled}
                            textFieldProps={{
                                required: dailyScheduleEnabled,
                                fullWidth: true,
                            }}
                        />
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color="inherit" onClick={dialogProps.onClose}>
                    Cancel
                </Button>
                <LoadingButton loading={updateMutation.isPending} type="submit">
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default EditConfigurationDialog;
