import type { FileMakerServer } from "@/queries/filemaker-server.ts";
import { Box, Stack, type SxProps } from "@mui/material";
import type { ReactNode } from "react";
import ResetAdminPasswordButton from "./ResetAdminPasswordButton.tsx";
import RestartComponentButton, {
    shouldRenderRestartComponentButton,
} from "./RestartComponentButton.tsx";

type Props = {
    server: FileMakerServer;
    sx?: SxProps;
};

const ActionsBar = ({ server, sx }: Props): ReactNode => {
    if (
        server.actions.resetAdminPassword === "disallowed" &&
        !shouldRenderRestartComponentButton(server)
    ) {
        return null;
    }

    return (
        <Stack
            spacing={2}
            direction={{ xs: "column", md: "row" }}
            sx={[
                {
                    alignItems: "flex-start",
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            <Box
                sx={{
                    flexGrow: 1,
                }}
            >
                <RestartComponentButton server={server} />
            </Box>
            <ResetAdminPasswordButton server={server} />
        </Stack>
    );
};
export default ActionsBar;
