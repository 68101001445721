import type { ControlledDialogProps } from "@/hooks/useDialogController.tsx";
import useSignedDownload from "@/hooks/useSignedDownload.ts";
import { useAcceptAgreementMutation } from "@/mutations/host.ts";
import type { ListHost } from "@/queries/host.ts";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { getErrorMessage } from "@/utils/api.ts";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import invariant from "tiny-invariant";

type Props = {
    host: ListHost;
    dialogProps: ControlledDialogProps;
};

const AgreementDialog = ({ host, dialogProps }: Props): ReactNode => {
    const { id: clientId } = useActiveClient();
    const acceptMutation = useAcceptAgreementMutation(clientId);
    const { enqueueSnackbar } = useSnackbar();
    const signedDownload = useSignedDownload(clientId);
    invariant(host.agreement, "Agreement missing in host");

    const acceptAgreement = () => {
        acceptMutation.mutate(
            {
                hostId: host.id,
            },
            {
                onSuccess: (message) => {
                    enqueueSnackbar(message, { variant: "success" });
                    dialogProps.onClose();
                },
                onError: (error) => {
                    enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                },
            },
        );
    };

    return (
        <Dialog {...dialogProps} maxWidth="sm" fullWidth>
            <DialogTitle>Soliant.Cloud Hosting Agreement</DialogTitle>
            <DialogContent dividers>
                <Box
                    sx={{
                        textAlign: "center",
                    }}
                >
                    <LoadingButton
                        variant="outlined"
                        loading={signedDownload.loading}
                        onClick={() => {
                            signedDownload.start(`/hosts/${host.id}/agreement/object-url`);
                        }}
                    >
                        Download hosting agreement
                    </LoadingButton>
                </Box>
                <Divider sx={{ my: 2 }} />
                {host.agreement.signature ? (
                    <Typography>
                        Agreement accepted by:
                        <br />
                        {host.agreement.signature}
                    </Typography>
                ) : (
                    <Typography>
                        By accepting the agreement you confirm that you have read it and are
                        authorized to accept it.
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    color="inherit"
                    onClick={() => {
                        dialogProps.onClose();
                    }}
                >
                    Close
                </Button>
                {!host.agreement.signature && (
                    <LoadingButton loading={acceptMutation.isPending} onClick={acceptAgreement}>
                        Accept
                    </LoadingButton>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default AgreementDialog;
