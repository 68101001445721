import { BodyContainer } from "@/components/Layout";
import NotFoundCard from "@/components/NotFoundCard";
import { InlineSpinner } from "@/components/Spinner";
import TimeZoneProvider from "@/components/TimeZoneProvider";
import { useQueryOptionsFactory } from "@/queries";
import type { Capability, Host } from "@/queries/host.ts";
import { useActiveClient } from "@/routes/$clientId.tsx";
import RefreshButton from "@/routes/$clientId/hosts/-components/RefreshButton.tsx";
import { Box, Tab, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";
import { Link, Outlet, createFileRoute, useLocation } from "@tanstack/react-router";
import { type ReactNode, createContext, useContext, useMemo } from "react";
import ViewSettingsButton from "./-components/ViewSettingsButton.tsx";

type TabOption = {
    label: string;
    path: string;
    capability?: Capability;
};

const tabOptions: TabOption[] = [
    { label: "General", path: "" },
    { label: "Volumes", path: "/volumes", capability: "volumes" },
    { label: "FMS", path: "/fms", capability: "filemaker_server" },
    { label: "SnapBack", path: "/snapback", capability: "snapback" },
    { label: "Disaster Recovery", path: "/disaster-recovery", capability: "disaster_recovery" },
    { label: "Dynamic Docket", path: "/dynamic-docket", capability: "dynamic_docket" },
    {
        label: "Auto-Scaling",
        path: "/webdirect-auto-scaling",
        capability: "webdirect_auto_scaling",
    },
    { label: "Events", path: "/events", capability: "event_schedule" },
    { label: "Private Backup", path: "/private-backup", capability: "private_backup" },
];

const createHostTabs = (clientId: string, host: Host) => {
    const tabs: ReactNode[] = [];

    for (const { label, path, capability } of tabOptions) {
        if (capability && !host.capabilities.has(capability)) {
            continue;
        }

        tabs.push(
            <Tab
                key={path}
                component={Link}
                label={label}
                value={`/${clientId}/hosts/${host.id}${path}`}
                to={`/${clientId}/hosts/${host.id}${path}`}
            />,
        );
    }

    return tabs;
};

const ActiveHostContext = createContext<Host | null>(null);

export const useActiveHost = (): Host => {
    const host = useContext(ActiveHostContext);

    if (!host) {
        throw new Error("useActiveHost used outside ActiveHostProvider");
    }

    return host;
};

const Root = (): ReactNode => {
    const { id: clientId } = useActiveClient();
    const params = Route.useParams();
    const qof = useQueryOptionsFactory();
    const { data: host } = useSuspenseQuery(qof.host.get(clientId, params.hostId));
    const { pathname } = useLocation();
    const validPathnames = useMemo(
        () =>
            tabOptions.reduce<string[]>((pathnames, { path, capability }) => {
                if (capability && !host.capabilities.has(capability)) {
                    return pathnames;
                }

                pathnames.push(`/${clientId}/hosts/${host.id}${path}`);
                return pathnames;
            }, []),
        [clientId, host.id, host.capabilities],
    );

    const theme = useTheme();
    const tabPosition = useMediaQuery(theme.breakpoints.down("sm")) ? "bottom" : "top";
    const tabs = useMemo(() => createHostTabs(clientId, host), [clientId, host]);
    const tabContainer = useMemo(
        () => (
            <Tabs
                variant="scrollable"
                scrollButtons="auto"
                value={validPathnames.includes(pathname) ? pathname : false}
                TabIndicatorProps={
                    tabPosition === "top"
                        ? undefined
                        : {
                              sx: {
                                  top: 0,
                              },
                          }
                }
            >
                {tabs}
            </Tabs>
        ),
        [validPathnames, pathname, tabPosition, tabs],
    );

    return (
        <TimeZoneProvider serverZone={host.timezone}>
            <ActiveHostContext.Provider value={host}>
                <BodyContainer
                    header={
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    minWidth: 0,
                                    mr: "auto",
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    sx={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                    }}
                                >
                                    {host.domainName}
                                </Typography>
                                <Typography
                                    sx={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                    }}
                                >
                                    {host.name}
                                </Typography>
                            </Box>
                            <RefreshButton hostId={host.id} />
                            <ViewSettingsButton edge="end" />
                        </Box>
                    }
                    subheader={tabPosition === "top" && tabContainer}
                    footer={tabPosition === "bottom" && tabContainer}
                >
                    <Outlet />
                </BodyContainer>
            </ActiveHostContext.Provider>
        </TimeZoneProvider>
    );
};

export const Route = createFileRoute("/$clientId/hosts/$hostId")({
    component: Root,
    pendingComponent: () => (
        <BodyContainer>
            <InlineSpinner />
        </BodyContainer>
    ),
    notFoundComponent: NotFoundCard,
    loader: async ({ context, params }) => {
        await context.queryClient.ensureQueryData(
            context.qof.host.get(params.clientId, params.hostId),
        );
    },
});
