import type { AuthenticatedFetch } from "@/hooks/useAuthenticatedFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { Duration } from "@js-joda/core";
import { queryOptions } from "@tanstack/react-query";
import {
    createDataSelector,
    createResourceCollectionSelector,
    createResourceSelector,
    handleJsonApiError,
} from "jsonapi-zod-query";
import { z } from "zod";
import { zj } from "zod-joda";

const configurationAttributesSchema = z.object({
    enabled: z.boolean(),
    appendFolderDate: z.boolean(),
    occurrence: z.enum(["daily", "weekly"]),
    occurrenceDescription: z.string().min(1),
    serviceName: z.string().min(1),
    remotePath: z.string().min(1),
});

const configurationSelector = createDataSelector(
    createResourceSelector({
        type: "private_backup_configuration",
        attributesSchema: configurationAttributesSchema,
    }),
);

export type PrivateBackupConfiguration = ReturnType<typeof configurationSelector>;

const logEntryAttributesSchema = z.object({
    successful: z.boolean(),
    durations: z.object({
        compression: z
            .number()
            .int()
            .nonnegative()
            .transform((value) => Duration.ofSeconds(value)),
        copy: z
            .number()
            .int()
            .nonnegative()
            .transform((value) => Duration.ofSeconds(value)),
        request: z
            .number()
            .int()
            .nonnegative()
            .transform((value) => Duration.ofSeconds(value)),
    }),
    completedAt: zj.zonedDateTime(),
    message: z.string(),
});

const logEntriesSelector = createDataSelector(
    createResourceCollectionSelector({
        type: "private_backup_log_entry",
        attributesSchema: logEntryAttributesSchema,
    }),
);

export type PrivateBackupLogEntry = ReturnType<typeof logEntriesSelector>[number];

export const createPrivateBackupQueryOptionsFactory = (authFetch: AuthenticatedFetch) => ({
    getConfiguration: (clientId: string, hostId: string) =>
        queryOptions({
            queryKey: ["client", clientId, "host", hostId, "private-backup", "configuration"],
            queryFn: async ({ signal }) => {
                const url = apiUrl(
                    `/clients/${clientId}/hosts/${hostId}/private-backup/configuration`,
                );
                const response = await authFetch(url, { signal });
                await handleJsonApiError(response);
                return configurationSelector(await response.json());
            },
        }),
    listLogEntries: (clientId: string, hostId: string) =>
        queryOptions({
            queryKey: ["client", clientId, "host", hostId, "private-backup", "log-entries"],
            queryFn: async ({ signal }) => {
                const url = apiUrl(
                    `/clients/${clientId}/hosts/${hostId}/private-backup/log-entries`,
                );
                const response = await authFetch(url, { signal });
                await handleJsonApiError(response);
                return logEntriesSelector(await response.json());
            },
        }),
});
