import type { DynamicDocketEvent } from "@/queries/dynamic-docket.ts";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import { Chip, type ChipProps } from "@mui/material";
import type { ReactNode } from "react";
import { match } from "ts-pattern";

type Props = {
    action: DynamicDocketEvent["action"];
    size?: ChipProps["size"];
};

const ActionChip = ({ action, size }: Props): ReactNode => {
    const [label, Icon] = match(action)
        .with("start", () => ["Start", PlayCircleOutlineIcon] as const)
        .with("stop", () => ["Stop", StopCircleOutlinedIcon] as const)
        .exhaustive();

    return <Chip label={label} icon={<Icon />} size={size} />;
};

export default ActionChip;
