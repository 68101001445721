import { useTimeZoneContext } from "@/components/TimeZoneProvider";
import useDialogController from "@/hooks/useDialogController.tsx";
import type {
    DisasterRecoveryConfiguration,
    DisasterRecoveryRestorePoint,
} from "@/queries/disaster-recovery.ts";
import LoadRestorePointDialog from "@/routes/$clientId/hosts/$hostId/disaster-recovery/-components/LoadRestorePointDialog.tsx";
import { zonedDateTimeFormatter } from "@/utils/format.ts";
import PublishIcon from "@mui/icons-material/Publish";
import {
    IconButton,
    ListItem,
    ListItemText,
    Stack,
    Tooltip,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import type { ReactNode } from "react";
import { createPortal } from "react-dom";
import StateChip from "./StateChip.tsx";

type Props = {
    restorePoint: DisasterRecoveryRestorePoint;
    configuration: DisasterRecoveryConfiguration;
};

const RestorePointListItem = ({ restorePoint, configuration }: Props): ReactNode => {
    const { zoneId } = useTimeZoneContext();
    const theme = useTheme();
    const compactView = useMediaQuery(theme.breakpoints.down("md"));
    const dialogController = useDialogController();

    return (
        <ListItem
            secondaryAction={
                <Tooltip title="Launch host from restore point">
                    <span>
                        <IconButton
                            edge="end"
                            disabled={restorePoint.state !== "available"}
                            onClick={dialogController.open}
                        >
                            <PublishIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            }
        >
            <ListItemText
                primary={restorePoint.description}
                secondary={
                    <Stack
                        sx={{
                            rowGap: 0.5,
                        }}
                    >
                        {restorePoint.createdAt
                            .withZoneSameInstant(zoneId)
                            .format(zonedDateTimeFormatter)}

                        {compactView && (
                            <div>
                                <StateChip state={restorePoint.state} size="small" />
                            </div>
                        )}
                    </Stack>
                }
                primaryTypographyProps={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                }}
                secondaryTypographyProps={{ component: "div" }}
                sx={{
                    flexGrow: 1,
                    mr: !compactView ? 2 : undefined,
                }}
            />
            {!compactView && (
                <ListItemText
                    secondary={<StateChip state={restorePoint.state} size="small" />}
                    secondaryTypographyProps={{ component: "div" }}
                    sx={{ flexGrow: 0 }}
                />
            )}

            {dialogController.mount &&
                createPortal(
                    <LoadRestorePointDialog
                        dialogProps={dialogController.dialogProps}
                        configuration={configuration}
                        restorePoint={restorePoint}
                    />,
                    document.body,
                )}
        </ListItem>
    );
};

export default RestorePointListItem;
