import type { FileUpload } from "@/components/FileUploadField";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import { acknowledgmentSchema, apiUrl } from "@/utils/api.ts";
import { type UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { handleJsonApiError } from "jsonapi-zod-query";

type SelectFmsLicenseValues = {
    hostId: string;
} & (
    | {
          choice: "buy";
      }
    | {
          choice: "byol";
          organization: string;
          certificate: FileUpload;
      }
);

export const useSelectFmsLicenseMutation = (
    clientId: string,
): UseMutationResult<string, Error, SelectFmsLicenseValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const { hostId, ...rest } = values;
            const attributes: Record<string, unknown> = { choice: rest.choice };

            if (rest.choice === "byol") {
                attributes.organization = rest.organization;
                attributes.certificate = rest.certificate;
            }

            const response = await fetch(
                apiUrl(`/clients/${clientId}/hosts/${hostId}/licenses/fms/select`),
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                    },
                    body: JSON.stringify({
                        data: {
                            type: "fms_license_selection",
                            attributes,
                        },
                    }),
                },
            );
            await handleJsonApiError(response);
            return acknowledgmentSchema.parse(await response.json()).meta.message;
        },
        onSuccess: async (_data, values) => {
            await Promise.all([
                queryClient.invalidateQueries({ queryKey: ["client", clientId, "hosts"] }),
                queryClient.invalidateQueries({
                    queryKey: ["client", clientId, "host", values.hostId],
                    exact: true,
                }),
            ]);
        },
    });
};

type AcceptAgreementValues = {
    hostId: string;
};

export const useAcceptAgreementMutation = (
    clientId: string,
): UseMutationResult<string, Error, AcceptAgreementValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl(`/clients/${clientId}/hosts/${values.hostId}/agreement/accept`),
                {
                    method: "POST",
                },
            );
            await handleJsonApiError(response);
            return acknowledgmentSchema.parse(await response.json()).meta.message;
        },
        onSuccess: async (_data, values) => {
            await Promise.all([
                queryClient.invalidateQueries({ queryKey: ["client", clientId, "hosts"] }),
                queryClient.invalidateQueries({
                    queryKey: ["client", clientId, "host", values.hostId],
                    exact: true,
                }),
            ]);
        },
    });
};

export type StateChangeValues = {
    hostId: string;
};

export const useStartHostMutation = (
    clientId: string,
): UseMutationResult<string, Error, StateChangeValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl(`/clients/${clientId}/hosts/${values.hostId}/start`),
                { method: "POST" },
            );
            await handleJsonApiError(response);
            return acknowledgmentSchema.parse(await response.json()).meta.message;
        },
        onSuccess: async (_data, values) => {
            await Promise.all([
                queryClient.invalidateQueries({ queryKey: ["client", clientId, "hosts"] }),
                queryClient.invalidateQueries({
                    queryKey: ["client", clientId, "host", values.hostId],
                    exact: true,
                }),
            ]);
        },
    });
};

export const useStopHostMutation = (
    clientId: string,
): UseMutationResult<string, Error, StateChangeValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl(`/clients/${clientId}/hosts/${values.hostId}/stop`),
                { method: "POST" },
            );
            await handleJsonApiError(response);
            return acknowledgmentSchema.parse(await response.json()).meta.message;
        },
        onSuccess: async (_data, values) => {
            await Promise.all([
                queryClient.invalidateQueries({ queryKey: ["client", clientId, "hosts"] }),
                queryClient.invalidateQueries({
                    queryKey: ["client", clientId, "host", values.hostId],
                    exact: true,
                }),
            ]);
        },
    });
};

export const useRestartHostMutation = (
    clientId: string,
): UseMutationResult<string, Error, StateChangeValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl(`/clients/${clientId}/hosts/${values.hostId}/restart`),
                { method: "POST" },
            );
            await handleJsonApiError(response);
            return acknowledgmentSchema.parse(await response.json()).meta.message;
        },
        onSuccess: async (_data, values) => {
            await Promise.all([
                queryClient.invalidateQueries({ queryKey: ["client", clientId, "hosts"] }),
                queryClient.invalidateQueries({
                    queryKey: ["client", clientId, "host", values.hostId],
                    exact: true,
                }),
            ]);
        },
    });
};

export type RemoteAccessValues = {
    hostId: string;
};

export const useRequestRemoteAccessMutation = (
    clientId: string,
): UseMutationResult<string, Error, RemoteAccessValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl(`/clients/${clientId}/hosts/${values.hostId}/request-remote-access`),
                { method: "POST" },
            );
            await handleJsonApiError(response);
            return acknowledgmentSchema.parse(await response.json()).meta.message;
        },
        onSuccess: async (_data, values) => {
            await queryClient.invalidateQueries({
                queryKey: ["client", clientId, "host", values.hostId],
                exact: true,
            });
        },
    });
};
