import type { AuthenticatedFetch } from "@/hooks/useAuthenticatedFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { queryOptions } from "@tanstack/react-query";
import {
    createDataSelector,
    createResourceCollectionSelector,
    createResourceSelector,
    handleJsonApiError,
} from "jsonapi-zod-query";
import { z } from "zod";
import { zj } from "zod-joda";

const snapBackAttributesSchema = z.object({
    awsSnapshotId: z.string().min(1),
    createdAt: zj.zonedDateTime(),
    description: z.string().min(1),
    status: z.enum(["online", "pending", "available", "unavailable", "error"]),
    preserved: z.boolean(),
    retained: z.array(z.enum(["daily", "weekly", "monthly"])).transform((value) => new Set(value)),
    volumeSize: z.number().int().nonnegative(),
});

const snapBackSelector = createDataSelector(
    createResourceCollectionSelector({
        type: "snapback",
        attributesSchema: snapBackAttributesSchema,
    }),
);

export type SnapBack = ReturnType<typeof snapBackSelector>[number];

const configurationAttributesSchema = z.object({
    model: z.string().min(1),
    retention: z.object({
        daily: z.number().int().nonnegative(),
        weekly: z.number().int().nonnegative(),
        monthly: z.number().int().nonnegative(),
    }),
});

const configurationSelector = createDataSelector(
    createResourceSelector({
        type: "snapback_configuration",
        attributesSchema: configurationAttributesSchema,
    }),
);

export type SnapBackConfiguration = ReturnType<typeof configurationSelector>;

const mountTargetAttributesSchema = z.object({
    name: z.string().min(1),
    domainName: z.string().min(1),
});

const mountTargetsSelector = createDataSelector(
    createResourceCollectionSelector({
        type: "snapback_mount_target",
        attributesSchema: mountTargetAttributesSchema,
    }),
);

export type SnapBackMountTarget = ReturnType<typeof mountTargetsSelector>[number];

export const createSnapBackQueryOptionsFactory = (authFetch: AuthenticatedFetch) => ({
    getConfiguration: (clientId: string, hostId: string) =>
        queryOptions({
            queryKey: ["client", clientId, "host", hostId, "snapback", "configuration"],
            queryFn: async ({ signal }) => {
                const url = apiUrl(`/clients/${clientId}/hosts/${hostId}/snapback/configuration`);
                const response = await authFetch(url, { signal });
                await handleJsonApiError(response);
                return configurationSelector(await response.json());
            },
        }),
    listMountTargets: (clientId: string, hostId: string) =>
        queryOptions({
            queryKey: ["client", clientId, "host", hostId, "snapback", "mount-targets"],
            queryFn: async ({ signal }) => {
                const url = apiUrl(`/clients/${clientId}/hosts/${hostId}/snapback/mount-targets`);
                const response = await authFetch(url, { signal });
                await handleJsonApiError(response);
                return mountTargetsSelector(await response.json());
            },
        }),
    listSnapBacks: (clientId: string, hostId: string) =>
        queryOptions({
            queryKey: ["client", clientId, "host", hostId, "snapbacks"],
            queryFn: async ({ signal }) => {
                const url = apiUrl(`/clients/${clientId}/hosts/${hostId}/snapbacks`);
                const response = await authFetch(url, { signal });
                await handleJsonApiError(response);
                return snapBackSelector(await response.json());
            },
        }),
});
