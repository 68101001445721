import type { PrivateBackupLogEntry } from "@/queries/private-backup.ts";
import { Divider, List, type SxProps } from "@mui/material";
import { Fragment, type ReactNode } from "react";
import LogEntryListItem from "./LogEntryListItem.tsx";

type Props = {
    logEntries: PrivateBackupLogEntry[];
    sx?: SxProps;
};

const LogEntryList = ({ logEntries, sx }: Props): ReactNode => {
    return (
        <List disablePadding sx={sx}>
            {logEntries.map((logEntry, index) => (
                <Fragment key={logEntry.id}>
                    {index > 0 && <Divider />}
                    <LogEntryListItem logEntry={logEntry} />
                </Fragment>
            ))}
        </List>
    );
};

export default LogEntryList;
