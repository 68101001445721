import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import { acknowledgmentSchema, apiUrl } from "@/utils/api.ts";
import { type UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { handleJsonApiError } from "jsonapi-zod-query";

export type UpdateSnapBackValues = {
    hostId: string;
    snapBackId: string;
    preserved: boolean;
    description: string;
};

export const useUpdateSnapBackMutation = (
    clientId: string,
): UseMutationResult<string, Error, UpdateSnapBackValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl(
                    `/clients/${clientId}/hosts/${values.hostId}/snapbacks/${values.snapBackId}`,
                ),
                {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                    },
                    body: JSON.stringify({
                        data: {
                            id: values.snapBackId,
                            type: "snapback",
                            attributes: {
                                preserved: values.preserved,
                                description: values.description,
                            },
                        },
                    }),
                },
            );
            await handleJsonApiError(response);
            return acknowledgmentSchema.parse(await response.json()).meta.message;
        },
        onSuccess: async (_data, values) => {
            await queryClient.invalidateQueries({
                queryKey: ["client", clientId, "host", values.hostId, "snapbacks"],
                exact: true,
            });
        },
    });
};

export type PerformSnapBackValues = {
    hostId: string;
};

export const usePerformSnapBackMutation = (
    clientId: string,
): UseMutationResult<string, Error, PerformSnapBackValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl(`/clients/${clientId}/hosts/${values.hostId}/snapbacks`),
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                    },
                    body: JSON.stringify({
                        data: {
                            type: "snapback",
                        },
                    }),
                },
            );
            await handleJsonApiError(response);
            return acknowledgmentSchema.parse(await response.json()).meta.message;
        },
        onSuccess: async (_data, values) => {
            await queryClient.invalidateQueries({
                queryKey: ["client", clientId, "host", values.hostId, "snapbacks"],
                exact: true,
            });
        },
    });
};

export type MountSnapBackValues = {
    hostId: string;
    snapBackId: string;
    targetHostId: string;
};

export const useMountSnapBackMutation = (
    clientId: string,
): UseMutationResult<string, Error, MountSnapBackValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl(
                    `/clients/${clientId}/hosts/${values.hostId}/snapbacks/${values.snapBackId}/mount`,
                ),
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                    },
                    body: JSON.stringify({
                        data: {
                            type: "snapback_mount",
                            attributes: {
                                targetHostId: values.targetHostId,
                            },
                        },
                    }),
                },
            );
            await handleJsonApiError(response);
            return acknowledgmentSchema.parse(await response.json()).meta.message;
        },
        onSuccess: async (_data, values) => {
            await queryClient.invalidateQueries({
                queryKey: ["client", clientId, "host", values.hostId, "snapbacks"],
                exact: true,
            });
        },
    });
};

export type UnmountSnapBackValues = {
    hostId: string;
    snapBackId: string;
};

export const useUnmountSnapBackMutation = (
    clientId: string,
): UseMutationResult<string, Error, UnmountSnapBackValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl(
                    `/clients/${clientId}/hosts/${values.hostId}/snapbacks/${values.snapBackId}/unmount`,
                ),
                {
                    method: "POST",
                },
            );
            await handleJsonApiError(response);
            return acknowledgmentSchema.parse(await response.json()).meta.message;
        },
        onSuccess: async (_data, values) => {
            await queryClient.invalidateQueries({
                queryKey: ["client", clientId, "host", values.hostId, "snapbacks"],
                exact: true,
            });
        },
    });
};
