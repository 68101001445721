import HostHelpSection from "@/components/HostHelpSection";
import { BodyContainer } from "@/components/Layout";
import { InlineSpinner } from "@/components/Spinner";
import useDialogController from "@/hooks/useDialogController.tsx";
import { useQueryOptionsFactory } from "@/queries";
import { useActiveClient } from "@/routes/$clientId.tsx";
import AddSettingsFileDialog from "@/routes/$clientId/hosts/$hostId/disaster-recovery/-components/AddSettingsFileDialog.tsx";
import SettingsFileList from "@/routes/$clientId/hosts/$hostId/disaster-recovery/-components/SettingsFileList.tsx";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import type { ReactNode } from "react";
import ConfigurationCard from "./-components/ConfigurationCard.tsx";
import RestorePointList from "./-components/RestorePointList.tsx";

const Root = (): ReactNode => {
    const { id: clientId } = useActiveClient();
    const params = Route.useParams();
    const qof = useQueryOptionsFactory();
    const { data: configuration } = useSuspenseQuery(
        qof.disasterRecovery.getConfiguration(clientId, params.hostId),
    );
    const { data: settingsFiles } = useSuspenseQuery(
        qof.disasterRecovery.listSettingsFiles(clientId, params.hostId),
    );
    const { data: restorePoints } = useSuspenseQuery(
        qof.disasterRecovery.listRestorePoints(clientId, params.hostId),
    );
    const addSettingsFileDialogController = useDialogController();

    return (
        <div>
            <ConfigurationCard configuration={configuration} />

            <Box sx={{ mt: 4 }}>
                <Typography variant="h6">Restore points</Typography>
                {restorePoints.length === 0 ? (
                    <Alert severity="info" sx={{ mt: 1 }}>
                        No restore point has been created yet.
                    </Alert>
                ) : (
                    <RestorePointList restorePoints={restorePoints} configuration={configuration} />
                )}
            </Box>

            <Box sx={{ mt: 4 }}>
                <Stack direction={{ xs: "column", sm: "row" }} sx={{ rowGap: 1 }}>
                    <Typography variant="h6" sx={{ mr: "auto" }}>
                        Script schedule settings
                    </Typography>
                    <Button
                        size="small"
                        variant="outlined"
                        startIcon={<FileUploadIcon />}
                        sx={{ mb: { xs: 1, sm: 0 } }}
                        onClick={addSettingsFileDialogController.open}
                    >
                        Upload
                    </Button>
                </Stack>

                {settingsFiles.length === 0 ? (
                    <Alert severity="info" sx={{ mt: 1 }}>
                        You haven't uploaded any script schedule settings yet.
                    </Alert>
                ) : (
                    <SettingsFileList settingsFiles={settingsFiles} />
                )}
            </Box>

            {addSettingsFileDialogController.mount && (
                <AddSettingsFileDialog
                    dialogProps={addSettingsFileDialogController.dialogProps}
                    clientId={clientId}
                    hostId={params.hostId}
                />
            )}

            <HostHelpSection
                supportResources={[
                    { label: "Using OptiFlex Disaster Recovery", type: "disaster_recovery" },
                ]}
            />
        </div>
    );
};

export const Route = createFileRoute("/$clientId/hosts/$hostId/disaster-recovery/")({
    component: Root,
    pendingComponent: () => (
        <BodyContainer>
            <InlineSpinner />
        </BodyContainer>
    ),
    loader: async ({ context, params }) => {
        await Promise.all([
            context.queryClient.ensureQueryData(
                context.qof.disasterRecovery.getConfiguration(params.clientId, params.hostId),
            ),
            context.queryClient.ensureQueryData(
                context.qof.disasterRecovery.listSettingsFiles(params.clientId, params.hostId),
            ),
            context.queryClient.ensureQueryData(
                context.qof.disasterRecovery.listRestorePoints(params.clientId, params.hostId),
            ),
        ]);
    },
});
