import HostHelpSection from "@/components/HostHelpSection";
import { BodyContainer } from "@/components/Layout";
import { InlineSpinner } from "@/components/Spinner";
import { useQueryOptionsFactory } from "@/queries";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { Alert } from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import type { ReactNode } from "react";
import ConfigurationCard from "./-components/ConfigurationCard.tsx";
import SnapBackList from "./-components/SnapBackList.tsx";

const Root = (): ReactNode => {
    const { id: clientId } = useActiveClient();
    const params = Route.useParams();
    const qof = useQueryOptionsFactory();
    const { data: configuration } = useSuspenseQuery(
        qof.snapBack.getConfiguration(clientId, params.hostId),
    );
    const { data: snapBacks } = useSuspenseQuery(
        qof.snapBack.listSnapBacks(clientId, params.hostId),
    );

    return (
        <div>
            <ConfigurationCard
                configuration={configuration}
                performDisabled={snapBacks.some((snapBack) => snapBack.status === "pending")}
            />

            {snapBacks.length === 0 ? (
                <Alert severity="info" sx={{ mt: 2 }}>
                    No backups have been performed yet.
                </Alert>
            ) : (
                <SnapBackList snapBacks={snapBacks} sx={{ mt: 2 }} />
            )}

            <HostHelpSection
                supportResources={[
                    { label: "Using SnapBacks", type: "snapback_restore" },
                    { label: "SnapBack Standard/Premium", type: "snapback_premium" },
                ]}
            />
        </div>
    );
};

export const Route = createFileRoute("/$clientId/hosts/$hostId/snapback/")({
    component: Root,
    pendingComponent: () => (
        <BodyContainer>
            <InlineSpinner />
        </BodyContainer>
    ),
    loader: async ({ context, params }) => {
        await Promise.all([
            context.queryClient.ensureQueryData(
                context.qof.snapBack.getConfiguration(params.clientId, params.hostId),
            ),
            context.queryClient.ensureQueryData(
                context.qof.snapBack.listSnapBacks(params.clientId, params.hostId),
            ),
        ]);
    },
});
