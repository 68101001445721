import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import { acknowledgmentSchema, apiUrl } from "@/utils/api.ts";
import { type UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { handleJsonApiError } from "jsonapi-zod-query";

export type ResizeVolumeValues = {
    hostId: string;
    volumeId: string;
    size: number;
};

export const useResizeVolumeMutation = (
    clientId: string,
): UseMutationResult<string, Error, ResizeVolumeValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl(
                    `/clients/${clientId}/hosts/${values.hostId}/volumes/${values.volumeId}/resize`,
                ),
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                    },
                    body: JSON.stringify({
                        data: {
                            type: "host_volume_resize_request",
                            attributes: {
                                site: values.size,
                            },
                        },
                    }),
                },
            );
            await handleJsonApiError(response);
            return acknowledgmentSchema.parse(await response.json()).meta.message;
        },
        onSuccess: async (_data, values) => {
            await queryClient.invalidateQueries({
                queryKey: ["client", clientId, "host", values.hostId, "volumes"],
                exact: true,
            });
        },
    });
};
