import type {
    ConfigObjectField,
    ConfigValueField,
    OutputsObjectField,
    OutputsValueField,
    SideCarManifest,
} from "@/sidecar/manifest.ts";

type Source = "config" | "outputs";
type ValueField<T> = T extends "config" ? ConfigValueField : OutputsValueField;
type ObjectField<T> = T extends "config" ? ConfigObjectField : OutputsObjectField;

export type ValueProperty<T extends Source> = ValueField<T> & {
    key: string;
    path: string;
    required: boolean;
};

export type DisplayGroup<T extends Source> = {
    key: string;
    title: string;
    properties: ValueProperty<T>[];
};

const fieldSort =
    <T extends Source>(order?: string[]) =>
    (a: ValueProperty<T> | DisplayGroup<T>, b: ValueProperty<T> | DisplayGroup<T>): number => {
        const aIndex = order?.indexOf(a.key);
        const bIndex = order?.indexOf(b.key);

        if (aIndex !== undefined && bIndex !== undefined) {
            return aIndex - bIndex;
        }

        if (aIndex !== undefined && bIndex === undefined) {
            return -1;
        }

        if (aIndex === undefined && bIndex !== undefined) {
            return 1;
        }

        return a.title.localeCompare(b.title);
    };
/**
 * Create display groups from a manifest
 *
 * Returns a tuple with an array of sorted display groups, together with a list of root properties
 * which do not belong to any display group.
 *
 * For root properties the order is only respected within all root properties, but root properties
 * themselves are always displayed before all display groups.
 *
 * Root properties can specify a `displayGroup` property to merge them into virtual display groups.
 * The `displayGroup* property will be ignored when the property is already in a real display group.
 */
export const createDisplayGroups = <T extends Source>(
    manifest: SideCarManifest,
    source: T,
): [DisplayGroup<T>[], ValueProperty<T>[]] => {
    const root = manifest[source];

    const rootProperties: ValueProperty<T>[] = Object.entries(root.properties)
        .filter(
            (entry): entry is [string, ValueField<T>] =>
                entry[1].type !== "object" && entry[1].displayGroup === undefined,
        )
        .map(([key, property]: [string, ValueField<T>]) => ({
            ...property,
            key,
            path: key,
            required: Boolean(root.required?.includes(key)),
        }))
        .sort(fieldSort(root.order));

    const virtualDisplayGroups: DisplayGroup<T>[] = Array.from(
        Object.entries(root.properties)
            .filter(
                (entry): entry is [string, ValueField<T> & { displayGroup: string }] =>
                    entry[1].type !== "object" && entry[1].displayGroup !== undefined,
            )
            .reduce((groups, [key, property]) => {
                let displayGroup = groups.get(property.displayGroup);

                if (!displayGroup) {
                    displayGroup = {
                        key: property.displayGroup,
                        title: property.displayGroup,
                        properties: [],
                    };
                    groups.set(property.displayGroup, displayGroup);
                }

                displayGroup.properties.push({
                    ...property,
                    key,
                    path: key,
                    required: Boolean(root.required?.includes(key)),
                });

                return groups;
            }, new Map<string, DisplayGroup<T>>())
            .values(),
    ).map((displayGroup) => {
        displayGroup.properties.sort(fieldSort(root.order));
        return displayGroup;
    });

    const realDisplayGroups: DisplayGroup<T>[] = Object.entries(root.properties)
        .filter((entry): entry is [string, ObjectField<T>] => entry[1].type === "object")
        .map(([key, property]) => ({
            key,
            title: property.title,
            properties: Object.entries(property.properties)
                .map(([valueKey, valueProperty]) => ({
                    ...valueProperty,
                    key: valueKey,
                    path: `${key}.${valueKey}`,
                    required: Boolean(property.required?.includes(valueKey)),
                }))
                .sort(fieldSort(property.order)),
        }));

    const displayGroups = [...virtualDisplayGroups, ...realDisplayGroups].sort(
        fieldSort(root.order),
    );

    return [displayGroups, rootProperties];
};
