import type { AuthenticatedFetch } from "@/hooks/useAuthenticatedFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { queryOptions } from "@tanstack/react-query";
import {
    createDataSelector,
    createResourceCollectionSelector,
    createResourceSelector,
    handleJsonApiError,
} from "jsonapi-zod-query";
import { z } from "zod";
import { zj } from "zod-joda";

const actionSchema = z.enum(["start", "stop"]);

const eventAttributesSchema = z.object({
    action: actionSchema,
    description: z.string(),
    runsAt: zj.zonedDateTime(),
});

const eventsSelector = createDataSelector(
    createResourceCollectionSelector({
        type: "dynamic_docket_event",
        attributesSchema: eventAttributesSchema,
    }),
);

export type DynamicDocketEvent = ReturnType<typeof eventsSelector>[number];

const scheduleSlotSchema = z.object({
    time: zj.localTime(),
    action: actionSchema,
});

export type ScheduleSlot = z.output<typeof scheduleSlotSchema>;

const scheduleDaySchema = z.object({
    begin: scheduleSlotSchema.nullable(),
    end: scheduleSlotSchema.nullable(),
});

export type ScheduleDay = z.output<typeof scheduleDaySchema>;

const weeklyScheduleSchema = z.object({
    monday: scheduleDaySchema,
    tuesday: scheduleDaySchema,
    wednesday: scheduleDaySchema,
    thursday: scheduleDaySchema,
    friday: scheduleDaySchema,
    saturday: scheduleDaySchema,
    sunday: scheduleDaySchema,
});

export type WeeklySchedule = z.output<typeof weeklyScheduleSchema>;

const configurationAttributesSchema = z.object({
    scheduleEnabled: z.boolean(),
    scheduleModifiable: z.boolean(),
    weeklySchedule: weeklyScheduleSchema,
});

const configurationSelector = createDataSelector(
    createResourceSelector({
        type: "dynamic_docket_configuration",
        attributesSchema: configurationAttributesSchema,
    }),
);

export type DynamicDocketConfiguration = ReturnType<typeof configurationSelector>;

export const createDynamicDocketQueryOptionsFactory = (authFetch: AuthenticatedFetch) => ({
    getConfiguration: (clientId: string, hostId: string) =>
        queryOptions({
            queryKey: ["client", clientId, "host", hostId, "dynamic-docket", "configuration"],
            queryFn: async ({ signal }) => {
                const url = apiUrl(
                    `/clients/${clientId}/hosts/${hostId}/dynamic-docket/configuration`,
                );
                const response = await authFetch(url, { signal });
                await handleJsonApiError(response);
                return configurationSelector(await response.json());
            },
        }),
    listUpcomingEvents: (clientId: string, hostId: string) =>
        queryOptions({
            queryKey: ["client", clientId, "host", hostId, "dynamic-docket", "upcoming-events"],
            queryFn: async ({ signal }) => {
                const url = apiUrl(
                    `/clients/${clientId}/hosts/${hostId}/dynamic-docket/upcoming-events`,
                );
                const response = await authFetch(url, { signal });
                await handleJsonApiError(response);
                return eventsSelector(await response.json());
            },
        }),
});
