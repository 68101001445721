import type { WebdirectAutoScalingWorker } from "@/queries/webdirect-auto-scaling.ts";
import { Divider, List, type SxProps } from "@mui/material";
import { Fragment, type ReactNode } from "react";
import WorkerListItem from "./WorkerListItem.tsx";

type Props = {
    workers: WebdirectAutoScalingWorker[];
    sx?: SxProps;
};

const WorkerList = ({ workers, sx }: Props): ReactNode => (
    <List disablePadding sx={sx}>
        {workers.map((worker, index) => (
            <Fragment key={worker.id}>
                {index > 0 && <Divider />}
                <WorkerListItem worker={worker} />
            </Fragment>
        ))}
    </List>
);

export default WorkerList;
