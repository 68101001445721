import type { HostEvent } from "@/queries/host-event.ts";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Chip, type ChipProps } from "@mui/material";
import type { ReactNode } from "react";
import { match } from "ts-pattern";

type Props = {
    reoccurrence: HostEvent["reoccurrence"];
    size: ChipProps["size"];
};

const ReoccurrenceChip = ({ reoccurrence, size }: Props): ReactNode => {
    const label = match(reoccurrence)
        .with("once", () => "Once")
        .with("quarter_hourly", () => "Quarter-hourly")
        .with("hourly", () => "Hourly")
        .with("daily", () => "Daily")
        .with("weekly", () => "Weekly")
        .with("monthly", () => "Monthly")
        .with("quarterly", () => "Quarterly")
        .exhaustive();

    return <Chip size={size} icon={<AccessTimeIcon />} label={label} />;
};

export default ReoccurrenceChip;
