import type { AnyRelationshipDeserializer } from "jsonapi-zod-query";
import { z } from "zod";

export const awsAccountRelationship = {
    relationshipType: "one",
    include: {
        type: "aws_account",
        attributesSchema: z.object({
            name: z.string(),
        }),
    },
} satisfies AnyRelationshipDeserializer;

export const awsRegionRelationship = {
    relationshipType: "one",
    include: {
        type: "aws_region",
        attributesSchema: z.object({
            name: z.string(),
        }),
    },
} satisfies AnyRelationshipDeserializer;
