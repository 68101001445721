import PasswordField from "@/components/PasswordField";
import type { ControlledDialogProps } from "@/hooks/useDialogController.tsx";
import { useResetAdminPasswordMutation } from "@/mutations/filemaker-server.ts";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { getErrorMessage } from "@/utils/api.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useSnackbar } from "notistack";
import { type ReactNode, useCallback } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z.object({
    password: z.string().min(8),
});

type FieldValues = z.input<typeof schema>;
type TransformedValues = z.output<typeof schema>;

type Props = {
    hostId: string;
    dialogProps: ControlledDialogProps;
};

const ResetAdminPasswordButton = ({ hostId, dialogProps }: Props): ReactNode => {
    const { id: clientId } = useActiveClient();
    const mutation = useResetAdminPasswordMutation(clientId);
    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema),
    });
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = useCallback(
        (values: TransformedValues) => {
            mutation.mutate(
                {
                    hostId,
                    ...values,
                },
                {
                    onSuccess: (message) => {
                        enqueueSnackbar(message, { variant: "success" });
                        dialogProps.onClose();
                    },
                    onError: (error) => {
                        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                    },
                },
            );
        },
        [enqueueSnackbar, hostId, mutation, dialogProps.onClose],
    );

    return (
        <Dialog
            {...dialogProps}
            maxWidth="xs"
            fullWidth
            PaperProps={{
                component: "form",
                noValidate: true,
                onSubmit: form.handleSubmit(handleSubmit),
            }}
        >
            <DialogTitle>Reset admin password</DialogTitle>
            <DialogContent dividers>
                <PasswordField
                    control={form.control}
                    name="password"
                    label="New password"
                    fullWidth
                    helperText="Must be at least 8 characters"
                />
            </DialogContent>
            <DialogActions>
                <Button color="inherit" onClick={dialogProps.onClose}>
                    Cancel
                </Button>
                <LoadingButton loading={mutation.isPending} type="submit">
                    Reset
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default ResetAdminPasswordButton;
