import useDialogController from "@/hooks/useDialogController.tsx";
import type { FileMakerServer } from "@/queries/filemaker-server.ts";
import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import { Button, type SxProps } from "@mui/material";
import type { ReactNode } from "react";
import { createPortal } from "react-dom";
import ResetAdminPasswordDialog from "./ResetAdminPasswordDialog.tsx";

type Props = {
    server: FileMakerServer;
    sx?: SxProps;
};

const ResetAdminPasswordButton = ({ server, sx }: Props): ReactNode => {
    const dialogController = useDialogController();

    if (server.actions.resetAdminPassword === "disallowed") {
        return null;
    }

    return (
        <>
            <Button
                onClick={dialogController.open}
                variant="outlined"
                disabled={server.actions.resetAdminPassword === "pending"}
                sx={sx}
                startIcon={
                    server.actions.resetAdminPassword === "pending" ? (
                        <PendingOutlinedIcon />
                    ) : (
                        <PasswordOutlinedIcon />
                    )
                }
            >
                Reset Admin Password
            </Button>

            {dialogController.mount &&
                createPortal(
                    <ResetAdminPasswordDialog
                        hostId={server.hostId}
                        dialogProps={dialogController.dialogProps}
                    />,
                    document.body,
                )}
        </>
    );
};

export default ResetAdminPasswordButton;
