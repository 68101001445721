import { useTimeZoneContext } from "@/components/TimeZoneProvider";
import useSignedDownload from "@/hooks/useSignedDownload.ts";
import type { DisasterRecoverySettingsFile } from "@/queries/disaster-recovery.ts";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { useActiveHost } from "@/routes/$clientId/hosts/$hostId.tsx";
import { zonedDateTimeFormatter } from "@/utils/format.ts";
import DownloadIcon from "@mui/icons-material/Download";
import { IconButton, ListItem, ListItemText, Tooltip } from "@mui/material";
import type { ReactNode } from "react";

type Props = {
    settingsFile: DisasterRecoverySettingsFile;
};

const SettingsFileListItem = ({ settingsFile }: Props): ReactNode => {
    const { id: clientId } = useActiveClient();
    const { id: hostId } = useActiveHost();
    const { zoneId } = useTimeZoneContext();
    const signedDownload = useSignedDownload(clientId);

    return (
        <ListItem
            secondaryAction={
                <Tooltip title="Download">
                    <span>
                        <IconButton
                            edge="end"
                            disabled={signedDownload.loading}
                            onClick={() => {
                                signedDownload.start(
                                    `/hosts/${hostId}/disaster-recovery/settings-files/${settingsFile.id}/object-url`,
                                );
                            }}
                        >
                            <DownloadIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            }
        >
            <ListItemText
                primary={settingsFile.filename}
                secondary={
                    <>
                        <div>{settingsFile.description}</div>
                        <div>
                            {settingsFile.createdAt
                                .withZoneSameInstant(zoneId)
                                .format(zonedDateTimeFormatter)}
                        </div>
                    </>
                }
                primaryTypographyProps={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                }}
                secondaryTypographyProps={{ component: "div" }}
            />
        </ListItem>
    );
};

export default SettingsFileListItem;
