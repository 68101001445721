import useDialogController from "@/hooks/useDialogController.tsx";
import { useUpdateConfigurationMutation } from "@/mutations/dynamic-docket.ts";
import type { DynamicDocketConfiguration } from "@/queries/dynamic-docket.ts";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { useActiveHost } from "@/routes/$clientId/hosts/$hostId.tsx";
import { getErrorMessage } from "@/utils/api.ts";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import { FormControlLabel, IconButton, Paper, Stack, Switch } from "@mui/material";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import EditScheduleDialog from "./EditScheduleDialog.tsx";

type Props = {
    configuration: DynamicDocketConfiguration;
};

const ConfigurationCard = ({ configuration }: Props): ReactNode => {
    const { id: clientId } = useActiveClient();
    const { id: hostId } = useActiveHost();
    const updateMutation = useUpdateConfigurationMutation(clientId);
    const dialogController = useDialogController();
    const { enqueueSnackbar } = useSnackbar();

    return (
        <Paper sx={{ px: 2, py: 1 }}>
            <Stack direction="row" spacing={2}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={configuration.scheduleEnabled}
                            disabled={updateMutation.isPending}
                            onChange={(_event, checked) => {
                                updateMutation.mutate(
                                    {
                                        hostId,
                                        scheduleEnabled: checked,
                                    },
                                    {
                                        onError: (error) => {
                                            enqueueSnackbar(getErrorMessage(error), {
                                                variant: "error",
                                            });
                                        },
                                    },
                                );
                            }}
                        />
                    }
                    label="Schedule enabled"
                    sx={{ mr: "auto" }}
                />
                <IconButton
                    disabled={!configuration.scheduleModifiable}
                    edge="end"
                    onClick={dialogController.open}
                >
                    <EditCalendarIcon />
                </IconButton>
            </Stack>

            {dialogController.mount && (
                <EditScheduleDialog
                    dialogProps={dialogController.dialogProps}
                    configuration={configuration}
                />
            )}
        </Paper>
    );
};
export default ConfigurationCard;
