import AutoFillGrid from "@/components/AutoFillGrid";
import { useQueryOptionsFactory } from "@/queries";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { Typography } from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { ReactNode } from "react";
import InvoiceCard from "./InvoiceCard.tsx";

const InvoicesSection = (): ReactNode => {
    const { id: clientId } = useActiveClient();
    const qof = useQueryOptionsFactory();
    const { data: invoices } = useSuspenseQuery(qof.invoice.list(clientId));

    return (
        <>
            <Typography
                variant="h6"
                sx={{
                    mb: 2,
                }}
            >
                Invoices
            </Typography>
            <AutoFillGrid minWidth={300}>
                {invoices.map((invoice) => (
                    <InvoiceCard key={invoice.id} invoice={invoice} />
                ))}
            </AutoFillGrid>
        </>
    );
};

export default InvoicesSection;
