import { ZoneId } from "@js-joda/core";
import { type ReactNode, createContext, useCallback, useContext, useMemo, useState } from "react";

export enum TimeZone {
    Local = "local",
    Host = "host",
    Utc = "utc",
}

type TimeZoneContextValue = {
    timeZone: TimeZone;
    zoneId: ZoneId;
    updateTimeZone: (timeZone: TimeZone) => void;
};

const TimeZoneContext = createContext<TimeZoneContextValue | null>(null);

type Props = {
    serverZone: ZoneId;
    children: ReactNode;
};

const defaultTimeZone = TimeZone.Host;
const storageKey = "sccp_host_preferred_timezone";

const TimeZoneProvider = ({ serverZone, children }: Props): ReactNode => {
    const [timeZone, setTimeZone] = useState<TimeZone>(() => {
        const storedValue = window.localStorage.getItem(storageKey);

        if (!storedValue) {
            return defaultTimeZone;
        }

        if (!(Object.values(TimeZone) as string[]).includes(storedValue)) {
            window.localStorage.removeItem(storageKey);
            return defaultTimeZone;
        }

        return storedValue as TimeZone;
    });

    const updateTimeZone = useCallback((timeZone: TimeZone) => {
        setTimeZone(timeZone);
        window.localStorage.setItem(storageKey, timeZone);
    }, []);

    const zoneId = useMemo(() => {
        switch (timeZone) {
            case TimeZone.Local:
                return ZoneId.systemDefault();

            case TimeZone.Utc:
                return ZoneId.UTC;

            case TimeZone.Host:
                return serverZone;
        }
    }, [timeZone, serverZone]);

    const contextValue = useMemo(
        () => ({
            timeZone,
            zoneId,
            updateTimeZone,
        }),
        [timeZone, zoneId, updateTimeZone],
    );

    return <TimeZoneContext.Provider value={contextValue}>{children}</TimeZoneContext.Provider>;
};

export const useTimeZoneContext = (): TimeZoneContextValue => {
    const context = useContext(TimeZoneContext);

    if (!context) {
        throw new Error("useTimeZoneContext used outside TimeZoneProvider");
    }

    return context;
};

export default TimeZoneProvider;
