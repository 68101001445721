import CardField from "@/components/CardField";
import useSignedDownload from "@/hooks/useSignedDownload.ts";
import type { FileLockerFile } from "@/queries/file-locker.ts";
import { localDateTimeFormatter, localZone } from "@/utils/format.ts";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Box, Card, CardActionArea, CardContent, CardHeader, Stack } from "@mui/material";
import humanFormat from "human-format";
import type { ReactNode } from "react";

type Props = {
    file: FileLockerFile;
    clientId: string;
};

const FileCard = ({ file, clientId }: Props): ReactNode => {
    const signedDownload = useSignedDownload(clientId);

    return (
        <Card>
            <CardActionArea
                disabled={signedDownload.loading}
                onClick={() => {
                    signedDownload.start(`/file-locker/files/${file.id}/object-url`);
                }}
            >
                <CardHeader
                    avatar={<InsertDriveFileIcon />}
                    title={file.filename}
                    subheader={file.description}
                    titleTypographyProps={{
                        textOverflow: "ellipsis",
                        noWrap: true,
                    }}
                    subheaderTypographyProps={{
                        textOverflow: "ellipsis",
                        noWrap: true,
                    }}
                    sx={{
                        ".MuiCardHeader-content": {
                            minWidth: 0,
                        },
                    }}
                />
                <CardContent>
                    <Stack
                        sx={{
                            rowGap: 2,
                        }}
                    >
                        <Stack
                            direction={{ xs: "column", sm: "row" }}
                            sx={{
                                rowGap: 2,
                            }}
                        >
                            <CardField
                                label="Size"
                                value={humanFormat(file.size, { scale: "binary", unit: "B" })}
                                sx={{ minWidth: 110, width: 110, maxWidth: 110 }}
                            />
                            <CardField
                                label="Created"
                                value={file.createdAt
                                    .withZoneSameInstant(localZone)
                                    .format(localDateTimeFormatter)}
                            />
                        </Stack>
                        <CardField
                            label="Uploader"
                            value={
                                <Box
                                    sx={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                    }}
                                >
                                    {file.uploadedBy}
                                </Box>
                            }
                        />
                    </Stack>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default FileCard;
