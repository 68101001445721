import FileUploadField, { fileUploadSchema } from "@/components/FileUploadField";
import type { ControlledDialogProps } from "@/hooks/useDialogController.tsx";
import { useAddSettingsFileMutation } from "@/mutations/disaster-recovery.ts";
import { getErrorMessage } from "@/utils/api.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { RhfTextField } from "mui-rhf-integration";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z.object({
    description: z.string().trim().min(1),
    file: fileUploadSchema,
});

type FieldValues = z.input<typeof schema>;
type TransformedValues = z.output<typeof schema>;

type Props = {
    dialogProps: ControlledDialogProps;
    clientId: string;
    hostId: string;
};

const AddSettingsFileDialog = ({ dialogProps, clientId, hostId }: Props): ReactNode => {
    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema),
    });
    const addSettingsFileMutation = useAddSettingsFileMutation(clientId);
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = (values: TransformedValues) => {
        addSettingsFileMutation.mutate(
            { hostId, ...values },
            {
                onSuccess: () => {
                    enqueueSnackbar("Settings file added", { variant: "success" });
                    dialogProps.onClose();
                },
                onError: (error) => {
                    enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                },
            },
        );
    };

    return (
        <Dialog
            {...dialogProps}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                component: "form",
                onSubmit: form.handleSubmit(handleSubmit),
                noValidate: true,
            }}
        >
            <DialogTitle>Add script schedule settings file</DialogTitle>
            <DialogContent dividers>
                <Stack spacing={2}>
                    <RhfTextField
                        control={form.control}
                        name="description"
                        label="Description"
                        fullWidth
                        required
                    />
                    <FileUploadField
                        control={form.control}
                        name="file"
                        label="File"
                        createSignedPostPath={`/clients/${clientId}/hosts/${hostId}/disaster-recovery/signed-post`}
                        maxSize={1024 * 1024}
                        required
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color="inherit" onClick={dialogProps.onClose}>
                    Cancel
                </Button>
                <LoadingButton type="submit" loading={addSettingsFileMutation.isPending}>
                    Add
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default AddSettingsFileDialog;
