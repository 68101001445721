import type { ThemeMode } from "@/components/ThemeProvider/ThemeProvider.tsx";
import { useThemeContext } from "@/components/ThemeProvider/index.ts";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import SettingsBrightnessIcon from "@mui/icons-material/SettingsBrightness";
import { ToggleButton, ToggleButtonGroup, styled } from "@mui/material";
import type { ReactNode } from "react";

const IconToggleButton = styled(ToggleButton)({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    "& > *": {
        marginRight: "8px",
    },
});

const ModeSwitch = (): ReactNode => {
    const { mode, switchMode } = useThemeContext();

    return (
        <ToggleButtonGroup
            fullWidth
            exclusive
            value={mode}
            size="small"
            onChange={(_event, mode) => {
                if (mode) {
                    switchMode(mode as ThemeMode);
                }
            }}
        >
            <IconToggleButton value="light">
                <LightModeIcon fontSize="small" />
                Light
            </IconToggleButton>
            <IconToggleButton value="system">
                <SettingsBrightnessIcon fontSize="small" />
                System
            </IconToggleButton>
            <IconToggleButton value="dark">
                <DarkModeIcon fontSize="small" />
                Dark
            </IconToggleButton>
        </ToggleButtonGroup>
    );
};

export default ModeSwitch;
