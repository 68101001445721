import CardField from "@/components/CardField";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Stack, type TypographyProps } from "@mui/material";
import type { ReactNode } from "react";

type Props = {
    enabled: boolean;
};

const StatusCardField = ({ enabled }: Props): ReactNode => {
    let value: ReactNode;
    let valueColor: TypographyProps["color"];

    switch (enabled) {
        case true: {
            value = (
                <Stack
                    direction="row"
                    sx={{
                        alignItems: "center",
                        gap: 0.5,
                    }}
                >
                    <CheckCircleOutlineIcon fontSize="inherit" />
                    <span>Enabled</span>
                </Stack>
            );
            valueColor = "success.main";
            break;
        }
        case false: {
            value = (
                <Stack
                    direction="row"
                    sx={{
                        alignItems: "center",
                        gap: 0.5,
                    }}
                >
                    <CancelOutlinedIcon fontSize="inherit" />
                    <span>Disabled</span>
                </Stack>
            );
            valueColor = "error.main";
            break;
        }
    }
    return <CardField label="Status" value={value} valueColor={valueColor} />;
};
export default StatusCardField;
