import type { AuthenticatedFetch } from "@/hooks/useAuthenticatedFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { queryOptions } from "@tanstack/react-query";
import {
    createDataSelector,
    createResourceCollectionSelector,
    handleJsonApiError,
} from "jsonapi-zod-query";
import { z } from "zod";
import { zj } from "zod-joda";

const attributesSchema = z.object({
    number: z.string().min(1),
    period: z.object({
        from: zj.localDate(),
        to: zj.localDate(),
    }),
    amount: z.number().int(),
});

const invoicesSelector = createDataSelector(
    createResourceCollectionSelector({
        type: "invoice",
        attributesSchema,
    }),
);

export type Invoice = ReturnType<typeof invoicesSelector>[number];

export const createInvoiceQueryOptionsFactory = (authFetch: AuthenticatedFetch) => ({
    list: (clientId: string) =>
        queryOptions({
            queryKey: ["client", clientId, "invoices"],
            queryFn: async ({ signal }) => {
                const url = apiUrl(`/clients/${clientId}/invoices`);
                const response = await authFetch(url, { signal });
                await handleJsonApiError(response);
                return invoicesSelector(await response.json());
            },
        }),
});
