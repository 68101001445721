import PasswordField from "@/components/PasswordField";
import { FormControlLabel, FormHelperText } from "@mui/material";
import { getProperty } from "dot-prop";
import { RhfSwitch, RhfTextField } from "mui-rhf-integration";
import type { ReactNode } from "react";
import type { Control } from "react-hook-form";
import { match } from "ts-pattern";
import type { ValueProperty } from "../../-utils/layout.ts";

type Props = {
    control: Control;
    property: ValueProperty<"config">;
    outputs?: Record<string, unknown>;
    disabled?: boolean;
};

const ConfigValueField = ({ control, property, outputs, disabled }: Props): ReactNode => {
    return match(property.type)
        .with("boolean", () => {
            return (
                <div>
                    <FormControlLabel
                        control={
                            <RhfSwitch
                                control={control}
                                name={`config.${property.path}`}
                                disabled={disabled}
                            />
                        }
                        label={property.title}
                    />
                    {property.description && (
                        <FormHelperText>{property.description}</FormHelperText>
                    )}
                </div>
            );
        })
        .with("string", () => {
            if (property.maskValue) {
                return (
                    <PasswordField
                        control={control}
                        name={`config.${property.path}`}
                        label={property.title}
                        helperText={property.description}
                        required={property.required}
                        disabled={disabled}
                    />
                );
            }

            let placeholder: string | undefined = undefined;

            if (property.placeholderFromOutputs) {
                placeholder = getProperty(outputs, property.placeholderFromOutputs);
            }

            return (
                <RhfTextField
                    control={control}
                    name={`config.${property.path}`}
                    label={property.title}
                    helperText={property.description}
                    required={property.required}
                    placeholder={placeholder}
                    slotProps={{
                        inputLabel: placeholder !== undefined ? { shrink: true } : undefined,
                    }}
                    disabled={disabled}
                />
            );
        })
        .exhaustive();
};

export default ConfigValueField;
