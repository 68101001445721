import type { ControlledDialogProps } from "@/hooks/useDialogController.tsx";
import type { StripeSetupIntent } from "@/queries/stripe.ts";
import { stripePromise } from "@/utils/stripe.ts";
import { Elements } from "@stripe/react-stripe-js";
import type { ReactNode } from "react";
import SetupForm from "./SetupForm.tsx";
import useElementsAppearance from "./useElementsAppearance.ts";

type Props = {
    dialogProps: ControlledDialogProps;
    setupIntent: StripeSetupIntent;
};

const SetupDialog = ({ dialogProps, setupIntent }: Props): ReactNode => {
    const elementsAppearance = useElementsAppearance();

    return (
        <Elements
            stripe={stripePromise}
            options={{
                clientSecret: setupIntent.clientSecret,
                appearance: elementsAppearance,
            }}
        >
            <SetupForm dialogProps={dialogProps} />
        </Elements>
    );
};

export default SetupDialog;
