import useDialogController from "@/hooks/useDialogController.tsx";
import type { ListHost } from "@/queries/host.ts";
import CreateIcon from "@mui/icons-material/Create";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import type { ReactNode } from "react";
import { createPortal } from "react-dom";
import invariant from "tiny-invariant";
import AgreementDialog from "./AgreementDialog.tsx";

type Props = {
    host: ListHost;
};

const AgreementActionItem = ({ host }: Props): ReactNode => {
    const dialogController = useDialogController();
    invariant(host.agreement, "Agreement missing in host");

    let listItemElements: ReactNode;

    if (host.agreement.signature) {
        listItemElements = (
            <>
                <ListItemIcon>
                    <CreateIcon />
                </ListItemIcon>
                <ListItemText>View agreement</ListItemText>
            </>
        );
    } else {
        listItemElements = (
            <>
                <ListItemIcon>
                    <CreateIcon color="warning" />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ color: "warning.main" }}>
                    View & accept agreement
                </ListItemText>
            </>
        );
    }

    return (
        <>
            <ListItemButton
                onClick={() => {
                    dialogController.open();
                }}
            >
                {listItemElements}
            </ListItemButton>

            {dialogController.mount &&
                createPortal(
                    <AgreementDialog host={host} dialogProps={dialogController.dialogProps} />,
                    document.body,
                )}
        </>
    );
};

export default AgreementActionItem;
