import { Box } from "@mui/material";
import { type ReactNode, useCallback, useRef, useState } from "react";

type Props = {
    children: ReactNode;
    disableTransition?: boolean;
};

const SmoothTransitionBox = ({ children, disableTransition }: Props): ReactNode => {
    const [contentHeight, setContentHeight] = useState<number | "auto">("auto");

    const observer = useRef(
        new ResizeObserver((entries) => {
            setContentHeight(entries[0].contentRect.height);
        }),
    );

    const contentRef = useCallback((node: HTMLDivElement) => {
        if (node === null) {
            observer.current.disconnect();
            return;
        }

        observer.current.observe(node);
    }, []);

    if (disableTransition) {
        return <div>{children}</div>;
    }

    return (
        <Box
            sx={{
                height: contentHeight,
                transition: "height 0.3s ease",
                overflow: "hidden",
            }}
        >
            <div ref={contentRef}>{children}</div>
        </Box>
    );
};

export default SmoothTransitionBox;
