import { Stack, Typography } from "@mui/material";
import type { ReactNode } from "react";
import type { Control } from "react-hook-form";
import type { DisplayGroup } from "../../-utils/layout.ts";
import ConfigValueField from "./ConfigValueField.tsx";

type Props = {
    control: Control;
    displayGroup: DisplayGroup<"config">;
    outputs?: Record<string, unknown>;
    disabled?: boolean;
};

const ConfigDisplayGroup = ({ control, displayGroup, outputs, disabled }: Props): ReactNode => (
    <div>
        <Typography variant="h6" sx={{ mb: 1 }}>
            {displayGroup.title}
        </Typography>

        <Stack spacing={2}>
            {displayGroup.properties.map((property) => (
                <ConfigValueField
                    key={property.key}
                    property={property}
                    outputs={outputs}
                    control={control}
                    disabled={disabled}
                />
            ))}
        </Stack>
    </div>
);

export default ConfigDisplayGroup;
