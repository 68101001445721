import type { AuthenticatedFetch } from "@/hooks/useAuthenticatedFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { queryOptions } from "@tanstack/react-query";
import {
    createDataSelector,
    createResourceCollectionSelector,
    handleJsonApiError,
} from "jsonapi-zod-query";
import { z } from "zod";
import { zj } from "zod-joda";

const actionRequestAttributesSchema = z.object({
    category: z.enum(["billing", "hosts"]),
    severity: z.enum(["info", "warning", "error"]),
    title: z.string().min(1),
    message: z.string().min(1),
});

const actionRequestsSelector = createDataSelector(
    createResourceCollectionSelector({
        type: "action_request",
        attributesSchema: actionRequestAttributesSchema,
    }),
);

export type ActionRequest = ReturnType<typeof actionRequestsSelector>[number];

const announcementAttributesSchema = z.object({
    title: z.string().min(1),
    date: zj.localDate(),
    content: z.string().min(1),
});

const announcementsSelector = createDataSelector(
    createResourceCollectionSelector({
        type: "announcement",
        attributesSchema: announcementAttributesSchema,
    }),
);

export type Announcement = ReturnType<typeof announcementsSelector>[number];

export const createDashboardQueryOptionsFactory = (authFetch: AuthenticatedFetch) => ({
    listActionRequests: (clientId: string) =>
        queryOptions({
            queryKey: ["client", clientId, "dashboard", "action-requests"],
            queryFn: async ({ signal }) => {
                const url = apiUrl(`/clients/${clientId}/dashboard/action-requests`);
                const response = await authFetch(url, { signal });
                await handleJsonApiError(response);
                return actionRequestsSelector(await response.json());
            },
        }),
    listAnnouncements: (clientId: string) =>
        queryOptions({
            queryKey: ["client", clientId, "dashboard", "announcements"],
            queryFn: async ({ signal }) => {
                const url = apiUrl(`/clients/${clientId}/dashboard/announcements`);
                const response = await authFetch(url, { signal });
                await handleJsonApiError(response);
                return announcementsSelector(await response.json());
            },
        }),
});
