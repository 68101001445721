import type { StripePaymentMethod } from "@/queries/stripe.ts";
import { LoadingButton } from "@mui/lab";
import { Card, CardActions, CardContent, CardHeader, Typography } from "@mui/material";
import type { ReactNode } from "react";

type Props = {
    paymentMethod: StripePaymentMethod;
    setup: {
        start: () => void;
        isStarting: boolean;
    } | null;
};

const PaymentMethodCard = ({ paymentMethod, setup }: Props): ReactNode => {
    let content: ReactNode;

    switch (paymentMethod.type) {
        case "card": {
            content = (
                <>
                    <Typography>Credit card ending on {paymentMethod.lastFourDigits}</Typography>
                    <Typography>
                        Expires {paymentMethod.expiration.month}/{paymentMethod.expiration.year}
                    </Typography>
                </>
            );
            break;
        }

        case "us_bank_account": {
            content = <Typography>US bank account</Typography>;
            break;
        }

        default: {
            content = <Typography>Other payment method</Typography>;
            break;
        }
    }

    return (
        <Card sx={{ flexGrow: 1, minWidth: "50%" }}>
            <CardHeader title="Current Method" />
            <CardContent>{content}</CardContent>
            {setup && (
                <CardActions>
                    <LoadingButton size="small" loading={setup.isStarting} onClick={setup.start}>
                        Change payment method
                    </LoadingButton>
                </CardActions>
            )}
        </Card>
    );
};

export default PaymentMethodCard;
