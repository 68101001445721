import { DateTimeFormatter, ZoneId } from "@js-joda/core";
import { Locale } from "@js-joda/locale_en-us";

export const localZone = ZoneId.systemDefault();

const currencyFormatter = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });
export const formatCents = (cents: number): string => currencyFormatter.format(cents / 100);

export const dateFormatter = DateTimeFormatter.ofPattern("MM/dd/yyyy");
export const zonedDateTimeFormatter = DateTimeFormatter.ofPattern(
    "MM/dd/yyyy hh:mm a ('UTC'xxx)",
).withLocale(Locale.US);
export const localDateTimeFormatter = DateTimeFormatter.ofPattern("MM/dd/yyyy hh:mm a").withLocale(
    Locale.US,
);
