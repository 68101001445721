import { Box, Divider } from "@mui/material";
import { type ReactNode, useEffect, useRef } from "react";

type Props = {
    header?: ReactNode;
    subheader?: ReactNode;
    footer?: ReactNode;
    children: ReactNode;
};

const BodyContainer = ({ header, subheader, footer, children }: Props): ReactNode => {
    const bodyRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (children && bodyRef.current) {
            bodyRef.current.scrollTo({ top: 0, left: 0, behavior: "instant" });
        }
    }, [children]);

    return (
        <Box
            sx={{
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
                height: "100%",
            }}
        >
            {header && (
                <Box>
                    <Box
                        sx={{
                            px: 3,
                            py: 2,
                        }}
                    >
                        {header}
                    </Box>
                    <Divider />
                    {subheader && (
                        <>
                            {subheader}
                            <Divider />
                        </>
                    )}
                </Box>
            )}
            <Box
                ref={bodyRef}
                sx={{
                    p: 3,
                    flexGrow: 1,
                    overflow: "auto",
                }}
            >
                {children}
            </Box>
            {footer && (
                <Box>
                    <Divider />
                    {footer}
                </Box>
            )}
        </Box>
    );
};

export default BodyContainer;
