import type { FileUpload } from "@/components/FileUploadField";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { type UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { handleJsonApiError } from "jsonapi-zod-query";

type AddFileValues = {
    description: string;
    file: FileUpload;
};

export const useAddFileMutation = (
    clientId: string,
): UseMutationResult<void, Error, AddFileValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(apiUrl(`/clients/${clientId}/file-locker/files`), {
                method: "POST",
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
                body: JSON.stringify({
                    data: {
                        type: "file_locker_file",
                        attributes: values,
                    },
                }),
            });
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["client", clientId, "file-locker", "files"],
            });
        },
    });
};
