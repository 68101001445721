import HostHelpSection from "@/components/HostHelpSection";
import { BodyContainer } from "@/components/Layout";
import { InlineSpinner } from "@/components/Spinner";
import { useQueryOptionsFactory } from "@/queries";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { Alert } from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import type { ReactNode } from "react";
import ConfigurationCard from "./-components/ConfigurationCard.tsx";
import WorkerList from "./-components/WorkerList.tsx";

const Root = (): ReactNode => {
    const { id: clientId } = useActiveClient();
    const params = Route.useParams();
    const qof = useQueryOptionsFactory();
    const {
        data: {
            data: configuration,
            meta: { availableOptions },
        },
    } = useSuspenseQuery(qof.webdirectAutoScaling.getConfiguration(clientId, params.hostId));
    const { data: workers } = useSuspenseQuery(
        qof.webdirectAutoScaling.listWorkers(clientId, params.hostId),
    );

    return (
        <div>
            <ConfigurationCard configuration={configuration} availableOptions={availableOptions} />

            {workers.length === 0 ? (
                <Alert severity="info" sx={{ mt: 2 }}>
                    There are currently no workers running.
                </Alert>
            ) : (
                <WorkerList workers={workers} sx={{ mt: 2 }} />
            )}

            <HostHelpSection
                supportResources={[
                    { label: "Using WebDirect Auto-Scaling", type: "webdirect_auto_scaling" },
                ]}
            />
        </div>
    );
};

export const Route = createFileRoute("/$clientId/hosts/$hostId/webdirect-auto-scaling/")({
    component: Root,
    pendingComponent: () => (
        <BodyContainer>
            <InlineSpinner />
        </BodyContainer>
    ),
    loader: async ({ context, params }) => {
        await Promise.all([
            context.queryClient.ensureQueryData(
                context.qof.webdirectAutoScaling.getConfiguration(params.clientId, params.hostId),
            ),
            context.queryClient.ensureQueryData(
                context.qof.webdirectAutoScaling.listWorkers(params.clientId, params.hostId),
            ),
        ]);
    },
});
