import CardField from "@/components/CardField";
import ConfirmDialog from "@/components/ConfirmDialog";
import { useDeleteSideCarDeploymentMutation } from "@/mutations/sidecar.ts";
import type { SideCarDeployment } from "@/queries/sidecar.ts";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { createDisplayGroups } from "@/routes/$clientId/sidecar/-utils/layout.ts";
import { getOutputsSchema } from "@/sidecar/manifest.ts";
import { getErrorMessage } from "@/utils/api.ts";
import { Alert, Button, DialogActions, DialogContent, Divider, Stack } from "@mui/material";
import { getProperty } from "dot-prop";
import { useSnackbar } from "notistack";
import { type ReactNode, useCallback, useMemo } from "react";
import { useConfirm } from "react-confirm-hook";
import OutputsDisplayGroup from "./OutputsDisplayGroup.tsx";
import OutputsValueCardField from "./OutputsValueCardField.tsx";

type Props = {
    deployment: SideCarDeployment;
    onClose: () => void;
};

const GeneralTab = ({ deployment, onClose }: Props): ReactNode => {
    const { id: clientId } = useActiveClient();
    const deleteMutation = useDeleteSideCarDeploymentMutation(clientId);
    const confirm = useConfirm(ConfirmDialog);
    const { enqueueSnackbar } = useSnackbar();

    const outputs = useMemo(() => {
        if (!deployment.metadata) {
            return <Alert severity="info">SideCar has not generated outputs yet.</Alert>;
        }

        const schema = getOutputsSchema(deployment.product.manifest);
        const parseResult = schema.safeParse(deployment.metadata.outputs);

        if (!parseResult.success) {
            return (
                <Alert severity="error">
                    SideCar outputs are malformed, please contact support.
                </Alert>
            );
        }

        const outputs = parseResult.data;
        const [displayGroups, rootProperties] = createDisplayGroups(
            deployment.product.manifest,
            "outputs",
        );

        return (
            <Stack spacing={2}>
                {rootProperties.map((property) => (
                    <OutputsValueCardField
                        key={property.key}
                        valueProperty={property}
                        value={getProperty(outputs, property.path)}
                    />
                ))}

                {displayGroups.map((displayGroup) => (
                    <OutputsDisplayGroup
                        key={displayGroup.key}
                        displayGroup={displayGroup}
                        values={outputs}
                    />
                ))}
            </Stack>
        );
    }, [deployment]);

    const handleDelete = useCallback(() => {
        confirm({
            title: "Delete SideCar",
            message: `Are you sure you want to delete ${deployment.title}?`,
            onConfirm: async () => {
                try {
                    await deleteMutation.mutateAsync({
                        deploymentId: deployment.id,
                    });
                    enqueueSnackbar("SideCar is scheduled for deletion", { variant: "success" });
                    onClose();
                } catch (error) {
                    enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                }
            },
        });
    }, [confirm, deployment, deleteMutation, enqueueSnackbar, onClose]);

    return (
        <>
            <DialogContent dividers>
                <Stack spacing={2}>
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                        <CardField
                            label="Account"
                            value={deployment.account.name}
                            sx={{ width: "100%" }}
                        />
                        <CardField
                            label="Region"
                            value={deployment.region.name}
                            sx={{ width: "100%" }}
                        />
                    </Stack>
                    <Divider />
                    {outputs}
                </Stack>
            </DialogContent>
            <DialogActions>
                {!deployment.deletable && (
                    <Button color="error" sx={{ mr: "auto" }} onClick={handleDelete}>
                        Delete
                    </Button>
                )}
                <Button color="inherit" onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </>
    );
};

export default GeneralTab;
