import Spinner from "@/components/Spinner/Spinner.tsx";
import { Box, type SxProps } from "@mui/material";
import type { ReactNode } from "react";

type Props = {
    sx?: Omit<SxProps, "display" | "width" | "justifyContent">;
};

const InlineSpinner = ({ sx }: Props): ReactNode => {
    return (
        <Box
            sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                ...sx,
            }}
        >
            <Spinner size={44} />
        </Box>
    );
};

export default InlineSpinner;
