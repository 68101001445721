import CardField from "@/components/CardField";
import type { SideCarDeployment } from "@/queries/sidecar.ts";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import PendingIcon from "@mui/icons-material/Pending";
import { Stack, type SxProps } from "@mui/material";
import type { ReactNode } from "react";
import { match } from "ts-pattern";

type Props = {
    state: SideCarDeployment["state"];
    sx?: SxProps;
};

const StateCardField = ({ state, sx }: Props): ReactNode => {
    const [Icon, label, valueColor] = match(state)
        .with("deploying", () => [PendingIcon, "Deploying…", "info.main"] as const)
        .with("deployed", () => [CheckCircleIcon, "Deployed", "success.main"] as const)
        .with("removing", () => [PendingIcon, "Removing…", "warning.main"] as const)
        .with("failed", () => [ErrorIcon, "Failed", "error.main"] as const)
        .exhaustive();

    return (
        <CardField
            label="State"
            sx={sx}
            value={
                <Stack
                    direction="row"
                    sx={{
                        alignItems: "center",
                        gap: 0.5,
                    }}
                >
                    <Icon fontSize="inherit" />
                    <span>{label}</span>
                </Stack>
            }
            valueColor={valueColor}
        />
    );
};
export default StateCardField;
