import type { HostVolume } from "@/queries/host-volume.ts";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { Stack } from "@mui/material";
import type { ReactNode } from "react";

type Props = {
    volume: HostVolume;
};

const FreeSpaceState = ({ volume }: Props): ReactNode => {
    const percentageAvailable = 100 - (volume.size.used / volume.size.allocated) * 100;

    if (percentageAvailable < 10) {
        return (
            <Stack
                direction="row"
                sx={{
                    alignItems: "center",
                    gap: 0.5,
                    color: "info.main",
                }}
            >
                <ErrorOutlinedIcon fontSize="inherit" />
                <span>Critically low space</span>
            </Stack>
        );
    }
    if (percentageAvailable < 20) {
        return (
            <Stack
                direction="row"
                sx={{
                    alignItems: "center",
                    gap: 0.5,
                    color: "warning.main",
                }}
            >
                <WarningAmberOutlinedIcon fontSize="inherit" />
                <span>Low space</span>
            </Stack>
        );
    }
    if (percentageAvailable < 30) {
        return (
            <Stack
                direction="row"
                sx={{
                    alignItems: "center",
                    gap: 0.5,
                    color: "info.main",
                }}
            >
                <InfoOutlinedIcon fontSize="inherit" />
                <span>Approaching low space</span>
            </Stack>
        );
    }
    return (
        <Stack
            direction="row"
            sx={{
                alignItems: "center",
                gap: 0.5,
                color: "success.main",
            }}
        >
            <CheckCircleOutlineIcon fontSize="inherit" />
            <span>Sufficient space</span>
        </Stack>
    );
};
export default FreeSpaceState;
