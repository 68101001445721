import type { ActionRequest } from "@/queries/dashboard.ts";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Alert, AlertTitle, IconButton, Stack, Typography } from "@mui/material";
import { Link } from "@tanstack/react-router";
import type { ReactNode } from "react";
import Markdown from "react-markdown";
import { match } from "ts-pattern";

type Props = {
    actionRequests: ActionRequest[];
};

const ActionRequestList = ({ actionRequests }: Props): ReactNode => (
    <Stack spacing={2}>
        {actionRequests.map((request) => (
            <Alert
                key={request.id}
                severity={request.severity}
                action={
                    <IconButton
                        component={Link}
                        to={match(request.category)
                            .with("billing", () => "/$clientId/billing")
                            .with("hosts", () => "/$clientId/hosts")
                            .exhaustive()}
                    >
                        <ArrowForwardIcon />
                    </IconButton>
                }
            >
                <AlertTitle>{request.title}</AlertTitle>
                <Markdown
                    skipHtml
                    allowedElements={["p"]}
                    unwrapDisallowed
                    components={{
                        p: ({ children }) => (
                            <Typography variant="inherit" sx={{ mb: 2, "&:last-child": { mb: 0 } }}>
                                {children}
                            </Typography>
                        ),
                    }}
                >
                    {request.message}
                </Markdown>
            </Alert>
        ))}
    </Stack>
);

export default ActionRequestList;
