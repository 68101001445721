import CloudDoneIcon from "@mui/icons-material/CloudDone";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, Typography } from "@mui/material";
import humanFormat from "human-format";
import type { ReactNode } from "react";
import type { FileUpload } from "./FileUploadField.tsx";

type Props = {
    value: FileUpload;
    onDelete: () => void;
};

const UploadedElements = ({ value, onDelete }: Props): ReactNode => {
    return (
        <>
            <Box
                sx={{
                    pl: 2,
                    width: 64,
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <CloudDoneIcon />
            </Box>
            <Box
                sx={{
                    flexGrow: 1,
                    pl: 2,
                    boxSizing: "content-box",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <div>
                    <Typography variant="body1">{value.filename}</Typography>
                    <Typography variant="caption">
                        {humanFormat(value.size, { scale: "binary", unit: "B" })}
                    </Typography>
                </div>
            </Box>
            <IconButton
                sx={{ mx: 2 }}
                onClick={() => {
                    onDelete();
                }}
            >
                <DeleteIcon />
            </IconButton>
        </>
    );
};

export default UploadedElements;
