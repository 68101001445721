import type { AuthenticatedFetch } from "@/hooks/useAuthenticatedFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { queryOptions } from "@tanstack/react-query";
import {
    createDataSelector,
    createResourceCollectionSelector,
    createResourceSelector,
    handleJsonApiError,
} from "jsonapi-zod-query";
import { z } from "zod";

const personSchema = z.object({
    name: z.string().min(1),
    emailAddress: z.string().nullable(),
    phoneNumber: z.string().nullable(),
});

const clientAttributesSchema = z.object({
    name: z.string(),
    address: z.object({
        street: z.string(),
        city: z.string(),
        postalCode: z.string(),
        region: z.string(),
        countryCode: z.string(),
    }),
    contactPerson: personSchema.nullable(),
    billingPerson: personSchema.nullable(),
    stripe: z
        .object({
            customerId: z.string().nullable(),
            setupIntentId: z.string().nullable(),
            paymentMethodId: z.string().nullable(),
        })
        .optional(),
});

const documentMetaSchema = z.object({
    selfAssignAllowed: z.boolean(),
});

const clientsSelector = createResourceCollectionSelector({
    type: "client",
    attributesSchema: clientAttributesSchema.pick({ name: true }),
    documentMetaSchema,
});

const clientSelector = createDataSelector(
    createResourceSelector({
        type: "client",
        attributesSchema: clientAttributesSchema,
        relationships: {
            accounts: {
                relationshipType: "many",
                include: {
                    type: "aws_account",
                    attributesSchema: z.object({
                        name: z.string(),
                    }),
                },
            },
        },
    }),
);

export type ClientPerson = z.output<typeof personSchema>;
export type ClientsResult = ReturnType<typeof clientsSelector>;
export type ListClient = ClientsResult["data"][number];
export type Client = ReturnType<typeof clientSelector>;

type ListClientsOptions = {
    filterName?: string;
    filterAssigned?: boolean;
};

export const createClientQueryOptionsFactory = (authFetch: AuthenticatedFetch) => ({
    list: (options?: ListClientsOptions) =>
        queryOptions({
            queryKey: ["clients", options],
            queryFn: async ({ signal }) => {
                const url = apiUrl("/clients");
                url.searchParams.set("fields[client]", "name");

                if (options?.filterName) {
                    url.searchParams.set("filter[name]", options.filterName);
                }

                if (options?.filterAssigned !== undefined) {
                    url.searchParams.set("filter[assigned]", options.filterAssigned.toString());
                }

                const response = await authFetch(url, { signal });
                await handleJsonApiError(response);
                return clientsSelector(await response.json());
            },
        }),
    get: (clientId: string) =>
        queryOptions({
            queryKey: ["client", clientId],
            queryFn: async ({ signal }) => {
                const response = await authFetch(apiUrl(`/clients/${clientId}`), { signal });
                await handleJsonApiError(response);
                return clientSelector(await response.json());
            },
        }),
});
