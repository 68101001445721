import { BodyContainer } from "@/components/Layout";
import { InlineSpinner } from "@/components/Spinner";
import { useQueryOptionsFactory } from "@/queries";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { Alert } from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import type { ReactNode } from "react";
import EventList from "./-components/EventList.tsx";

const Root = (): ReactNode => {
    const { id: clientId } = useActiveClient();
    const params = Route.useParams();
    const qof = useQueryOptionsFactory();
    const { data: events } = useSuspenseQuery(qof.hostEvent.list(clientId, params.hostId));

    if (events.length === 0) {
        return <Alert severity="info">There are currently no host events occurring.</Alert>;
    }

    return <EventList events={events} />;
};

export const Route = createFileRoute("/$clientId/hosts/$hostId/events/")({
    component: Root,
    pendingComponent: () => (
        <BodyContainer>
            <InlineSpinner />
        </BodyContainer>
    ),
    loader: async ({ context, params }) => {
        await context.queryClient.ensureQueryData(
            context.qof.hostEvent.list(params.clientId, params.hostId),
        );
    },
});
