import type { AuthenticatedFetch } from "@/hooks/useAuthenticatedFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { queryOptions } from "@tanstack/react-query";
import { createDataSelector, createResourceSelector, handleJsonApiError } from "jsonapi-zod-query";
import { z } from "zod";

const attributesSchema = z.object({
    type: z.string().min(1),
    hostId: z.string().min(1).optional(),
    title: z.string().min(1),
    message: z.string().min(1),
    links: z.array(
        z.object({
            url: z.string().url(),
            label: z.string().min(1),
        }),
    ),
    formEnabled: z.boolean().optional(),
});

const supportResourceSelector = createDataSelector(
    createResourceSelector({
        type: "support_resource",
        attributesSchema,
    }),
);

export type SupportResource = ReturnType<typeof supportResourceSelector>;

export const createSupportResourceQueryOptionsFactory = (authFetch: AuthenticatedFetch) => ({
    get: (clientId: string, type: string, hostId: string | undefined) =>
        queryOptions({
            queryKey: ["client", clientId, "support-resource", type, hostId],
            queryFn: async ({ signal }) => {
                const url = hostId
                    ? apiUrl(`/clients/${clientId}/hosts/${hostId}/support-resources/${type}`)
                    : apiUrl(`/clients/${clientId}/support-resources/${type}`);
                const response = await authFetch(url, { signal });
                await handleJsonApiError(response);
                return supportResourceSelector(await response.json());
            },
        }),
});
