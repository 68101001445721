import useDialogController from "@/hooks/useDialogController.tsx";
import type { FileMakerServer } from "@/queries/filemaker-server.ts";
import EditIcon from "@mui/icons-material/Edit";
import {
    Card,
    CardContent,
    CardHeader,
    IconButton,
    Link,
    type SxProps,
    Typography,
} from "@mui/material";
import type { ReactNode } from "react";
import { createPortal } from "react-dom";
import ChangeWebDirectCustomHomeUrlDialog from "./ChangeWebDirectCustomHomeUrlDialog.tsx";

type Props = {
    server: FileMakerServer;
    sx?: SxProps;
};

const WebDirectCustomHomeUrlCard = ({ server, sx }: Props): ReactNode => {
    const dialogController = useDialogController();

    return (
        <>
            <Card sx={sx}>
                <CardHeader
                    title="WebDirect custom home URL"
                    titleTypographyProps={{ variant: "body1" }}
                    action={
                        <IconButton onClick={dialogController.open}>
                            <EditIcon />
                        </IconButton>
                    }
                />
                <CardContent>
                    <Typography
                        variant="body2"
                        sx={{
                            mb: 2,
                        }}
                    >
                        {server.webDirectCustomHomeUrl ? (
                            <>
                                Custom home URL set to:
                                <br />
                                <Link
                                    href={server.webDirectCustomHomeUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {server.webDirectCustomHomeUrl}
                                </Link>
                            </>
                        ) : (
                            "You have not configured a custom home URL."
                        )}
                    </Typography>
                </CardContent>
            </Card>
            {dialogController.mount &&
                createPortal(
                    <ChangeWebDirectCustomHomeUrlDialog
                        hostId={server.hostId}
                        url={server.webDirectCustomHomeUrl}
                        dialogProps={dialogController.dialogProps}
                    />,
                    document.body,
                )}
        </>
    );
};
export default WebDirectCustomHomeUrlCard;
