import { useTimeZoneContext } from "@/components/TimeZoneProvider";
import type { ControlledDialogProps } from "@/hooks/useDialogController.tsx";
import { useLoadRestorePointMutation } from "@/mutations/disaster-recovery.ts";
import type {
    DisasterRecoveryConfiguration,
    DisasterRecoveryRestorePoint,
} from "@/queries/disaster-recovery.ts";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { useActiveHost } from "@/routes/$clientId/hosts/$hostId.tsx";
import { getErrorMessage } from "@/utils/api.ts";
import { zonedDateTimeFormatter } from "@/utils/format.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    FormHelperText,
    Link,
} from "@mui/material";
import { RhfSwitch } from "mui-rhf-integration";
import { useSnackbar } from "notistack";
import { type ReactNode, useCallback } from "react";
import { useForm } from "react-hook-form";
import { match } from "ts-pattern";
import { z } from "zod";

const schema = z.object({
    openDatabases: z.boolean(),
});

type FieldValues = z.input<typeof schema>;
type TransformedValues = z.output<typeof schema>;

type Props = {
    dialogProps: ControlledDialogProps;
    restorePoint: DisasterRecoveryRestorePoint;
    configuration: DisasterRecoveryConfiguration;
};

const LoadRestorePointDialog = ({ dialogProps, restorePoint, configuration }: Props): ReactNode => {
    const { id: clientId } = useActiveClient();
    const host = useActiveHost();
    const loadMutation = useLoadRestorePointMutation(clientId);
    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema),
        defaultValues: {
            openDatabases: true,
        },
    });
    const { enqueueSnackbar } = useSnackbar();
    const { zoneId } = useTimeZoneContext();

    const handleSubmit = useCallback(
        (values: TransformedValues) => {
            loadMutation.mutate(
                {
                    hostId: host.id,
                    restorePointId: restorePoint.id,
                    ...values,
                },
                {
                    onSuccess: (message) => {
                        enqueueSnackbar(message, { variant: "success" });
                        dialogProps.onClose();
                    },
                    onError: (error) => {
                        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                    },
                },
            );
        },
        [enqueueSnackbar, host.id, restorePoint.id, loadMutation, dialogProps.onClose],
    );

    return (
        <Dialog
            {...dialogProps}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                component: "form",
                noValidate: true,
                onSubmit: form.handleSubmit(handleSubmit),
            }}
        >
            <DialogTitle>Launch host from restore point</DialogTitle>
            <DialogContent dividers>
                <DialogContentText sx={{ mb: 3 }}>
                    Launch host using restore point {restorePoint.description} from{" "}
                    {restorePoint.createdAt
                        .withZoneSameInstant(zoneId)
                        .format(zonedDateTimeFormatter)}
                    .
                </DialogContentText>

                {match(configuration.model)
                    .with("on_premise", () => (
                        <Box sx={{ mb: 3 }}>
                            <DialogContentText sx={{ mb: 2 }}>
                                Launching {host.name} will load the requested restore point and
                                enable requests to {host.domainName}. You should disable connections
                                to your on-premise server and notify users to connect to{" "}
                                {host.domainName}. If required, download the latest saved Script
                                schedule settings file to load on the OptiFlex-DR host. Once loaded
                                enable the desired schedules. Consider disabling Open Databases to
                                allow for any adjustments prior to making databases available. You
                                will receive an email when {host.name} is available.
                            </DialogContentText>
                            <DialogContentText>
                                When you are ready to switch operations back to your on-premise
                                server you can download your files through the Soliant.cloud client
                                portal and stop {host.name}. Contact{" "}
                                <Link href="mailto:support@soliant.cloud">
                                    support@soliant.cloud
                                </Link>{" "}
                                if you require any assistance.
                            </DialogContentText>
                        </Box>
                    ))
                    .with("cross_region", () => (
                        <Box sx={{ mb: 3 }}>
                            <DialogContentText sx={{ mb: 2 }}>
                                Launching {host.name} will stop the protected host{" "}
                                {configuration.protectingHost.name} and update the DNS record{" "}
                                {configuration.protectingHost.domainName} to forward requests to the
                                OptiFlex Disaster Recovery host {host.name}. If required, download
                                the latest saved Script schedule settings file to load on the
                                OptiFlex-DR host. Once loaded enable the desired schedules. Consider
                                disabling Open Databases to allow for any adjustments prior to
                                making databases available. Contact support@soliant.cloud when
                                you’re ready to switch operations back to{" "}
                                {configuration.protectingHost.name}. You will receive an email when{" "}
                                {host.name} is available.
                            </DialogContentText>
                            <DialogContentText>
                                Contact{" "}
                                <Link href="mailto:support@soliant.cloud">
                                    support@soliant.cloud
                                </Link>{" "}
                                when you are ready to switch operations back to{" "}
                                {configuration.protectingHost.name}.
                            </DialogContentText>
                        </Box>
                    ))
                    .exhaustive()}

                <FormControlLabel
                    control={<RhfSwitch control={form.control} name="openDatabases" />}
                    label="Open databases"
                />
                <FormHelperText>
                    Databases will open by default. Disable this option to not open databases when
                    launched.
                </FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button color="inherit" onClick={dialogProps.onClose}>
                    Cancel
                </Button>
                <LoadingButton loading={loadMutation.isPending} type="submit">
                    Launch
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default LoadRestorePointDialog;
