import CardField from "@/components/CardField";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import type { ValueProperty } from "../../-utils/layout.ts";

type Props = {
    valueProperty: ValueProperty<"outputs">;
    value: string | boolean | undefined;
};

const OutputsValueCardField = ({ valueProperty, value }: Props): ReactNode => {
    const { enqueueSnackbar } = useSnackbar();

    if (value === undefined) {
        return null;
    }

    if (typeof value === "boolean") {
        return <CardField label={valueProperty.title} value={value ? "Yes" : "No"} />;
    }

    const outputValue = valueProperty.maskValue ? value.replace(/./g, "•") : value;

    return (
        <Stack direction="row">
            <CardField label={valueProperty.title} value={outputValue} sx={{ mr: "auto" }} />
            <IconButton
                onClick={() => {
                    navigator.clipboard
                        .writeText(value)
                        .then(() =>
                            enqueueSnackbar(`${valueProperty.title} copied to clipboard`, {
                                variant: "info",
                            }),
                        )
                        .catch(() =>
                            enqueueSnackbar("Failed to copy to clipboard", { variant: "error" }),
                        );
                }}
            >
                <ContentCopyIcon />
            </IconButton>
        </Stack>
    );
};

export default OutputsValueCardField;
