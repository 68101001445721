import { useTheme } from "@mui/material";
import type { Appearance } from "@stripe/stripe-js";

const useElementsAppearance = (): Appearance => {
    const theme = useTheme();

    return {
        labels: "floating",
        variables: {
            fontFamily: theme.typography.fontFamily,

            colorPrimary: theme.palette.primary.main,
            colorBackground: theme.palette.background.default,
            colorText: theme.palette.text.primary,
            colorSuccess: theme.palette.success.main,
            colorDanger: theme.palette.error.main,
            colorWarning: theme.palette.warning.main,

            accessibleColorOnColorPrimary: theme.palette.primary.contrastText,
            accessibleColorOnColorBackground: theme.palette.text.primary,
            accessibleColorOnColorSuccess: theme.palette.success.contrastText,
            accessibleColorOnColorDanger: theme.palette.error.contrastText,
            accessibleColorOnColorWarning: theme.palette.warning.contrastText,

            borderRadius: `${theme.shape.borderRadius}px`,
        },
    };
};

export default useElementsAppearance;
