import CardField from "@/components/CardField";
import { Duration, ZonedDateTime } from "@js-joda/core";
import prettyMilliseconds from "pretty-ms";
import { type ReactNode, useEffect, useState } from "react";

type Props = {
    lastBootedAt: ZonedDateTime;
};

const UptimeCardField = ({ lastBootedAt }: Props): ReactNode => {
    const [uptime, setUptime] = useState(() => Duration.between(lastBootedAt, ZonedDateTime.now()));

    useEffect(() => {
        let timeout: number | undefined = undefined;

        const updateUptime = () => {
            const uptime = Duration.between(lastBootedAt, ZonedDateTime.now());
            setUptime(uptime);

            timeout = window.setTimeout(updateUptime, uptime.toDays() > 0 ? 60_000 : 1_000);
        };

        updateUptime();

        return () => {
            if (timeout) {
                window.clearTimeout(timeout);
            }
        };
    }, [lastBootedAt]);

    return (
        <CardField
            label="Uptime"
            value={prettyMilliseconds(uptime.toMillis(), {
                unitCount: 3,
                secondsDecimalDigits: 0,
            })}
        />
    );
};

export default UptimeCardField;
