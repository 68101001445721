import type { DynamicDocketEvent } from "@/queries/dynamic-docket.ts";
import { Divider, List, type SxProps } from "@mui/material";
import { Fragment, type ReactNode } from "react";
import EventListItem from "./EventListItem.tsx";

type Props = {
    events: DynamicDocketEvent[];
    sx?: SxProps;
};

const EventList = ({ events, sx }: Props): ReactNode => (
    <List disablePadding sx={sx}>
        {events.map((event, index) => (
            <Fragment key={event.id}>
                {index > 0 && <Divider />}
                <EventListItem event={event} />
            </Fragment>
        ))}
    </List>
);

export default EventList;
