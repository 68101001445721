import { useQueryOptionsFactory } from "@/queries";
import type { SignedGetUrl } from "@/queries/signed-get-url.ts";
import { getErrorMessage } from "@/utils/api.ts";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import invariant from "tiny-invariant";

type StartSignedDownload = (path: string) => void;

type SignedDownload = {
    start: StartSignedDownload;
    loading: boolean;
};

const useSignedDownload = (clientId: string): SignedDownload => {
    const qof = useQueryOptionsFactory();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const start = useCallback<StartSignedDownload>(
        async (path) => {
            setLoading(true);
            const queryOptions = qof.signedGetUrl.get(clientId, path);
            invariant(queryOptions.queryFn);

            let signedUrl: SignedGetUrl;

            try {
                signedUrl = await queryClient.fetchQuery(queryOptions);
            } catch (error) {
                enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                return;
            } finally {
                setLoading(false);
            }

            window.open(signedUrl.url);
        },
        [qof, queryClient, clientId, enqueueSnackbar],
    );

    return { start, loading };
};

export default useSignedDownload;
