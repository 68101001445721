import ConfirmDialog from "@/components/ConfirmDialog";
import { useToggleHostedFtpAccessMutation } from "@/mutations/filemaker-server.ts";
import type { FileMakerServer } from "@/queries/filemaker-server.ts";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { getErrorMessage } from "@/utils/api.ts";
import { Card, CardContent, CardHeader, Switch, type SxProps, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import { useConfirm } from "react-confirm-hook";

type Props = {
    server: FileMakerServer;
    sx?: SxProps;
};

const HostedFtpAccessCard = ({ server, sx }: Props): ReactNode => {
    const { id: clientId } = useActiveClient();
    const mutation = useToggleHostedFtpAccessMutation(clientId);
    const confirm = useConfirm(ConfirmDialog);
    const { enqueueSnackbar } = useSnackbar();

    const toggleAccess = async (enabled: boolean) => {
        try {
            const message = await mutation.mutateAsync({
                hostId: server.hostId,
                enabled,
            });
            enqueueSnackbar(message, { variant: "success" });
        } catch (error) {
            enqueueSnackbar(getErrorMessage(error), { variant: "error" });
        }
    };

    const state = server.actions.toggleHostedFtpAccess;

    if (state === "disallowed") {
        return null;
    }

    return (
        <Card sx={sx}>
            <CardHeader
                title="Hosted FTP Access"
                titleTypographyProps={{ variant: "body1" }}
                action={
                    <Switch
                        checked={server.hostedFtpAccessEnabled}
                        disabled={state === "pending"}
                        onClick={() => {
                            confirm({
                                title: `${server.hostedFtpAccessEnabled ? "Disable" : "Enable"} hosted database on secure FTP`,
                                message: server.hostedFtpAccessEnabled
                                    ? "Do you really want to disabled secure FTP access?"
                                    : "Do you really want to enable secure FTP access?",
                                onConfirm: () => toggleAccess(!server.hostedFtpAccessEnabled),
                            });
                        }}
                    />
                }
            />
            <CardContent>
                <Typography
                    variant="body2"
                    sx={{
                        mb: 2,
                    }}
                >
                    Hosted FTP access allows you to temporarily access hosted databases via secure
                    FTP. Once enabled you'll have access for 72 hours.
                </Typography>
            </CardContent>
        </Card>
    );
};
export default HostedFtpAccessCard;
