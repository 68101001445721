import { useTimeZoneContext } from "@/components/TimeZoneProvider";
import type { WebdirectAutoScalingWorker } from "@/queries/webdirect-auto-scaling.ts";
import { zonedDateTimeFormatter } from "@/utils/format.ts";
import { ListItem, ListItemText, Stack } from "@mui/material";
import type { ReactNode } from "react";

type Props = {
    worker: WebdirectAutoScalingWorker;
};

const WorkerListItem = ({ worker }: Props): ReactNode => {
    const { zoneId } = useTimeZoneContext();

    return (
        <ListItem>
            <ListItemText
                primary={worker.hostname}
                secondary={
                    <Stack>
                        <div>
                            {worker.startedAt
                                .withZoneSameInstant(zoneId)
                                .format(zonedDateTimeFormatter)}
                        </div>
                        <div>{worker.statusMessage}</div>
                    </Stack>
                }
                primaryTypographyProps={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                }}
                secondaryTypographyProps={{ component: "div" }}
            />
        </ListItem>
    );
};

export default WorkerListItem;
