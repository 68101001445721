import type { ControlledDialogProps } from "@/hooks/useDialogController.tsx";
import { useResizeVolumeMutation } from "@/mutations/host-volume.ts";
import type { HostVolume } from "@/queries/host-volume.ts";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { getErrorMessage } from "@/utils/api.ts";
import { LoadingButton } from "@mui/lab";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    Slider,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import humanFormat from "human-format";
import { useSnackbar } from "notistack";
import { type ReactNode, useState } from "react";

type Props = {
    dialogProps: ControlledDialogProps;
    hostId: string;
    volume: HostVolume;
};

const ResizeDialog = ({ dialogProps, hostId, volume }: Props): ReactNode => {
    const { id: clientId } = useActiveClient();
    const [size, setSize] = useState(volume.size.allocated);
    const [inputSize, setInputSize] = useState(volume.size.allocated.toString());
    const mutation = useResizeVolumeMutation(clientId);
    const { enqueueSnackbar } = useSnackbar();

    const resizeVolume = () => {
        mutation.mutate(
            {
                hostId,
                volumeId: volume.id,
                size: size,
            },
            {
                onSuccess: (message) => {
                    enqueueSnackbar(message, { variant: "success" });
                    dialogProps.onClose();
                },
                onError: (error) => {
                    enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                },
            },
        );
    };

    return (
        <Dialog {...dialogProps} maxWidth="xs" fullWidth>
            <DialogTitle>Resize volume</DialogTitle>
            <DialogContent sx={{ overflow: "initial" }}>
                <Typography
                    sx={{
                        mb: 2,
                    }}
                >
                    You can resize this volume to a size between{" "}
                    {humanFormat(volume.size.min * 1000 * 1000 * 1000, { unit: "B" })} and{" "}
                    {humanFormat(volume.size.max * 1000 * 1000 * 1000, { unit: "B" })}.
                </Typography>
                <Stack
                    direction="row"
                    sx={{
                        alignItems: "center",
                    }}
                >
                    <Slider
                        sx={{ flexGrow: 1 }}
                        min={volume.size.min}
                        max={volume.size.max}
                        step={1}
                        shiftStep={10}
                        value={size}
                        onChange={(_event, value) => {
                            setSize(value as number);
                            setInputSize(value.toString());
                        }}
                    />
                    <TextField
                        sx={{ minWidth: 100, maxWidth: 100, width: 100, ml: 2 }}
                        size="small"
                        value={inputSize}
                        onChange={(event) => {
                            setInputSize(event.target.value);
                        }}
                        onBlur={() => {
                            const parsedSize = Number.parseInt(inputSize, 10);
                            if (Number.isNaN(parsedSize)) {
                                setInputSize(size.toString());
                                return;
                            }
                            const clampedSize = Math.min(
                                volume.size.max,
                                Math.max(volume.size.min, parsedSize),
                            );
                            setSize(clampedSize);
                            setInputSize(clampedSize.toString());
                        }}
                        slotProps={{
                            input: {
                                inputMode: "numeric",
                                endAdornment: <InputAdornment position="end">GB</InputAdornment>,
                            },
                        }}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color="inherit" onClick={dialogProps.onClose}>
                    Cancel
                </Button>
                <LoadingButton
                    onClick={resizeVolume}
                    loading={mutation.isPending}
                    disabled={size === volume.size.allocated}
                >
                    Resize
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
export default ResizeDialog;
