import SupportResource from "@/components/SupportResource";
import { useActiveHost } from "@/routes/$clientId/hosts/$hostId.tsx";
import HelpIcon from "@mui/icons-material/Help";
import { Box, Divider, Link, Typography } from "@mui/material";

type Props = {
    supportResources: { label: string; type: string }[];
};

const HostHelpSection = ({ supportResources }: Props) => {
    const { id: hostId } = useActiveHost();

    return (
        <Box sx={{ mt: 2 }}>
            <Divider />

            <Box sx={{ mt: 2 }}>
                <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
                    <HelpIcon />
                    <Typography>Additional information</Typography>
                </Box>

                <ul>
                    {supportResources.map((supportResource) => (
                        <SupportResource
                            key={supportResource.type}
                            type={supportResource.type}
                            hostId={hostId}
                            renderButton={(open) => (
                                <li>
                                    <Link onClick={open} component="button">
                                        {supportResource.label}
                                    </Link>
                                </li>
                            )}
                        />
                    ))}
                </ul>
            </Box>
        </Box>
    );
};

export default HostHelpSection;
