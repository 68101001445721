import { Card, CardContent, Typography } from "@mui/material";
import type { ReactNode } from "react";

const NotFoundCard = (): ReactNode => (
    <Card>
        <CardContent>
            <Typography variant="h5" gutterBottom>
                Not found
            </Typography>

            <Typography>The requested resource could not be found.</Typography>
        </CardContent>
    </Card>
);

export default NotFoundCard;
