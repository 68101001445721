import { useRequestRemoteAccessMutation } from "@/mutations/host.ts";
import type { Host } from "@/queries/host.ts";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { getErrorMessage } from "@/utils/api.ts";
import { LoadingButton } from "@mui/lab";
import type { SxProps } from "@mui/material";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";

type Props = {
    host: Host;
    sx?: SxProps;
};

const RemoteAccessButton = ({ host, sx }: Props): ReactNode => {
    const { id: clientId } = useActiveClient();
    const mutation = useRequestRemoteAccessMutation(clientId);
    const { enqueueSnackbar } = useSnackbar();

    if (host.remoteAccessRequest === "disallowed") {
        return null;
    }

    const requestRemoteAccess = () => {
        mutation.mutate(
            { hostId: host.id },
            {
                onSuccess: (message) => {
                    enqueueSnackbar(message, { variant: "success" });
                },
                onError: (error) => {
                    enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                },
            },
        );
    };

    return (
        <LoadingButton
            variant="outlined"
            loading={mutation.isPending}
            disabled={host.remoteAccessRequest === "pending"}
            onClick={() => {
                requestRemoteAccess();
            }}
            sx={sx}
        >
            Request remote access
        </LoadingButton>
    );
};

export default RemoteAccessButton;
