import CardField from "@/components/CardField";
import useSignedDownload from "@/hooks/useSignedDownload.ts";
import type { Invoice } from "@/queries/invoice.ts";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { dateFormatter, formatCents } from "@/utils/format.ts";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { Card, CardActionArea, CardContent, CardHeader } from "@mui/material";
import type { ReactNode } from "react";

type Props = {
    invoice: Invoice;
};

const InvoiceCard = ({ invoice }: Props): ReactNode => {
    const { id: clientId } = useActiveClient();
    const signedDownload = useSignedDownload(clientId);

    return (
        <Card>
            <CardActionArea
                disabled={signedDownload.loading}
                onClick={() => {
                    signedDownload.start(`/invoices/${invoice.id}/object-url`);
                }}
            >
                <CardHeader
                    avatar={<ReceiptIcon />}
                    title={invoice.number}
                    subheader={`${invoice.period.from.format(
                        dateFormatter,
                    )} - ${invoice.period.to.format(dateFormatter)}`}
                />
                <CardContent>
                    <CardField label="Amount" value={formatCents(invoice.amount)} />
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default InvoiceCard;
