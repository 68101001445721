import CancelIcon from "@mui/icons-material/Cancel";
import {
    Box,
    CircularProgress,
    IconButton,
    type SxProps,
    Typography,
    useTheme,
} from "@mui/material";
import humanFormat from "human-format";
import type { ReactNode } from "react";
import type { UploadStatus } from "./FileUploadField.tsx";
import type { ProgressState } from "./upload-worker.ts";

const progressStateLabels: Record<ProgressState, string> = {
    calculating_checksum: "Calculating checksum…",
    uploading: "Uploading…",
};

type Props = {
    uploadStatus: UploadStatus;
    onCancel: () => void;
};

const UploadingElements = ({
    uploadStatus: { filename, size, progress, state },
    onCancel,
}: Props): ReactNode => {
    const theme = useTheme();
    const progressBackgroundColor =
        state === "calculating_checksum"
            ? theme.palette.grey[theme.palette.mode === "light" ? 200 : 800]
            : theme.palette.secondary.main;

    const progressSx: SxProps = {
        position: "absolute",
        left: 0,
        top: 0,
    };
    let progressColor: "primary" | "secondary";
    const progressElements: ReactNode[] = [];

    if (state === "calculating_checksum") {
        progressColor = "secondary";
    } else {
        progressColor = "primary";
        progressElements.push(
            <CircularProgress
                key="secondary"
                sx={progressSx}
                color="secondary"
                variant="determinate"
                value={100}
            />,
        );
    }

    if (progress.type === "indeterminate") {
        progressElements.push(
            <CircularProgress
                key={progressColor}
                sx={progressSx}
                color={progressColor}
                variant="indeterminate"
            />,
        );
    } else {
        progressElements.push(
            <CircularProgress
                key={progressColor}
                sx={progressSx}
                color={progressColor}
                variant="determinate"
                value={(progress.current / progress.total) * 100}
            />,
        );
    }

    if (progressElements.length === 1) {
        progressElements.push(
            <CircularProgress
                key="primary"
                sx={progressSx}
                color="primary"
                variant="determinate"
                value={0}
            />,
        );
    }

    return (
        <>
            <Box
                sx={{
                    pl: 2,
                    width: 64,
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Box
                    sx={{
                        position: "relative",
                    }}
                >
                    <CircularProgress
                        variant="determinate"
                        sx={{ color: progressBackgroundColor }}
                        value={100}
                    />
                    {progressElements}
                </Box>
            </Box>
            <Box
                sx={{
                    flexGrow: 1,
                    pl: 2,
                    boxSizing: "content-box",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <div>
                    <Typography variant="body1">{filename}</Typography>
                    <Typography variant="caption">
                        {humanFormat(size, { scale: "binary", unit: "B" })} (
                        {progressStateLabels[state]})
                    </Typography>
                </div>
            </Box>
            <IconButton
                sx={{ mx: 2 }}
                onClick={() => {
                    onCancel();
                }}
            >
                <CancelIcon />
            </IconButton>
        </>
    );
};

export default UploadingElements;
