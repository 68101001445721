import FileUploadField, { fileUploadSchema } from "@/components/FileUploadField";
import SmoothTransitionBox from "@/components/SmoothTransitionBox";
import type { ControlledDialogProps } from "@/hooks/useDialogController.tsx";
import { useSelectFmsLicenseMutation } from "@/mutations/host.ts";
import type { ListHost } from "@/queries/host.ts";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { getErrorMessage } from "@/utils/api.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Stack,
    Typography,
} from "@mui/material";
import { RhfRadioGroup, RhfTextField } from "mui-rhf-integration";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z.discriminatedUnion("choice", [
    z.object({
        choice: z.literal("buy"),
    }),
    z.object({
        choice: z.literal("byol"),
        organization: z.string().trim().min(1),
        certificate: fileUploadSchema,
    }),
]);

type FieldValues = z.input<typeof schema>;
type TransformedValues = z.output<typeof schema>;

type Props = {
    host: ListHost;
    dialogProps: ControlledDialogProps;
};

const SelectLicenseDialog = ({ host, dialogProps }: Props): ReactNode => {
    const { id: clientId } = useActiveClient();
    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema),
        defaultValues: {
            choice: "byol",
        },
    });
    const selectLicenseMutation = useSelectFmsLicenseMutation(clientId);
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = (values: TransformedValues) => {
        selectLicenseMutation.mutate(
            {
                hostId: host.id,
                ...values,
            },
            {
                onSuccess: (message) => {
                    enqueueSnackbar(message, { variant: "success" });
                    dialogProps.onClose();
                },
                onError: (error) => {
                    enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                },
            },
        );
    };

    const choice = form.watch("choice");

    return (
        <Dialog
            {...dialogProps}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                component: "form",
                onSubmit: form.handleSubmit(handleSubmit),
                noValidate: true,
            }}
        >
            <DialogTitle>Select FileMaker Server License</DialogTitle>
            <DialogContent dividers>
                <SmoothTransitionBox>
                    <RhfRadioGroup
                        control={form.control}
                        name="choice"
                        label="Choice"
                        options={[
                            { value: "byol", label: "Bring your own license" },
                            { value: "buy", label: "Buy license from Soliant" },
                        ]}
                    />

                    <Divider sx={{ mt: 1, mb: 2 }} />

                    {choice === "byol" ? (
                        <Stack spacing={2}>
                            <RhfTextField
                                control={form.control}
                                name="organization"
                                label="Organization"
                                fullWidth
                                required
                            />
                            <FileUploadField
                                control={form.control}
                                name="certificate"
                                label="Certificate file"
                                createSignedPostPath={`/clients/${clientId}/hosts/${host.id}/licenses/fms/signed-post`}
                                maxSize={1024 * 100}
                                required
                                fileOptions={{
                                    extensions: [".fmcert"],
                                }}
                            />
                        </Stack>
                    ) : (
                        <Typography>
                            Soliant's licensing department will contact you shortly to discuss
                            licensing options. This cannot be changed through the web interface
                            after submission.
                        </Typography>
                    )}
                </SmoothTransitionBox>
            </DialogContent>
            <DialogActions>
                <Button color="inherit" onClick={dialogProps.onClose}>
                    Cancel
                </Button>
                <LoadingButton type="submit" loading={selectLicenseMutation.isPending}>
                    Select
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default SelectLicenseDialog;
