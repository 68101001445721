import type { FileUpload } from "@/components/FileUploadField";
import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import { acknowledgmentSchema, apiUrl } from "@/utils/api.ts";
import { type UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { handleJsonApiError } from "jsonapi-zod-query";

type AddFileValues = {
    hostId: string;
    description: string;
    file: FileUpload;
};

export const useAddSettingsFileMutation = (
    clientId: string,
): UseMutationResult<void, Error, AddFileValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ hostId, ...values }) => {
            const response = await fetch(
                apiUrl(`/clients/${clientId}/hosts/${hostId}/disaster-recovery/settings-files`),
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                    },
                    body: JSON.stringify({
                        data: {
                            type: "disaster_recovery_settings_file",
                            attributes: values,
                        },
                    }),
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: async (_data, { hostId }) => {
            await queryClient.invalidateQueries({
                queryKey: [
                    "client",
                    clientId,
                    "host",
                    hostId,
                    "disaster-recovery",
                    "settings-files",
                ],
            });
        },
    });
};

type LoadRestorePointValues = {
    hostId: string;
    restorePointId: string;
    openDatabases: boolean;
};

export const useLoadRestorePointMutation = (
    clientId: string,
): UseMutationResult<string, Error, LoadRestorePointValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ hostId, restorePointId, ...values }) => {
            const response = await fetch(
                apiUrl(
                    `/clients/${clientId}/hosts/${hostId}/disaster-recovery/restore-points/${restorePointId}/load`,
                ),
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                    },
                    body: JSON.stringify({
                        data: {
                            type: "disaster_recovery_restore_point_load",
                            attributes: values,
                        },
                    }),
                },
            );
            await handleJsonApiError(response);
            return acknowledgmentSchema.parse(await response.json()).meta.message;
        },
        onSuccess: async (_data, { hostId }) => {
            await queryClient.invalidateQueries({
                queryKey: [
                    "client",
                    clientId,
                    "host",
                    hostId,
                    "disaster-recovery",
                    "restore-points",
                ],
            });
        },
    });
};
