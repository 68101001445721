import type { AuthenticatedFetch } from "@/hooks/useAuthenticatedFetch.ts";
import { createClientQueryOptionsFactory } from "@/queries/client.ts";
import { createDashboardQueryOptionsFactory } from "@/queries/dashboard.ts";
import { createDisasterRecoveryQueryOptionsFactory } from "@/queries/disaster-recovery.ts";
import { createDynamicDocketQueryOptionsFactory } from "@/queries/dynamic-docket.ts";
import { createFileLockerQueryOptionsFactory } from "@/queries/file-locker.ts";
import { createFileMakerServerQueryOptionsFactory } from "@/queries/filemaker-server.ts";
import { createHostEventQueryOptionsFactory } from "@/queries/host-event.ts";
import { createHostVolumeQueryOptionsFactory } from "@/queries/host-volume.ts";
import { createHostQueryOptionsFactory } from "@/queries/host.ts";
import { createInvoiceQueryOptionsFactory } from "@/queries/invoice.ts";
import { createPrivateBackupQueryOptionsFactory } from "@/queries/private-backup.ts";
import { createSideCarQueryOptionsFactory } from "@/queries/sidecar.ts";
import { createSignedGetUrlQueryOptionsFactory } from "@/queries/signed-get-url.ts";
import { createSnapBackQueryOptionsFactory } from "@/queries/snapback.ts";
import { createStripeQueryOptionsFactory } from "@/queries/stripe.ts";
import { createSupportResourceQueryOptionsFactory } from "@/queries/support-resource.ts";
import { createWebdirectAutoScalingQueryOptionsFactory } from "@/queries/webdirect-auto-scaling.ts";
import { type ReactNode, createContext, useContext } from "react";

export const createQueryOptionsFactory = (authFetch: AuthenticatedFetch) => ({
    client: createClientQueryOptionsFactory(authFetch),
    dashboard: createDashboardQueryOptionsFactory(authFetch),
    disasterRecovery: createDisasterRecoveryQueryOptionsFactory(authFetch),
    dynamicDocket: createDynamicDocketQueryOptionsFactory(authFetch),
    fileLocker: createFileLockerQueryOptionsFactory(authFetch),
    fileMakerServer: createFileMakerServerQueryOptionsFactory(authFetch),
    host: createHostQueryOptionsFactory(authFetch),
    hostEvent: createHostEventQueryOptionsFactory(authFetch),
    hostVolume: createHostVolumeQueryOptionsFactory(authFetch),
    invoice: createInvoiceQueryOptionsFactory(authFetch),
    privateBackup: createPrivateBackupQueryOptionsFactory(authFetch),
    sideCar: createSideCarQueryOptionsFactory(authFetch),
    signedGetUrl: createSignedGetUrlQueryOptionsFactory(authFetch),
    snapBack: createSnapBackQueryOptionsFactory(authFetch),
    stripe: createStripeQueryOptionsFactory(authFetch),
    supportResource: createSupportResourceQueryOptionsFactory(authFetch),
    webdirectAutoScaling: createWebdirectAutoScalingQueryOptionsFactory(authFetch),
});

export type QueryOptionsFactory = ReturnType<typeof createQueryOptionsFactory>;

const QueryOptionsFactoryContext = createContext<QueryOptionsFactory | null>(null);

type QueryOptionsFactoryProviderProps = {
    children: ReactNode;
    factory: QueryOptionsFactory;
};

export const QueryOptionsFactoryProvider = ({
    children,
    factory,
}: QueryOptionsFactoryProviderProps): ReactNode => (
    <QueryOptionsFactoryContext.Provider value={factory}>
        {children}
    </QueryOptionsFactoryContext.Provider>
);

export const useQueryOptionsFactory = (): QueryOptionsFactory => {
    const factory = useContext(QueryOptionsFactoryContext);

    if (!factory) {
        throw new Error("useQueryOptionsFactory used outside QueryOptionsFactoryProvider");
    }

    return factory;
};
