import SupportResourceDialog from "@/components/SupportResource/SupportResourceDialog.tsx";
import useDialogController from "@/hooks/useDialogController.tsx";
import type { ReactNode } from "react";
import { createPortal } from "react-dom";

type RenderButton = (onClick: () => void) => ReactNode;

type Props = {
    type: string;
    hostId?: string;
    renderButton: RenderButton;
};

const SupportResource = ({ type, hostId, renderButton }: Props): ReactNode => {
    const dialogController = useDialogController();

    return (
        <>
            {renderButton(dialogController.open)}

            {dialogController.mount &&
                createPortal(
                    <SupportResourceDialog
                        dialogProps={dialogController.dialogProps}
                        type={type}
                        hostId={hostId}
                    />,
                    document.body,
                )}
        </>
    );
};

export default SupportResource;
