import AutoFillGrid from "@/components/AutoFillGrid";
import { BodyContainer } from "@/components/Layout";
import { InlineSpinner } from "@/components/Spinner";
import { useQueryOptionsFactory } from "@/queries";
import ProductCard from "@/routes/$clientId/sidecar/-components/ProductCard.tsx";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import {
    Autocomplete,
    Box,
    Button,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";
import { Link, createFileRoute } from "@tanstack/react-router";
import { type ReactNode, useMemo, useState } from "react";

const Root = (): ReactNode => {
    const { clientId } = Route.useParams();
    const qof = useQueryOptionsFactory();
    const { data: products } = useSuspenseQuery(qof.sideCar.listProducts(clientId));
    const { data: environments } = useSuspenseQuery(qof.sideCar.listEnvironments(clientId));
    const [filter, setFilter] = useState("");
    const [tag, setTag] = useState<string | null>(null);

    const tags = useMemo(
        () =>
            Array.from(
                products.reduce((tags, product) => {
                    for (const tag of product.tags) {
                        tags.add(tag);
                    }

                    return tags;
                }, new Set<string>()),
            ).sort(),
        [products],
    );

    const filteredProducts = useMemo(
        () =>
            products.filter((product) => {
                if (tag && !product.tags.includes(tag)) {
                    return false;
                }

                if (!filter) {
                    return true;
                }

                const normalizedFilter = filter.toLocaleLowerCase();
                const name = product.name.toLocaleLowerCase();

                return name.includes(normalizedFilter);
            }),
        [filter, tag, products],
    );

    return (
        <BodyContainer
            header={
                <Box
                    sx={{
                        display: "flex",
                    }}
                >
                    <Typography variant="h5" sx={{ mr: "auto" }}>
                        Add SideCar
                    </Typography>
                    <Button
                        component={Link}
                        size="small"
                        startIcon={<ArrowBackIcon />}
                        to="/$clientId/sidecar"
                    >
                        Cancel
                    </Button>
                </Box>
            }
        >
            <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
                <TextField
                    label="Search"
                    size="small"
                    value={filter}
                    onChange={(event) => {
                        setFilter(event.target.value);
                    }}
                    slotProps={{
                        input: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            endAdornment: filter ? (
                                <InputAdornment position="end">
                                    <IconButton
                                        size="small"
                                        edge="end"
                                        onClick={() => {
                                            setFilter("");
                                        }}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            ) : undefined,
                        },
                    }}
                    sx={{ mb: 2, width: "100%", maxWidth: 400 }}
                />

                <Autocomplete
                    renderInput={(params) => <TextField {...params} size="small" label="Tag" />}
                    options={tags}
                    autoHighlight
                    value={tag}
                    onChange={(_event, value: string | null) => {
                        setTag(value);
                    }}
                    sx={{ width: 250, ml: "auto" }}
                />
            </Stack>

            {filteredProducts.length === 0 ? (
                <Typography>No SideCars match your search.</Typography>
            ) : (
                <AutoFillGrid minWidth={500}>
                    {filteredProducts.map((product) => (
                        <ProductCard
                            key={product.id}
                            product={product}
                            environments={environments}
                        />
                    ))}
                </AutoFillGrid>
            )}
        </BodyContainer>
    );
};

export const Route = createFileRoute("/$clientId/sidecar/create")({
    component: Root,
    pendingComponent: () => (
        <BodyContainer>
            <InlineSpinner />
        </BodyContainer>
    ),
    loader: async ({ context, params }) => {
        await Promise.all([
            context.queryClient.ensureQueryData(context.qof.sideCar.listProducts(params.clientId)),
            context.queryClient.ensureQueryData(
                context.qof.sideCar.listEnvironments(params.clientId),
            ),
        ]);
    },
});
