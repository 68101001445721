import logoColor from "@/assets/logo-color.svg";
import logoWhite from "@/assets/logo-white.svg";
import SupportResource from "@/components/SupportResource";
import CloudIcon from "@mui/icons-material/Cloud";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FeedbackIcon from "@mui/icons-material/Feedback";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import PaymentIcon from "@mui/icons-material/Payment";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import StorageIcon from "@mui/icons-material/Storage";
import {
    Box,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    useTheme,
} from "@mui/material";
import { Link } from "@tanstack/react-router";
import type { ReactNode } from "react";

type NavigationItem = {
    href: string;
    label: string;
    icon: ReactNode;
};

const navigationItems: NavigationItem[] = [
    {
        label: "Dashboard",
        href: "/",
        icon: <DashboardIcon />,
    },
    {
        label: "Hosts",
        href: "/hosts",
        icon: <StorageIcon />,
    },
    {
        label: "File Locker",
        href: "/file-locker",
        icon: <SaveAltIcon />,
    },
    {
        label: "Billing",
        href: "/billing",
        icon: <PaymentIcon />,
    },
    {
        label: "SideCar",
        href: "/sidecar",
        icon: <CloudIcon />,
    },
];

const DrawerContent = (): ReactNode => {
    const {
        palette: { mode },
    } = useTheme();

    return (
        <>
            <Box
                component="img"
                src={mode === "dark" ? logoWhite : logoColor}
                alt="Soliant.cloud"
                sx={{
                    height: 60,
                    my: 2,
                }}
            />
            <Divider variant="middle" />
            <List>
                {navigationItems.map((item) => (
                    <ListItemButton key={item.href} component={Link} to={`/$clientId${item.href}`}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.label} />
                    </ListItemButton>
                ))}

                <Divider />

                <SupportResource
                    type="service_desk_ticket"
                    renderButton={(open) => (
                        <ListItemButton onClick={open}>
                            <ListItemIcon>
                                <HelpCenterIcon />
                            </ListItemIcon>
                            <ListItemText primary="Service Desk" />
                        </ListItemButton>
                    )}
                />

                <SupportResource
                    type="customer_review_aws"
                    renderButton={(open) => (
                        <ListItemButton onClick={open}>
                            <ListItemIcon>
                                <FeedbackIcon />
                            </ListItemIcon>
                            <ListItemText primary="Provide Feedback" />
                        </ListItemButton>
                    )}
                />
            </List>
        </>
    );
};

export default DrawerContent;
