import AutoFillGrid from "@/components/AutoFillGrid";
import type { Client } from "@/queries/client.ts";
import { Card, CardHeader, Stack, Typography } from "@mui/material";
import { type TCountryCode, getCountryData } from "countries-list";
import { formatAddress } from "localized-address-format";
import type { ReactNode } from "react";
import PersonCard from "./PersonCard.tsx";

type Props = {
    client: Client;
};

const ClientDetails = ({ client }: Props): ReactNode => (
    <Stack spacing={2}>
        <Typography variant="h6">{client.name}</Typography>
        <Typography sx={{ whiteSpace: "pre-line" }}>
            {formatAddress({
                locality: client.address.city,
                administrativeArea: client.address.region,
                addressLines: [client.address.street],
                postalCode: client.address.postalCode,
                postalCountry: client.address.countryCode,
            }).join("\n")}
            {"\n"}
            {getCountryData(client.address.countryCode as TCountryCode).name}
        </Typography>

        <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            {client.contactPerson && <PersonCard title="Contact" person={client.contactPerson} />}
            {client.billingPerson && <PersonCard title="Billing" person={client.billingPerson} />}
        </Stack>

        {client.accounts.length > 0 && (
            <>
                <Typography variant="h6">Accounts</Typography>

                <AutoFillGrid minWidth={300}>
                    {client.accounts.map((account) => (
                        <Card key={account.id}>
                            <CardHeader
                                title={account.name}
                                subheader={account.id}
                                titleTypographyProps={{ variant: "body1" }}
                                subheaderTypographyProps={{ variant: "body2" }}
                            />
                        </Card>
                    ))}
                </AutoFillGrid>
            </>
        )}
    </Stack>
);

export default ClientDetails;
