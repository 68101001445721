import type { DialogController } from "@/hooks/useDialogController.tsx";
import type { Client } from "@/queries/client.ts";
import { type User, useAuth0 } from "@auth0/auth0-react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Divider, ListItem, ListItemText, Menu, MenuItem } from "@mui/material";
import { type PopupState, bindMenu } from "material-ui-popup-state/hooks";
import type { ReactNode } from "react";
import ModeSwitch from "./ModeSwitch.tsx";

type Props = {
    state: PopupState;
    user: User;
    client: Client;
    clientSwitcherDialogController?: DialogController | null;
};

const UserMenu = ({ state, clientSwitcherDialogController, user, client }: Props): ReactNode => {
    const { logout } = useAuth0();

    return (
        <Menu
            {...bindMenu(state)}
            slotProps={{
                paper: { sx: { width: 400 } },
            }}
        >
            <ListItem>
                <ListItemText
                    primaryTypographyProps={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                    }}
                    secondaryTypographyProps={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                    }}
                    primary={user.email}
                    secondary={client.name}
                />
            </ListItem>
            <Divider key="divider" sx={{ mb: 1 }} />
            <ListItem>
                <ModeSwitch />
            </ListItem>
            {clientSwitcherDialogController && [
                <Divider key="divider" sx={{ my: 1 }} />,
                <MenuItem
                    key="switch-client"
                    onClick={() => {
                        state.close();
                        clientSwitcherDialogController.open();
                    }}
                >
                    <ListItemText>Switch client</ListItemText>
                    <KeyboardArrowRightIcon />
                </MenuItem>,
            ]}
            <Divider sx={{ my: 1 }} />
            <MenuItem
                onClick={() => {
                    state.close();
                    void logout();
                }}
            >
                Log out
            </MenuItem>
        </Menu>
    );
};

export default UserMenu;
