import { dayJsToLocalTime } from "@/utils/datetime.ts";
import { type Dayjs, isDayjs } from "dayjs";
import { z } from "zod";

export const timeSchema = z.custom<Dayjs>(isDayjs).transform(dayJsToLocalTime);

export const semverSchema = z
    .string()
    .regex(
        /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/,
        "Invalid version number",
    );
