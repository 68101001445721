import type { ControlledDialogProps } from "@/hooks/useDialogController.tsx";
import { useCreateSideCarDeploymentMutation } from "@/mutations/sidecar.ts";
import type { SideCarEnvironment, SideCarProduct } from "@/queries/sidecar.ts";
import { useActiveClient } from "@/routes/$clientId.tsx";
import ConfigForm from "@/routes/$clientId/sidecar/-components/ConfigForm";
import type { ConfigFormValues } from "@/routes/$clientId/sidecar/-components/ConfigForm/ConfigForm.tsx";
import { getErrorMessage } from "@/utils/api.ts";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useNavigate } from "@tanstack/react-router";
import { useSnackbar } from "notistack";
import { type ReactNode, useCallback } from "react";

type Props = {
    dialogProps: ControlledDialogProps;
    product: SideCarProduct;
    environments: SideCarEnvironment[];
};

const AddDeploymentDialog = ({ dialogProps, product, environments }: Props): ReactNode => {
    const { id: clientId } = useActiveClient();
    const createMutation = useCreateSideCarDeploymentMutation(clientId);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const handleSubmit = useCallback(
        (values: ConfigFormValues<true>) => {
            createMutation.mutate(
                {
                    productId: product.id,
                    accountId: values.environment.account.id,
                    regionId: values.environment.region.id,
                    title: values.title,
                    config: values.config,
                },
                {
                    onSuccess: () => {
                        enqueueSnackbar("SideCar deployment started", { variant: "success" });
                        dialogProps.onClose();
                        void navigate({ to: "/$clientId/sidecar", params: { clientId } });
                    },
                    onError: (error) => {
                        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                    },
                },
            );
        },
        [product.id, createMutation, enqueueSnackbar, dialogProps.onClose, navigate, clientId],
    );

    return (
        <Dialog {...dialogProps} maxWidth="sm" fullWidth>
            <DialogTitle>Add {product.name} SideCar</DialogTitle>
            <ConfigForm
                manifest={product.manifest}
                onSubmit={handleSubmit}
                environments={environments}
                wrapper={(children) => (
                    <>
                        <DialogContent dividers>{children}</DialogContent>
                        <DialogActions>
                            <Button color="inherit" onClick={dialogProps.onClose}>
                                Cancel
                            </Button>
                            <LoadingButton type="submit" loading={createMutation.isPending}>
                                Deploy
                            </LoadingButton>
                        </DialogActions>
                    </>
                )}
            />
        </Dialog>
    );
};

export default AddDeploymentDialog;
