import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import { acknowledgmentSchema, apiUrl } from "@/utils/api.ts";
import { type UseMutationResult, useMutation } from "@tanstack/react-query";
import { handleJsonApiError } from "jsonapi-zod-query";

type SupportRequestValues = {
    type: string;
    hostId?: string;
    details: string;
};

export const useSubmitSupportRequestMutation = (
    clientId: string,
): UseMutationResult<string, Error, SupportRequestValues> => {
    const fetch = useAuthenticatedFetch();

    return useMutation({
        mutationFn: async ({ hostId, type, ...values }) => {
            const url = hostId
                ? apiUrl(`/clients/${clientId}/hosts/${hostId}/support-resources/${type}/requests`)
                : apiUrl(`/clients/${clientId}/support-resources/${type}/requests`);

            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
                body: JSON.stringify({
                    data: {
                        type: "support_request",
                        attributes: values,
                    },
                }),
            });
            await handleJsonApiError(response);
            return acknowledgmentSchema.parse(await response.json()).meta.message;
        },
    });
};
