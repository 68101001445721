import type { SnapBack } from "@/queries/snapback.ts";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Chip, type ChipProps } from "@mui/material";
import type { ReactNode } from "react";

type Props = {
    status: SnapBack["status"];
    size?: ChipProps["size"];
};

const StatusChip = ({ status, size }: Props): ReactNode => {
    switch (status) {
        case "online":
            return (
                <Chip label="Online" icon={<PlayCircleOutlineIcon />} color="info" size={size} />
            );

        case "pending":
            return <Chip label="Pending" icon={<PendingOutlinedIcon />} color="info" size={size} />;

        case "available":
            return (
                <Chip
                    label="Available"
                    icon={<CheckCircleOutlineIcon />}
                    color="success"
                    size={size}
                />
            );

        case "unavailable":
            return (
                <Chip
                    label="Unavailable"
                    icon={<CancelOutlinedIcon />}
                    color="warning"
                    size={size}
                />
            );

        case "error":
            return <Chip label="Error" icon={<ErrorOutlineIcon />} color="error" size={size} />;
    }
};

export default StatusChip;
