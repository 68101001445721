import type { AuthenticatedFetch } from "@/hooks/useAuthenticatedFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { queryOptions } from "@tanstack/react-query";
import {
    createDataSelector,
    createResourceCollectionSelector,
    handleJsonApiError,
} from "jsonapi-zod-query";
import { z } from "zod";

const attributesSchema = z.object({
    modifiable: z.boolean(),
    usage: z.string().min(1),
    size: z.object({
        allocated: z.number().int().nonnegative(),
        used: z.number().int().nonnegative(),
        min: z.number().int().nonnegative(),
        max: z.number().int().nonnegative(),
    }),
});

const hostVolumesSelector = createDataSelector(
    createResourceCollectionSelector({
        type: "host_volume",
        attributesSchema,
    }),
);

export type HostVolume = ReturnType<typeof hostVolumesSelector>[number];

export const createHostVolumeQueryOptionsFactory = (authFetch: AuthenticatedFetch) => ({
    list: (clientId: string, hostId: string) =>
        queryOptions({
            queryKey: ["client", clientId, "host", hostId, "volumes"],
            queryFn: async ({ signal }) => {
                const url = apiUrl(`/clients/${clientId}/hosts/${hostId}/volumes`);
                const response = await authFetch(url, { signal });
                await handleJsonApiError(response);
                return hostVolumesSelector(await response.json());
            },
        }),
});
