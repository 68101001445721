import CardField from "@/components/CardField";
import type { PrivateBackupConfiguration } from "@/queries/private-backup.ts";
import InfoIcon from "@mui/icons-material/Info";
import { Stack, type SxProps, Tooltip } from "@mui/material";
import type { ReactNode } from "react";
import { match } from "ts-pattern";

type Props = {
    occurrence: PrivateBackupConfiguration["occurrence"];
    description: PrivateBackupConfiguration["occurrenceDescription"];
    sx?: SxProps;
};

const OccurrenceCardField = ({ occurrence, description, sx }: Props): ReactNode => {
    const value = match(occurrence)
        .with("daily", () => "Daily")
        .with("weekly", () => "Weekly")
        .exhaustive();

    return (
        <CardField
            label="Occurrence"
            value={
                <Tooltip title={description}>
                    <Stack
                        direction="row"
                        sx={{
                            columnGap: 1,
                            alignItems: "center",
                        }}
                    >
                        {value}
                        <InfoIcon fontSize="inherit" />
                    </Stack>
                </Tooltip>
            }
            sx={sx}
        />
    );
};
export default OccurrenceCardField;
