import { useTimeZoneContext } from "@/components/TimeZoneProvider";
import type { DynamicDocketEvent } from "@/queries/dynamic-docket.ts";
import { zonedDateTimeFormatter } from "@/utils/format.ts";
import { ListItem, ListItemText, Stack, useMediaQuery, useTheme } from "@mui/material";
import type { ReactNode } from "react";
import ActionChip from "./ActionChip.tsx";

type Props = {
    event: DynamicDocketEvent;
};

const EventListItem = ({ event }: Props): ReactNode => {
    const { zoneId } = useTimeZoneContext();
    const theme = useTheme();
    const compactView = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <ListItem>
            <ListItemText
                primary={event.description}
                secondary={
                    <Stack
                        sx={{
                            rowGap: 0.5,
                        }}
                    >
                        {event.runsAt.withZoneSameInstant(zoneId).format(zonedDateTimeFormatter)}

                        {compactView && (
                            <div>
                                <ActionChip action={event.action} size="small" />
                            </div>
                        )}
                    </Stack>
                }
                primaryTypographyProps={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                }}
                secondaryTypographyProps={{ component: "div" }}
                sx={{
                    flexGrow: 1,
                    mr: !compactView ? 2 : undefined,
                }}
            />
            {!compactView && (
                <ListItemText
                    secondary={<ActionChip action={event.action} size="small" />}
                    secondaryTypographyProps={{ component: "div" }}
                    sx={{ flexGrow: 0 }}
                />
            )}
        </ListItem>
    );
};

export default EventListItem;
