import { useUpdateSideCarDeploymentMutation } from "@/mutations/sidecar.ts";
import type { SideCarDeployment } from "@/queries/sidecar.ts";
import { useActiveClient } from "@/routes/$clientId.tsx";
import type { ConfigFormValues } from "@/routes/$clientId/sidecar/-components/ConfigForm/ConfigForm.tsx";
import { getErrorMessage } from "@/utils/api.ts";
import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { useSnackbar } from "notistack";
import { type ReactNode, useCallback } from "react";
import ConfigForm from "../ConfigForm/index.ts";

type Props = {
    deployment: SideCarDeployment;
    onClose: () => void;
};

const ConfigTab = ({ deployment, onClose }: Props): ReactNode => {
    const { id: clientId } = useActiveClient();
    const updateMutation = useUpdateSideCarDeploymentMutation(clientId);
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = useCallback(
        (values: ConfigFormValues<false>) => {
            updateMutation.mutate(
                {
                    deploymentId: deployment.id,
                    title: values.title,
                    config: values.config,
                },
                {
                    onSuccess: () => {
                        enqueueSnackbar("SideCar configuration has been updated", {
                            variant: "success",
                        });
                        onClose();
                    },
                    onError: (error) => {
                        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                    },
                },
            );
        },
        [deployment.id, updateMutation, enqueueSnackbar, onClose],
    );

    return (
        <ConfigForm
            manifest={deployment.product.manifest}
            onSubmit={handleSubmit}
            defaultValues={{
                title: deployment.title,
                config: deployment.config,
            }}
            outputs={deployment.metadata?.outputs}
            disabled={!deployment.modifiable}
            wrapper={(children) => (
                <>
                    <DialogContent dividers>{children}</DialogContent>
                    <DialogActions>
                        <Button color="inherit" onClick={onClose}>
                            Cancel
                        </Button>
                        <LoadingButton
                            type="submit"
                            loading={updateMutation.isPending}
                            disabled={!deployment.modifiable}
                        >
                            Update
                        </LoadingButton>
                    </DialogActions>
                </>
            )}
        />
    );
};

export default ConfigTab;
