import ClientListItem from "@/components/Layout/ClientListItem.tsx";
import type { ControlledDialogProps } from "@/hooks/useDialogController.tsx";
import type { ListClient } from "@/queries/client.ts";
import { Box, Dialog, DialogTitle, Divider, List } from "@mui/material";
import { useNavigate } from "@tanstack/react-router";
import type { ReactNode } from "react";
import SelfAssign from "./SelfAssign.tsx";

type Props = {
    dialogProps: ControlledDialogProps;
    clients: ListClient[];
    selfAssignAllowed: boolean;
};

const ClientSwitcherDialog = ({ dialogProps, clients, selfAssignAllowed }: Props): ReactNode => {
    const navigate = useNavigate();

    return (
        <Dialog {...dialogProps} maxWidth="xs" fullWidth>
            <DialogTitle>Switch client</DialogTitle>
            <Divider />
            <Box
                sx={{
                    py: 1,
                }}
            >
                <List dense disablePadding>
                    {clients.map((client) => (
                        <ClientListItem
                            key={client.id}
                            client={client}
                            onSelect={() => {
                                void navigate({
                                    to: "/$clientId",
                                    params: { clientId: client.id },
                                });
                                dialogProps.onClose();
                            }}
                            selfAssignAllowed={selfAssignAllowed}
                        />
                    ))}
                </List>
            </Box>
            {selfAssignAllowed && (
                <>
                    <Divider />
                    <Box
                        sx={{
                            py: 1,
                            px: 2,
                        }}
                    >
                        <SelfAssign />
                    </Box>
                </>
            )}
        </Dialog>
    );
};
export default ClientSwitcherDialog;
