import type { AuthenticatedFetch } from "@/hooks/useAuthenticatedFetch.ts";
import { awsAccountRelationship, awsRegionRelationship } from "@/queries/common.ts";
import { sideCarManifestSchema } from "@/sidecar/manifest.ts";
import { apiUrl } from "@/utils/api.ts";
import { semverSchema } from "@/utils/zod.ts";
import { queryOptions } from "@tanstack/react-query";
import {
    type Relationships,
    createDataSelector,
    createResourceCollectionSelector,
    handleJsonApiError,
} from "jsonapi-zod-query";
import { z } from "zod";

const productAttributesSchema = z.object({
    sideCarId: z.string().min(1),
    majorVersion: z.number().int().nonnegative(),
    name: z.string().min(1),
    description: z.string().min(1),
    tags: z.array(z.string().min(1)),
    documentationUrl: z.string().url().nullable(),
    quota: z.object({
        limit: z.number().int().nonnegative(),
        used: z.number().int().nonnegative(),
    }),
});

const productRelationships = {
    manifest: {
        relationshipType: "one",
        include: {
            type: "sidecar_manifest",
            attributesSchema: sideCarManifestSchema,
        },
    },
} satisfies Relationships;

const productsSelector = createDataSelector(
    createResourceCollectionSelector({
        type: "sidecar_product",
        attributesSchema: productAttributesSchema,
        relationships: productRelationships,
    }),
);

export type SideCarProduct = ReturnType<typeof productsSelector>[number];

const environmentRelationships = {
    account: awsAccountRelationship,
    regions: {
        relationshipType: "many",
        include: {
            type: "aws_region",
            attributesSchema: z.object({
                name: z.string().min(1),
            }),
        },
    },
} satisfies Relationships;

const environmentsSelector = createDataSelector(
    createResourceCollectionSelector({
        type: "sidecar_environment",
        relationships: environmentRelationships,
    }),
);

export type SideCarEnvironment = ReturnType<typeof environmentsSelector>[number];

const deploymentAttributesSchema = z.object({
    title: z.string().min(1),
    modifiable: z.boolean(),
    deletable: z.boolean(),
    state: z.enum(["deploying", "deployed", "removing", "failed"]),
    config: z.record(z.unknown()),
    metadata: z
        .object({
            version: semverSchema,
            outputs: z.record(z.unknown()),
        })
        .nullable(),
});

const deploymentRelationships = {
    product: {
        relationshipType: "one",
        include: {
            type: "sidecar_product",
            attributesSchema: productAttributesSchema,
            relationships: productRelationships,
        },
    },
    account: awsAccountRelationship,
    region: awsRegionRelationship,
} satisfies Relationships;

const deploymentsSelector = createDataSelector(
    createResourceCollectionSelector({
        type: "sidecar_deployment",
        attributesSchema: deploymentAttributesSchema,
        relationships: deploymentRelationships,
    }),
);

export type SideCarDeployment = ReturnType<typeof deploymentsSelector>[number];

export const createSideCarQueryOptionsFactory = (authFetch: AuthenticatedFetch) => ({
    listProducts: (clientId: string) =>
        queryOptions({
            queryKey: ["client", clientId, "sidecar", "products"],
            queryFn: async ({ signal }) => {
                const url = apiUrl(`/clients/${clientId}/sidecar/products`);
                const response = await authFetch(url, { signal });
                await handleJsonApiError(response);
                return productsSelector(await response.json());
            },
        }),
    listEnvironments: (clientId: string) =>
        queryOptions({
            queryKey: ["client", clientId, "sidecar", "environments"],
            queryFn: async ({ signal }) => {
                const url = apiUrl(`/clients/${clientId}/sidecar/environments`);
                const response = await authFetch(url, { signal });
                await handleJsonApiError(response);
                return environmentsSelector(await response.json());
            },
        }),
    listDeployments: (clientId: string) =>
        queryOptions({
            queryKey: ["client", clientId, "sidecar", "deployments"],
            queryFn: async ({ signal }) => {
                const url = apiUrl(`/clients/${clientId}/sidecar/deployments`);
                const response = await authFetch(url, { signal });
                await handleJsonApiError(response);
                return deploymentsSelector(await response.json());
            },
        }),
});
