import type { ControlledDialogProps } from "@/hooks/useDialogController.tsx";
import { useUpdateConfigurationMutation } from "@/mutations/dynamic-docket.ts";
import type {
    DynamicDocketConfiguration,
    ScheduleDay,
    ScheduleSlot,
} from "@/queries/dynamic-docket.ts";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { useActiveHost } from "@/routes/$clientId/hosts/$hostId.tsx";
import { getErrorMessage } from "@/utils/api.ts";
import { dayJsToLocalTime, localTimeToDayJs } from "@/utils/datetime.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Stack,
    Typography,
} from "@mui/material";
import { type Dayjs, isDayjs } from "dayjs";
import { RhfTextField } from "mui-rhf-integration";
import { RhfTimePicker } from "mui-rhf-integration/date-picker";
import { useSnackbar } from "notistack";
import { type ReactNode, useCallback } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const slotSchema = z.object({
    time: z
        .custom<Dayjs>(isDayjs)
        .transform(dayJsToLocalTime)
        .transform((value) => value.withMinute(0).withSecond(0))
        .nullable(),
    action: z.enum(["start", "stop", "none"]),
});

const daySchema = z.object({
    begin: slotSchema.transform((value) =>
        !value.time || value.action === "none" ? null : (value as unknown as ScheduleSlot),
    ),
    end: slotSchema.transform((value) =>
        !value.time || value.action === "none" ? null : (value as unknown as ScheduleSlot),
    ),
});

const schema = z.object({
    monday: daySchema,
    tuesday: daySchema,
    wednesday: daySchema,
    thursday: daySchema,
    friday: daySchema,
    saturday: daySchema,
    sunday: daySchema,
});

type FieldValues = z.input<typeof schema>;
type TransformedValues = z.output<typeof schema>;

const actionOptions = [
    { value: "none", label: "None" },
    { value: "start", label: "Start" },
    { value: "stop", label: "Stop" },
];

const mapScheduleDayToFormValues = (scheduleDay: ScheduleDay): z.input<typeof daySchema> => {
    return {
        begin:
            scheduleDay.begin === null
                ? {
                      time: null,
                      action: "none",
                  }
                : {
                      time: localTimeToDayJs(scheduleDay.begin.time),
                      action: scheduleDay.begin.action,
                  },
        end:
            scheduleDay.end === null
                ? {
                      time: null,
                      action: "none",
                  }
                : {
                      time: localTimeToDayJs(scheduleDay.end.time),
                      action: scheduleDay.end.action,
                  },
    };
};

const days = [
    { key: "monday", label: "Monday" },
    { key: "tuesday", label: "Tuesday" },
    { key: "wednesday", label: "Wednesday" },
    { key: "thursday", label: "Thursday" },
    { key: "friday", label: "Friday" },
    { key: "saturday", label: "Saturday" },
    { key: "sunday", label: "Sunday" },
] as const;

const slots = [
    { key: "begin", label: "Begin" },
    { key: "end", label: "End" },
] as const;

type Props = {
    dialogProps: ControlledDialogProps;
    configuration: DynamicDocketConfiguration;
};

const EditScheduleDialog = ({ dialogProps, configuration }: Props): ReactNode => {
    const { id: clientId } = useActiveClient();
    const { id: hostId } = useActiveHost();
    const updateMutation = useUpdateConfigurationMutation(clientId);
    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema),
        defaultValues: {
            monday: mapScheduleDayToFormValues(configuration.weeklySchedule.monday),
            tuesday: mapScheduleDayToFormValues(configuration.weeklySchedule.tuesday),
            wednesday: mapScheduleDayToFormValues(configuration.weeklySchedule.wednesday),
            thursday: mapScheduleDayToFormValues(configuration.weeklySchedule.thursday),
            friday: mapScheduleDayToFormValues(configuration.weeklySchedule.friday),
            saturday: mapScheduleDayToFormValues(configuration.weeklySchedule.saturday),
            sunday: mapScheduleDayToFormValues(configuration.weeklySchedule.sunday),
        },
    });
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = useCallback(
        (values: TransformedValues) => {
            updateMutation.mutate(
                {
                    hostId,
                    weeklySchedule: values,
                },
                {
                    onSuccess: (message) => {
                        enqueueSnackbar(message, { variant: "success" });
                        dialogProps.onClose();
                    },
                    onError: (error) => {
                        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                    },
                },
            );
        },
        [enqueueSnackbar, hostId, updateMutation, dialogProps.onClose],
    );

    return (
        <Dialog
            {...dialogProps}
            maxWidth="md"
            fullWidth
            PaperProps={{
                component: "form",
                noValidate: true,
                onSubmit: form.handleSubmit(handleSubmit),
            }}
        >
            <DialogTitle>Edit schedule</DialogTitle>
            <DialogContent dividers>
                <Stack spacing={2}>
                    {days.map(({ key: dayKey, label }) => (
                        <div key={dayKey}>
                            <Typography sx={{ mb: 1 }}>{label}</Typography>

                            <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                                {slots.map(({ key: slotKey, label }) => (
                                    <Stack
                                        key={slotKey}
                                        direction="row"
                                        spacing={2}
                                        sx={{
                                            width: { xs: "100%", md: "50%" },
                                        }}
                                    >
                                        <RhfTimePicker
                                            control={form.control}
                                            name={`${dayKey}.${slotKey}.time`}
                                            views={["hours"]}
                                            slotProps={{
                                                actionBar: {
                                                    actions: ["clear"],
                                                },
                                            }}
                                            label={label}
                                            textFieldProps={{ size: "small" }}
                                            sx={{
                                                minWidth: 130,
                                                maxWidth: 130,
                                                width: 130,
                                            }}
                                        />
                                        <RhfTextField
                                            control={form.control}
                                            name={`${dayKey}.${slotKey}.action`}
                                            label="Action"
                                            size="small"
                                            select
                                            fullWidth
                                        >
                                            {actionOptions.map(({ value, label }) => (
                                                <MenuItem key={value} value={value}>
                                                    {label}
                                                </MenuItem>
                                            ))}
                                        </RhfTextField>
                                    </Stack>
                                ))}
                            </Stack>
                        </div>
                    ))}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color="inherit" onClick={dialogProps.onClose}>
                    Cancel
                </Button>
                <LoadingButton loading={updateMutation.isPending} type="submit">
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default EditScheduleDialog;
