import CardField from "@/components/CardField";
import type { PrivateBackupConfiguration } from "@/queries/private-backup.ts";
import { Paper, Stack } from "@mui/material";
import type { ReactNode } from "react";
import OccurrenceCardField from "./OccurrenceCardField.tsx";
import StatusCardField from "./StatusCardField.tsx";

type Props = {
    configuration: PrivateBackupConfiguration;
};

const ConfigurationCard = ({ configuration }: Props): ReactNode => {
    return (
        <Paper sx={{ p: 2 }}>
            <Stack
                direction={{ xs: "column", lg: "row" }}
                sx={{
                    columnGap: 4,
                    rowGap: 2,
                }}
            >
                <Stack
                    direction={{ xs: "column", sm: "row" }}
                    sx={{
                        columnGap: 4,
                        rowGap: 2,
                        mr: { lg: "auto" },
                    }}
                >
                    <StatusCardField enabled={configuration.enabled} />
                    <CardField
                        label="Append folder date"
                        value={configuration.appendFolderDate ? "Yes" : "No"}
                    />
                    <OccurrenceCardField
                        occurrence={configuration.occurrence}
                        description={configuration.occurrenceDescription}
                    />
                </Stack>
                <Stack
                    direction={{ xs: "column", sm: "row" }}
                    sx={{
                        columnGap: 4,
                        rowGap: 2,
                    }}
                >
                    <CardField label="Service" value={configuration.serviceName} />
                    <CardField
                        label="Remote path"
                        value={configuration.remotePath}
                        preventOverflow
                        sx={{
                            minWidth: 0,
                            maxWidth: { xs: "100%", lg: 400 },
                        }}
                    />
                </Stack>
            </Stack>
        </Paper>
    );
};
export default ConfigurationCard;
