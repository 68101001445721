import type { ListClient } from "@/queries/client.ts";
import { Card, CardContent, Container, Typography } from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";
import { Navigate, createFileRoute } from "@tanstack/react-router";
import type { ReactNode } from "react";

export const activeClientIdKey = "sccp_active_client_id";

const getInitiallyActiveClient = (clients: ListClient[]): ListClient => {
    if (clients.length === 0) {
        throw new Error("Received empty clients array");
    }

    const previouslySelectedClientId = window.localStorage.getItem(activeClientIdKey);

    if (!previouslySelectedClientId) {
        return clients[0];
    }

    return clients.find((client) => client.id === previouslySelectedClientId) ?? clients[0];
};

const Root = (): ReactNode => {
    const {
        data: { data: clients },
    } = useSuspenseQuery(Route.useRouteContext().qof.client.list());

    if (clients.length === 0) {
        return (
            <Container maxWidth="sm" sx={{ my: 2 }}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            No assigned clients found.
                        </Typography>

                        <Typography gutterBottom>
                            There are no clients assigned to your user, please contact support.
                        </Typography>
                    </CardContent>
                </Card>
            </Container>
        );
    }

    const activeClient = getInitiallyActiveClient(clients);
    return <Navigate to="/$clientId" params={{ clientId: activeClient.id }} />;
};

export const Route = createFileRoute("/")({
    component: Root,
});
