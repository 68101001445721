import useDialogController from "@/hooks/useDialogController.tsx";
import type { Client, ListClient } from "@/queries/client.ts";
import { useAuth0 } from "@auth0/auth0-react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, Drawer, IconButton, Toolbar, Typography } from "@mui/material";
import { bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import type { ReactNode } from "react";
import { useState } from "react";
import ClientSwitcherDialog from "./ClientSwitcherDialog.tsx";
import DrawerContent from "./DrawerContent.tsx";
import UserMenu from "./UserMenu.tsx";

const drawerWidth = 240;

type Props = {
    children: ReactNode;
    activeClient: Client;
    assignedClients: ListClient[];
    selfAssignAllowed: boolean;
};

const Layout = ({
    children,
    activeClient,
    assignedClients,
    selfAssignAllowed,
}: Props): ReactNode => {
    const { user } = useAuth0();
    const userMenuState = usePopupState({ variant: "popover", popupId: "user-menu" });
    const clientSwitcherDialogController = useDialogController();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const clientSwitchingEnabled = selfAssignAllowed || assignedClients.length > 1;

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    if (!user) {
        throw new Error("Layout requires user to be set");
    }

    const drawerContent = <DrawerContent />;

    return (
        <Box
            sx={{
                display: "grid",
                gridTemplate: {
                    xs: `
                        "nav header" auto
                        "nav content" 1fr
                        / 0 1fr
                    `,
                    md: `
                        "nav header" auto
                        "nav content" 1fr
                        / auto 1fr
                    `,
                },
                height: "100vh",
            }}
        >
            <AppBar position="static" sx={{ gridArea: "header", zIndex: 5 }} color="secondary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: "none" } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ display: { md: "none" } }}>
                        Soliant.cloud
                    </Typography>
                    <IconButton
                        color="inherit"
                        edge="end"
                        {...bindTrigger(userMenuState)}
                        sx={{ ml: "auto" }}
                    >
                        <AccountCircleIcon />
                    </IconButton>
                    <UserMenu
                        state={userMenuState}
                        user={user}
                        client={activeClient}
                        clientSwitcherDialogController={
                            clientSwitchingEnabled ? clientSwitcherDialogController : null
                        }
                    />
                </Toolbar>
                {clientSwitcherDialogController.mount && (
                    <ClientSwitcherDialog
                        dialogProps={clientSwitcherDialogController.dialogProps}
                        clients={assignedClients}
                        selfAssignAllowed={selfAssignAllowed}
                    />
                )}
            </AppBar>
            <Box
                component="nav"
                sx={{
                    gridArea: "nav",
                    width: { md: drawerWidth },
                    flexShrink: { md: 0 },
                }}
            >
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: "block", md: "none" },
                        "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
                    }}
                >
                    {drawerContent}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: "none", md: "block" },
                        "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
                    }}
                    open
                >
                    {drawerContent}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    gridArea: "content",
                    overflow: "hidden",
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

export default Layout;
