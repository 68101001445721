import { BodyContainer } from "@/components/Layout";
import SupportResource from "@/components/SupportResource";
import { useQueryOptionsFactory } from "@/queries";
import { useActiveClient } from "@/routes/$clientId.tsx";
import HelpIcon from "@mui/icons-material/Help";
import { Box, Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import type { ReactNode } from "react";
import ActionRequestList from "./-components/ActionRequestList.tsx";
import AnnouncementsList from "./-components/AnnouncementsList.tsx";
import ClientDetails from "./-components/ClientDetails.tsx";

const Root = (): ReactNode => {
    const client = useActiveClient();
    const qof = useQueryOptionsFactory();
    const { data: actionRequests } = useSuspenseQuery(qof.dashboard.listActionRequests(client.id));
    const { data: announcements } = useSuspenseQuery(qof.dashboard.listAnnouncements(client.id));

    return (
        <BodyContainer
            header={
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h5" sx={{ mr: "auto" }}>
                        Dashboard
                    </Typography>

                    <SupportResource
                        type="dashboard"
                        renderButton={(open) => (
                            <Tooltip title="FAQ">
                                <IconButton onClick={open} edge="end">
                                    <HelpIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    />
                </Box>
            }
        >
            <Stack spacing={2}>
                {actionRequests.length > 0 && <ActionRequestList actionRequests={actionRequests} />}

                <ClientDetails client={client} />

                {announcements.length > 0 && (
                    <div>
                        <Divider sx={{ mb: 2 }} />
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Announcements
                        </Typography>
                        <AnnouncementsList announcements={announcements} />
                    </div>
                )}
            </Stack>
        </BodyContainer>
    );
};

export const Route = createFileRoute("/$clientId/")({
    component: Root,
    loader: async ({ context, params }) => {
        await Promise.all([
            context.queryClient.ensureQueryData(
                context.qof.dashboard.listActionRequests(params.clientId),
            ),
            context.queryClient.ensureQueryData(
                context.qof.dashboard.listAnnouncements(params.clientId),
            ),
        ]);
    },
});
