import type { AuthenticatedFetch } from "@/hooks/useAuthenticatedFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { queryOptions } from "@tanstack/react-query";
import {
    createDataSelector,
    createResourceCollectionSelector,
    createResourceSelector,
    handleJsonApiError,
} from "jsonapi-zod-query";
import { z } from "zod";
import { zj } from "zod-joda";

const settingsFileAttributesSchema = z.object({
    createdAt: zj.zonedDateTime(),
    objectKey: z.string().min(1),
    filename: z.string().min(1),
    description: z.string().min(1),
});

const settingsFilesSelector = createDataSelector(
    createResourceCollectionSelector({
        type: "disaster_recovery_settings_file",
        attributesSchema: settingsFileAttributesSchema,
    }),
);

export type DisasterRecoverySettingsFile = ReturnType<typeof settingsFilesSelector>[number];

const restorePointsAttributesSchema = z.object({
    createdAt: zj.zonedDateTime(),
    description: z.string().min(1),
    state: z.enum(["available", "unavailable"]),
    snapBackId: z.string().min(1),
});

const restorePointsSelector = createDataSelector(
    createResourceCollectionSelector({
        type: "disaster_recovery_restore_point",
        attributesSchema: restorePointsAttributesSchema,
    }),
);

export type DisasterRecoveryRestorePoint = ReturnType<typeof restorePointsSelector>[number];

const configurationAttributesSchema = z.object({
    modeDescription: z.string().min(1),
    packageSource: z.string().min(1),
    model: z.enum(["on_premise", "cross_region"]),
    identity: z.string().uuid(),
    additionalFolders: z.array(z.enum(["container", "database"])),
    protectingHost: z.object({
        id: z.string().min(1),
        name: z.string().min(1),
        domainName: z.string().min(1),
    }),
});

const configurationSelector = createDataSelector(
    createResourceSelector({
        type: "disaster_recovery_configuration",
        attributesSchema: configurationAttributesSchema,
    }),
);

export type DisasterRecoveryConfiguration = ReturnType<typeof configurationSelector>;

export const createDisasterRecoveryQueryOptionsFactory = (authFetch: AuthenticatedFetch) => ({
    getConfiguration: (clientId: string, hostId: string) =>
        queryOptions({
            queryKey: ["client", clientId, "host", hostId, "disaster-recovery", "configuration"],
            queryFn: async ({ signal }) => {
                const url = apiUrl(
                    `/clients/${clientId}/hosts/${hostId}/disaster-recovery/configuration`,
                );
                const response = await authFetch(url, { signal });
                await handleJsonApiError(response);
                return configurationSelector(await response.json());
            },
        }),
    listSettingsFiles: (clientId: string, hostId: string) =>
        queryOptions({
            queryKey: ["client", clientId, "host", hostId, "disaster-recovery", "settings-files"],
            queryFn: async ({ signal }) => {
                const url = apiUrl(
                    `/clients/${clientId}/hosts/${hostId}/disaster-recovery/settings-files`,
                );
                const response = await authFetch(url, { signal });
                await handleJsonApiError(response);
                return settingsFilesSelector(await response.json());
            },
        }),
    listRestorePoints: (clientId: string, hostId: string) =>
        queryOptions({
            queryKey: ["client", clientId, "host", hostId, "disaster-recovery", "restore-points"],
            queryFn: async ({ signal }) => {
                const url = apiUrl(
                    `/clients/${clientId}/hosts/${hostId}/disaster-recovery/restore-points`,
                );
                const response = await authFetch(url, { signal });
                await handleJsonApiError(response);
                return restorePointsSelector(await response.json());
            },
        }),
});
