import type { HostEvent } from "@/queries/host-event.ts";
import AlarmIcon from "@mui/icons-material/Alarm";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import PendingIcon from "@mui/icons-material/Pending";
import { Chip, type ChipProps } from "@mui/material";
import type { ReactNode } from "react";
import { match } from "ts-pattern";

type Props = {
    status: HostEvent["status"];
    size: ChipProps["size"];
};

const StatusChip = ({ status, size }: Props): ReactNode => {
    const [label, Icon] = match(status)
        .with("scheduled", () => ["Scheduled", AlarmIcon] as const)
        .with("in_progress", () => ["Quarter-hourly", PendingIcon] as const)
        .with("pending", () => ["Pending", PendingIcon] as const)
        .with("completed", () => ["Completed", DoneIcon] as const)
        .with("failed", () => ["Failed", ErrorIcon] as const)
        .exhaustive();

    return <Chip size={size} icon={<Icon />} label={label} />;
};

export default StatusChip;
