import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";

export type AuthenticatedFetch = typeof fetch;

const useAuthenticatedFetch = (): AuthenticatedFetch => {
    const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

    return useCallback(
        async (input: RequestInfo | URL, init?: RequestInit) => {
            let accessToken: string;

            try {
                accessToken = await getAccessTokenSilently();
            } catch {
                await loginWithRedirect({
                    appState: {
                        returnTo: window.location.pathname,
                    },
                });
                return new Promise(() => {
                    // Will never resolve
                });
            }

            const modifiedInit = init ?? {};
            modifiedInit.headers = new Headers(modifiedInit.headers);
            modifiedInit.headers.set("Authorization", `Bearer ${accessToken}`);

            return await fetch(input, modifiedInit);
        },
        [getAccessTokenSilently, loginWithRedirect],
    );
};

export default useAuthenticatedFetch;
