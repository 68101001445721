import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { type UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { handleJsonApiError } from "jsonapi-zod-query";

type CreateSideCarDeploymentValues = {
    productId: string;
    accountId: string;
    regionId: string;
    title: string;
    config: Record<string, unknown>;
};

export const useCreateSideCarDeploymentMutation = (
    clientId: string,
): UseMutationResult<void, Error, CreateSideCarDeploymentValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ productId, accountId, regionId, ...attributes }) => {
            const response = await fetch(apiUrl(`/clients/${clientId}/sidecar/deployments`), {
                method: "POST",
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
                body: JSON.stringify({
                    data: {
                        type: "sidecar_deployment",
                        attributes: attributes,
                        relationships: {
                            product: {
                                data: {
                                    id: productId,
                                    type: "sidecar_product",
                                },
                            },
                            account: {
                                data: {
                                    id: accountId,
                                    type: "aws_account",
                                },
                            },
                            region: {
                                data: {
                                    id: regionId,
                                    type: "aws_region",
                                },
                            },
                        },
                    },
                }),
            });
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["client", clientId, "sidecar", "deployments"],
            });
        },
    });
};

type UpdateSideCarDeploymentValues = {
    deploymentId: string;
    title: string;
    config: Record<string, unknown>;
};

export const useUpdateSideCarDeploymentMutation = (
    clientId: string,
): UseMutationResult<void, Error, UpdateSideCarDeploymentValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ deploymentId, ...attributes }) => {
            const response = await fetch(
                apiUrl(`/clients/${clientId}/sidecar/deployments/${deploymentId}`),
                {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                    },
                    body: JSON.stringify({
                        data: {
                            id: deploymentId,
                            type: "sidecar_deployment",
                            attributes,
                        },
                    }),
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["client", clientId, "sidecar", "deployments"],
            });
        },
    });
};

type DeleteSideCarDeploymentValues = {
    deploymentId: string;
};

export const useDeleteSideCarDeploymentMutation = (
    clientId: string,
): UseMutationResult<void, Error, DeleteSideCarDeploymentValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl(`/clients/${clientId}/sidecar/deployments/${values.deploymentId}`),
                {
                    method: "DELETE",
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["client", clientId, "sidecar", "deployments"],
            });
        },
    });
};
