import CardField from "@/components/CardField";
import useDialogController from "@/hooks/useDialogController.tsx";
import type {
    WebdirectAutoScalingConfiguration,
    WebdirectAutoScalingConfigurationAvailableOptions,
} from "@/queries/webdirect-auto-scaling.ts";
import SettingsIcon from "@mui/icons-material/Settings";
import { IconButton, Paper, Stack } from "@mui/material";
import type { ReactNode } from "react";
import EditConfigurationDialog from "./EditConfigurationDialog.tsx";

type Props = {
    configuration: WebdirectAutoScalingConfiguration;
    availableOptions: WebdirectAutoScalingConfigurationAvailableOptions;
};

const ConfigurationCard = ({ configuration, availableOptions }: Props): ReactNode => {
    const dialogController = useDialogController();

    return (
        <Paper sx={{ p: 2 }}>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    alignItems: { xs: "flex-start", lg: "center" },
                }}
            >
                <Stack
                    direction={{ xs: "column", lg: "row" }}
                    sx={{
                        columnGap: 4,
                        rowGap: 2,
                    }}
                >
                    <CardField label="Instance type" value={configuration.instanceType} />
                    <CardField
                        label="Cooldown time"
                        value={`${configuration.cooldownTime} minutes`}
                    />
                    <CardField label="Master users" value={configuration.masterUsers} />
                    <CardField
                        label="Utilization threshold"
                        value={`${configuration.utilizationThreshold}%`}
                    />
                    <CardField
                        label="Daily schedule"
                        value={configuration.dailySchedule ? "Enabled" : "Disabled"}
                    />
                </Stack>

                <IconButton onClick={dialogController.open} sx={{ ml: "auto" }}>
                    <SettingsIcon />
                </IconButton>
            </Stack>

            {dialogController.mount && (
                <EditConfigurationDialog
                    dialogProps={dialogController.dialogProps}
                    configuration={configuration}
                    availableOptions={availableOptions}
                />
            )}
        </Paper>
    );
};
export default ConfigurationCard;
