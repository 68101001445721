import type { ControlledDialogProps } from "@/hooks/useDialogController.tsx";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useSnackbar } from "notistack";
import { type FormEvent, type ReactNode, useCallback } from "react";

type Props = {
    dialogProps: ControlledDialogProps;
};

const SetupForm = ({ dialogProps }: Props): ReactNode => {
    const stripe = useStripe();
    const elements = useElements();
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = useCallback(
        async (event: FormEvent) => {
            event.preventDefault();

            if (!(stripe && elements)) {
                return;
            }

            const { error } = await stripe.confirmSetup({
                elements,
                confirmParams: {
                    return_url: window.location.href,
                },
            });

            if (error) {
                enqueueSnackbar(error.message, { variant: "error" });
            }
        },
        [stripe, elements, enqueueSnackbar],
    );

    return (
        <Dialog
            {...dialogProps}
            maxWidth="md"
            fullWidth
            PaperProps={{
                component: "form",
                onSubmit: handleSubmit,
            }}
        >
            <DialogTitle>Setup payment method</DialogTitle>
            <DialogContent>
                <PaymentElement />
            </DialogContent>
            <DialogActions>
                <Button onClick={dialogProps.onClose} color="inherit">
                    Close
                </Button>
                <Button type="submit">Submit</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SetupForm;
