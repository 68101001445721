import type { SnapBack } from "@/queries/snapback.ts";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import { Chip, type ChipProps, Tooltip } from "@mui/material";
import type { ReactNode } from "react";

type Props = {
    retained: SnapBack["retained"];
    size?: ChipProps["size"];
};

const RetainedChip = ({ retained, size }: Props): ReactNode => {
    const labelParts = [];
    const titleParts = [];

    if (retained.has("daily")) {
        labelParts.push("D");
        titleParts.push("daily");
    }

    if (retained.has("weekly")) {
        labelParts.push("W");
        titleParts.push("weekly");
    }

    if (retained.has("monthly")) {
        labelParts.push("M");
        titleParts.push("monthly");
    }

    if (labelParts.length === 0) {
        return null;
    }

    const lastTitleElement = titleParts.pop();
    const title = `Retained ${titleParts.join(", ")}${titleParts.length > 0 ? " and " : ""} ${lastTitleElement}`;

    return (
        <Tooltip title={title}>
            <Chip icon={<EventRepeatIcon />} label={labelParts.join(" / ")} size={size} />
        </Tooltip>
    );
};

export default RetainedChip;
