import CardField from "@/components/CardField";
import type { Host } from "@/queries/host.ts";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import ArrowCircleDownOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ModeStandbyIcon from "@mui/icons-material/ModeStandby";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import { Stack, type TypographyProps } from "@mui/material";
import type { ReactNode } from "react";

type Props = {
    state: Host["state"];
};

const StateCardField = ({ state }: Props): ReactNode => {
    let value: ReactNode;
    let valueColor: TypographyProps["color"] | undefined;

    switch (state.current) {
        case "running": {
            value = (
                <Stack
                    direction="row"
                    sx={{
                        alignItems: "center",
                        gap: 0.5,
                    }}
                >
                    <CheckCircleOutlineIcon fontSize="inherit" />
                    <span>Running</span>
                </Stack>
            );
            valueColor = "success.main";
            break;
        }
        case "starting": {
            value = (
                <Stack
                    direction="row"
                    sx={{
                        alignItems: "center",
                        gap: 0.5,
                    }}
                >
                    <ArrowCircleUpOutlinedIcon fontSize="inherit" />
                    <span>Starting</span>
                </Stack>
            );
            break;
        }
        case "stopping": {
            value = (
                <Stack
                    direction="row"
                    sx={{
                        alignItems: "center",
                        gap: 0.5,
                    }}
                >
                    <ArrowCircleDownOutlinedIcon fontSize="inherit" />
                    <span>Stopping</span>
                </Stack>
            );
            break;
        }
        case "stopped": {
            value = (
                <Stack
                    direction="row"
                    sx={{
                        alignItems: "center",
                        gap: 0.5,
                    }}
                >
                    <StopCircleOutlinedIcon fontSize="inherit" />
                    <span>Stopped</span>
                </Stack>
            );
            valueColor = "error.main";
            break;
        }
        case "standby": {
            value = (
                <Stack
                    direction="row"
                    sx={{
                        alignItems: "center",
                        gap: 0.5,
                    }}
                >
                    <ModeStandbyIcon fontSize="inherit" />
                    <span>Standby</span>
                </Stack>
            );
            valueColor = "warning.main";
            break;
        }
    }
    return <CardField label="State" value={value} valueColor={valueColor} />;
};
export default StateCardField;
