import type { ListHost } from "@/queries/host.ts";
import { useActiveClient } from "@/routes/$clientId.tsx";
import StatusChip from "@/routes/$clientId/hosts/-components/StatusChip.tsx";
import StorageIcon from "@mui/icons-material/Storage";
import { Box, Card, CardActionArea, CardContent, CardHeader, Divider, List } from "@mui/material";
import { useNavigate, useRouter } from "@tanstack/react-router";
import type { ReactNode } from "react";
import AgreementActionItem from "./Actions/AgreementActionItem";
import FmsLicenseActionItem from "./Actions/FmsLicenseActionItem";

type Props = {
    host: ListHost;
};

const HostCard = ({ host }: Props): ReactNode => {
    const { id: clientId } = useActiveClient();
    const navigate = useNavigate();
    const actions: ReactNode[] = [];
    const router = useRouter();

    if (host.agreement) {
        actions.push(<AgreementActionItem host={host} key="agreement" />);
    }

    if (host.licenses?.fms !== undefined) {
        actions.push(<FmsLicenseActionItem host={host} key="fms-license" />);
    }

    return (
        <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <CardActionArea
                sx={[
                    actions.length > 0
                        ? {
                              flexGrow: 0,
                          }
                        : {
                              flexGrow: 1,
                          },
                ]}
                onClick={() => {
                    void navigate({
                        to: "/$clientId/hosts/$hostId",
                        params: { clientId: clientId, hostId: host.id },
                    });
                }}
                onMouseEnter={() =>
                    router.preloadRoute({
                        to: "/$clientId/hosts/$hostId",
                        params: { clientId: clientId, hostId: host.id },
                    })
                }
                onTouchStart={() =>
                    router.preloadRoute({
                        to: "/$clientId/hosts/$hostId",
                        params: { clientId: clientId, hostId: host.id },
                    })
                }
            >
                <Box
                    sx={{
                        height: "100%",
                    }}
                >
                    <CardHeader
                        avatar={<StorageIcon />}
                        title={host.domainName}
                        subheader={host.name}
                    />
                    <CardContent>
                        <StatusChip status={host.status} />
                    </CardContent>
                </Box>
            </CardActionArea>
            {actions.length > 0 && (
                <Box>
                    <Divider />
                    <List>{actions}</List>
                </Box>
            )}
        </Card>
    );
};
export default HostCard;
