import { useUnassignClientMutation } from "@/mutations/client.ts";
import type { ListClient } from "@/queries/client.ts";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { getErrorMessage } from "@/utils/api.ts";
import BusinessIcon from "@mui/icons-material/Business";
import RemoveIcon from "@mui/icons-material/Remove";
import { IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";

type Props = {
    client: ListClient;
    onSelect: (clientId: string) => void;
    selfAssignAllowed: boolean;
};

const ClientListItem = ({ client, onSelect, selfAssignAllowed }: Props): ReactNode => {
    const activeClient = useActiveClient();
    const unassignMutation = useUnassignClientMutation();
    const { enqueueSnackbar } = useSnackbar();

    const unassign = () => {
        unassignMutation.mutate(
            {
                clientId: client.id,
            },
            {
                onError: (error) => {
                    enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                },
            },
        );
    };

    return (
        <ListItem
            key={client.id}
            disablePadding
            secondaryAction={
                selfAssignAllowed && activeClient.id !== client.id ? (
                    <IconButton edge="end" onClick={unassign} disabled={unassignMutation.isPending}>
                        <RemoveIcon />
                    </IconButton>
                ) : undefined
            }
        >
            <ListItemButton
                onClick={() => {
                    onSelect(client.id);
                }}
                sx={{ color: activeClient.id === client.id ? "primary.main" : undefined }}
            >
                <ListItemIcon sx={{ color: "inherit" }}>
                    <BusinessIcon />
                </ListItemIcon>
                <ListItemText
                    primaryTypographyProps={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                    }}
                >
                    {client.name}
                </ListItemText>
            </ListItemButton>
        </ListItem>
    );
};

export default ClientListItem;
