import AutoFillGrid from "@/components/AutoFillGrid";
import { BodyContainer } from "@/components/Layout";
import { InlineSpinner } from "@/components/Spinner";
import SupportResource from "@/components/SupportResource";
import { useQueryOptionsFactory } from "@/queries";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import HelpIcon from "@mui/icons-material/Help";
import SearchIcon from "@mui/icons-material/Search";
import {
    Alert,
    Box,
    Button,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";
import { Link, createFileRoute } from "@tanstack/react-router";
import { type ReactNode, useMemo, useState } from "react";
import DeploymentCard from "./-components/DeploymentCard.tsx";

const Root = (): ReactNode => {
    const { clientId } = Route.useParams();
    const qof = useQueryOptionsFactory();
    const { data: deployments } = useSuspenseQuery(qof.sideCar.listDeployments(clientId));
    const [filter, setFilter] = useState("");

    const filteredDeployments = useMemo(
        () =>
            deployments.filter((deployment) => {
                if (!filter) {
                    return true;
                }

                const normalizedFilter = filter.toLocaleLowerCase();
                const title = deployment.title.toLocaleLowerCase();

                return title.includes(normalizedFilter);
            }),
        [filter, deployments],
    );

    let content: ReactNode;

    if (deployments.length === 0) {
        content = <Alert severity="info">You haven't deployed any SideCars yet.</Alert>;
    } else {
        const searchField = (
            <TextField
                label="Search"
                size="small"
                value={filter}
                onChange={(event) => {
                    setFilter(event.target.value);
                }}
                slotProps={{
                    input: {
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: filter ? (
                            <InputAdornment position="end">
                                <IconButton
                                    size="small"
                                    edge="end"
                                    onClick={() => {
                                        setFilter("");
                                    }}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        ) : undefined,
                    },
                }}
                sx={{ mb: 2, width: "100%", maxWidth: 400 }}
            />
        );

        if (filteredDeployments.length === 0) {
            content = (
                <>
                    {searchField}
                    <Typography>No SideCars match your search.</Typography>
                </>
            );
        } else {
            content = (
                <>
                    {searchField}
                    <AutoFillGrid minWidth={400}>
                        {filteredDeployments.map((deployment) => (
                            <DeploymentCard deployment={deployment} key={deployment.id} />
                        ))}
                    </AutoFillGrid>
                </>
            );
        }
    }

    return (
        <BodyContainer
            header={
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h5" sx={{ mr: "auto" }}>
                        SideCars
                    </Typography>
                    <Button
                        component={Link}
                        size="small"
                        startIcon={<AddIcon />}
                        to="/$clientId/sidecar/create"
                    >
                        Add SideCar
                    </Button>
                    <SupportResource
                        type="sidecar"
                        renderButton={(open) => (
                            <Tooltip title="SideCar Setup / Configuration">
                                <IconButton onClick={open} edge="end" sx={{ ml: 2 }}>
                                    <HelpIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    />
                </Box>
            }
        >
            {content}
        </BodyContainer>
    );
};
export const Route = createFileRoute("/$clientId/sidecar/")({
    component: Root,
    pendingComponent: () => (
        <BodyContainer>
            <InlineSpinner />
        </BodyContainer>
    ),
    loader: async ({ context, params }) => {
        await context.queryClient.ensureQueryData(
            context.qof.sideCar.listDeployments(params.clientId),
        );
    },
});
