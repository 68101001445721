import type { DialogController } from "@/hooks/useDialogController.tsx";
import { useDeleteSetupIntentMutation } from "@/mutations/setup-intent.ts";
import type { StripeSetupIntent } from "@/queries/stripe.ts";
import { LoadingButton } from "@mui/lab";
import { Button, Card, CardActions, CardContent, CardHeader, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";

type Props = {
    clientId: string;
    setupIntent: StripeSetupIntent;
    setupDialogController: DialogController;
    refresh: () => void;
    isRefreshing: boolean;
};

const SetupIntentCard = ({
    clientId,
    setupIntent,
    setupDialogController,
    refresh,
    isRefreshing,
}: Props): ReactNode => {
    const deleteSetupIntentMutation = useDeleteSetupIntentMutation(clientId);
    const { enqueueSnackbar } = useSnackbar();

    let content: ReactNode;
    let actions: ReactNode;

    const refreshButton = (
        <LoadingButton size="small" loading={isRefreshing} onClick={refresh}>
            Refresh
        </LoadingButton>
    );

    switch (setupIntent.status) {
        case "processing": {
            content = (
                <Typography>
                    Hold tight, we are setting up your payment method for future payments. We'll
                    email you when your payment method has been successfully set up.
                </Typography>
            );
            break;
        }

        case "canceled": {
            content = <Typography>The setup has been canceled.</Typography>;
            actions = <CardActions>{refreshButton}</CardActions>;
            break;
        }

        case "succeeded": {
            content = (
                <Typography>
                    The setup has succeeded and we will display your payment method momentarily.
                </Typography>
            );
            actions = <CardActions>{refreshButton}</CardActions>;
            break;
        }

        case "requires_action":
        case "requires_confirmation":
        case "requires_payment_method": {
            content = <Typography>Setup of a new payment method has been started.</Typography>;
            actions = (
                <CardActions>
                    <Button
                        size="small"
                        onClick={() => {
                            setupDialogController.open();
                        }}
                    >
                        Continue
                    </Button>
                    <LoadingButton
                        size="small"
                        color="error"
                        loading={deleteSetupIntentMutation.isPending}
                        onClick={() => {
                            deleteSetupIntentMutation.mutate(undefined, {
                                onError: () => {
                                    enqueueSnackbar("Failed to cancel setup", { variant: "error" });
                                },
                            });
                        }}
                    >
                        Cancel
                    </LoadingButton>
                </CardActions>
            );
            break;
        }
    }

    return (
        <Card sx={{ flexGrow: 1, minWidth: "50%" }}>
            <CardHeader title="Payment Setup" />
            <CardContent>{content}</CardContent>
            {actions}
        </Card>
    );
};

export default SetupIntentCard;
