import iconImage from "@/assets/favicon.svg";
import { Box, CircularProgress } from "@mui/material";
import type { ReactNode } from "react";

type Props = {
    size?: number;
};

const Spinner = ({ size = 60 }: Props): ReactNode => {
    const logoSize = size * 0.75;
    const logoOffset = (size - logoSize) / 2;

    return (
        <Box
            sx={{
                position: "relative",
                width: size,
                height: size,
            }}
        >
            <Box
                component="img"
                src={iconImage}
                sx={{
                    width: logoSize,
                    height: logoSize,
                    position: "absolute",
                    left: logoOffset,
                    top: logoOffset,
                }}
            />
            <CircularProgress
                size={size}
                sx={{ position: "absolute", left: 0, top: 0 }}
                color="secondary"
            />
        </Box>
    );
};

export default Spinner;
