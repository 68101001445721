import { useAssignClientMutation } from "@/mutations/client.ts";
import type { ListClient } from "@/queries/client.ts";
import { Route } from "@/routes/$clientId.tsx";
import { Autocomplete, TextField } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { type ReactNode, type SyntheticEvent, useRef, useState } from "react";

const SelfAssign = (): ReactNode => {
    const [filterValue, setFilterValue] = useState("");
    const timerRef = useRef<number>();
    const clientsQuery = useQuery({
        ...Route.useRouteContext().qof.client.list({
            filterName: filterValue,
            filterAssigned: false,
        }),
        enabled: filterValue !== "",
    });
    const [client, setClient] = useState<ListClient | null>(null);
    const assignClientMutation = useAssignClientMutation();

    const handleInputChange = (_event: SyntheticEvent, newInputValue: string) => {
        clearTimeout(timerRef.current);

        timerRef.current = setTimeout(() => {
            setFilterValue(newInputValue);
        }, 200);
    };

    return (
        <Autocomplete
            value={client}
            onChange={(_event, value) => {
                setClient(value);

                if (!value) {
                    return;
                }

                assignClientMutation.mutate(
                    {
                        clientId: value.id,
                    },
                    {
                        onSettled: () => {
                            setClient(null);
                        },
                    },
                );
            }}
            disabled={assignClientMutation.isPending}
            options={clientsQuery.data?.data ?? []}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            loading={filterValue !== "" && clientsQuery.isPending}
            noOptionsText={filterValue === "" ? "Type to search" : "No clients found"}
            renderInput={(params) => <TextField label="Self assign" {...params} />}
            onInputChange={handleInputChange}
            disableClearable={client !== null}
        />
    );
};

export default SelfAssign;
