import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import { setupIntentSelector } from "@/queries/stripe.ts";
import { apiUrl } from "@/utils/api.ts";
import type { SetupIntent } from "@stripe/stripe-js";
import { type UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { handleJsonApiError } from "jsonapi-zod-query";

export const useCreateSetupIntentMutation = (
    clientId: string,
): UseMutationResult<SetupIntent, Error, void> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async () => {
            const response = await fetch(apiUrl(`/clients/${clientId}/stripe/setup-intent`), {
                method: "POST",
            });
            await handleJsonApiError(response);
            return setupIntentSelector(await response.json()) as unknown as SetupIntent;
        },
        onSuccess: (data) => {
            queryClient.setQueryData(["client", clientId, "stripe", "setup-intent"], data);
        },
    });
};

export const useDeleteSetupIntentMutation = (
    clientId: string,
): UseMutationResult<void, Error, void> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async () => {
            const response = await fetch(apiUrl(`/clients/${clientId}/stripe/setup-intent`), {
                method: "DELETE",
            });
            await handleJsonApiError(response);
        },
        onSuccess: () => {
            queryClient.setQueryData(["client", clientId, "stripe", "setup-intent"], null);
        },
    });
};
