import type { DisasterRecoveryRestorePoint } from "@/queries/disaster-recovery.ts";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { Chip, type ChipProps } from "@mui/material";
import type { ReactNode } from "react";
import { match } from "ts-pattern";

type Props = {
    state: DisasterRecoveryRestorePoint["state"];
    size?: ChipProps["size"];
};

const StateChip = ({ state, size }: Props): ReactNode => {
    const [label, Icon, color] = match(state)
        .with("available", () => ["Available", CheckCircleIcon, "success"] as const)
        .with("unavailable", () => ["Unavailable", ErrorIcon, "error"] as const)
        .exhaustive();

    return <Chip label={label} icon={<Icon />} color={color} size={size} />;
};

export default StateChip;
