import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { IconButton, InputAdornment, TextField, type TextFieldProps } from "@mui/material";
import {
    type KeyboardEvent,
    type ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import {
    type Control,
    type FieldPathByValue,
    type FieldValues,
    type RegisterOptions,
    useController,
} from "react-hook-form";

type Props<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPathByValue<TFieldValues, number> = FieldPathByValue<TFieldValues, number>,
> = Omit<TextFieldProps, "error" | "onChange" | "onBlur" | "value" | "inputRef" | "type"> & {
    control: Control<TFieldValues>;
    name: TName;
    rules?: Omit<
        RegisterOptions<NoInfer<TFieldValues>, NoInfer<TName>>,
        "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
    >;
    min?: number;
    max?: number;
};

const IntegerField = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPathByValue<TFieldValues, number> = FieldPathByValue<TFieldValues, number>,
>({
    control,
    name,
    rules,
    disabled,
    min,
    max,
    ...rest
}: Props<TFieldValues, TName>): ReactNode => {
    const { field, fieldState } = useController({ control, name, rules });
    const [innerValue, setInnerValue] = useState(
        typeof field.value === "number" ? field.value.toString() : "",
    );

    useEffect(() => {
        setInnerValue(typeof field.value === "number" ? field.value.toString() : "");
    }, [field.value]);

    const helperText = fieldState.error?.message ?? rest.helperText;

    const decrement = useCallback(() => {
        const currentValue = Number.parseInt(innerValue, 10);

        if (Number.isNaN(currentValue)) {
            field.onChange(max ?? min ?? 0);
            return;
        }

        const newValue = min !== undefined ? Math.max(min, currentValue - 1) : currentValue - 1;
        setInnerValue(newValue.toString());
        field.onChange(newValue);
    }, [innerValue, min, max, field.onChange]);

    const increment = useCallback(() => {
        const currentValue = Number.parseInt(innerValue, 10);

        if (Number.isNaN(currentValue)) {
            field.onChange(min ?? max ?? 0);
            return;
        }

        const newValue = max !== undefined ? Math.min(max, currentValue + 1) : currentValue + 1;
        setInnerValue(newValue.toString());
        field.onChange(newValue);
    }, [innerValue, min, max, field.onChange]);

    const minusKeyAllowed = useMemo(() => min === undefined || min < 0, [min]);

    return (
        <TextField
            {...rest}
            disabled={disabled}
            name={name}
            error={Boolean(fieldState.error)}
            value={innerValue}
            inputRef={field.ref}
            helperText={helperText}
            inputMode="numeric"
            autoComplete="off"
            slotProps={{
                input: {
                    onFocus: (event) => {
                        if (event.defaultPrevented || disabled) {
                            return;
                        }

                        const target = event.currentTarget;
                        const length = target.value.length;
                        target.setSelectionRange(length, length);
                    },
                    onChange: (event) => {
                        if (event.defaultPrevented || disabled) {
                            return;
                        }

                        const inputValue = event.target.value;
                        setInnerValue(inputValue);

                        if (inputValue.length === 0) {
                            field.onChange(null);
                            return;
                        }

                        const parsedValue = Number.parseInt(inputValue, 10);

                        if (!Number.isNaN(parsedValue)) {
                            field.onChange(parsedValue);
                        }
                    },
                    onBlur: (event) => {
                        if (event.defaultPrevented || disabled) {
                            return;
                        }

                        const currentValue = Number.parseInt(innerValue, 10);

                        if (!Number.isNaN(currentValue)) {
                            const clampedValue = Math.max(
                                min ?? Number.MIN_SAFE_INTEGER,
                                Math.min(max ?? Number.MAX_SAFE_INTEGER, currentValue),
                            );
                            setInnerValue(clampedValue.toString());
                            field.onChange(clampedValue);
                        } else {
                            setInnerValue("");
                            field.onChange(null);
                        }

                        field.onBlur();
                    },
                    onKeyDown: (event) => {
                        if (
                            event.defaultPrevented ||
                            disabled ||
                            isKeyAllowed(event, minusKeyAllowed)
                        ) {
                            return;
                        }

                        event.preventDefault();

                        if (event.key === "ArrowUp") {
                            increment();
                        } else if (event.key === "ArrowDown") {
                            decrement();
                        } else if (event.key === "Home" && min !== undefined) {
                            field.onChange(min);
                        } else if (event.key === "End" && max !== undefined) {
                            field.onChange(max);
                        }
                    },
                    onPaste(event) {
                        if (event.defaultPrevented || disabled) {
                            return;
                        }

                        event.preventDefault();

                        const clipboardData = event.clipboardData || window.Clipboard;
                        const pastedData = clipboardData.getData("text/plain");
                        const parsedNumber = Number.parseInt(pastedData, 10);

                        if (!Number.isNaN(parsedNumber)) {
                            const clampedValue = Math.max(
                                min ?? Number.MIN_SAFE_INTEGER,
                                Math.min(max ?? Number.MAX_SAFE_INTEGER, parsedNumber),
                            );
                            setInnerValue(clampedValue.toString());
                            field.onChange(clampedValue);
                        }
                    },
                    startAdornment: (
                        <InputAdornment position="start">
                            <IconButton
                                edge="start"
                                onClick={decrement}
                                disabled={
                                    disabled ||
                                    (min !== undefined &&
                                        field.value !== null &&
                                        field.value <= min)
                                }
                            >
                                <RemoveIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                edge="end"
                                onClick={increment}
                                disabled={
                                    disabled ||
                                    (max !== undefined &&
                                        field.value !== null &&
                                        field.value >= max)
                                }
                            >
                                <AddIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                },
            }}
        />
    );
};

const isKeyAllowed = (
    event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    minusAllowed: boolean,
): boolean => {
    const nativeEvent = event.nativeEvent;
    const inputValue = event.currentTarget.value;
    const selectionStart = event.currentTarget.selectionStart;
    const selectionEnd = event.currentTarget.selectionEnd;
    const isAllSelected = selectionStart === 0 && selectionEnd === inputValue.length;
    let isAllowedNonNumericKey = false;

    if (event.key === "-" && minusAllowed) {
        const isMinusHighlighted =
            selectionStart === 0 && selectionEnd === 1 && inputValue[0] === "-";
        isAllowedNonNumericKey = !inputValue.includes("-") || isAllSelected || isMinusHighlighted;
    }

    const isLatinNumeral = /^[0-9]$/.test(event.key);
    const isNavigateKey = [
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
        "Tab",
        "Enter",
    ].includes(event.key);

    return (
        nativeEvent.isComposing ||
        event.altKey ||
        event.ctrlKey ||
        event.metaKey ||
        isAllowedNonNumericKey ||
        isLatinNumeral ||
        isNavigateKey
    );
};

export default IntegerField;
