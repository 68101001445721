import type { ClientPerson } from "@/queries/client.ts";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import {
    Card,
    CardHeader,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import type { ReactNode } from "react";

type Props = {
    title: string;
    person: ClientPerson;
};

const PersonCard = ({ title, person }: Props): ReactNode => (
    <Card sx={{ width: "100%" }}>
        <CardHeader avatar={<ContactPageIcon />} title={title} subheader={person.name} />
        {(person.emailAddress || person.phoneNumber) && (
            <>
                <Divider />
                <List>
                    {person.emailAddress && (
                        <ListItem>
                            <ListItemIcon>
                                <EmailIcon />
                            </ListItemIcon>
                            <ListItemText>{person.emailAddress}</ListItemText>
                        </ListItem>
                    )}
                    {person.phoneNumber && (
                        <ListItem>
                            <ListItemIcon>
                                <PhoneIcon />
                            </ListItemIcon>
                            <ListItemText>{person.phoneNumber}</ListItemText>
                        </ListItem>
                    )}
                </List>
            </>
        )}
    </Card>
);

export default PersonCard;
