import type { SnapBack } from "@/queries/snapback.ts";
import { Divider, List, type SxProps } from "@mui/material";
import { Fragment, type ReactNode } from "react";
import SnapBackListItem from "./SnapBackListItem.tsx";

type Props = {
    snapBacks: SnapBack[];
    sx?: SxProps;
};

const SnapBackList = ({ snapBacks, sx }: Props): ReactNode => {
    const hasOnlineSnapBack = snapBacks.some((snapBack) => snapBack.status === "online");
    const hasPendingSnapBack = snapBacks.some((snapBack) => snapBack.status === "pending");

    return (
        <List disablePadding sx={sx}>
            {snapBacks.map((snapBack, index) => (
                <Fragment key={snapBack.id}>
                    {index > 0 && <Divider />}
                    <SnapBackListItem
                        snapBack={snapBack}
                        hasOnlineSnapBack={hasOnlineSnapBack}
                        hasPendingSnapBack={hasPendingSnapBack}
                    />
                </Fragment>
            ))}
        </List>
    );
};

export default SnapBackList;
