import useDialogController from "@/hooks/useDialogController.tsx";
import type { ListHost } from "@/queries/host.ts";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import type { ReactNode } from "react";
import { createPortal } from "react-dom";
import invariant from "tiny-invariant";
import SelectLicenseDialog from "./SelectLicenseDialog.tsx";

type Props = {
    host: ListHost;
};

const FmsLicenseActionItem = ({ host }: Props): ReactNode => {
    const dialogController = useDialogController();
    invariant(host.licenses?.fms !== undefined, "FMS license missing in host");

    switch (host.licenses.fms) {
        case "buy":
            return (
                <ListItem>
                    <ListItemIcon>
                        <CardMembershipIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="The license is managed by Soliant"
                        secondary="FileMaker Server License"
                    />
                </ListItem>
            );

        case "byol":
            return (
                <ListItem>
                    <ListItemIcon>
                        <CardMembershipIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="You supplied your own license"
                        secondary="FileMaker Server License"
                    />
                </ListItem>
            );
    }

    return (
        <>
            <ListItemButton
                onClick={() => {
                    dialogController.open();
                }}
            >
                <ListItemIcon>
                    <CardMembershipIcon color="warning" />
                </ListItemIcon>
                <ListItemText
                    primary="Select FileMaker Server License"
                    primaryTypographyProps={{ color: "warning.main" }}
                />
            </ListItemButton>

            {dialogController.mount &&
                createPortal(
                    <SelectLicenseDialog host={host} dialogProps={dialogController.dialogProps} />,
                    document.body,
                )}
        </>
    );
};

export default FmsLicenseActionItem;
