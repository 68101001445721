import ErrorCard from "@/components/ErrorCard";
import { FullPageSpinner } from "@/components/Spinner";
import type { QueryOptionsFactory } from "@/queries";
import { Container } from "@mui/material";
import type { QueryClient } from "@tanstack/react-query";
import { createRootRouteWithContext } from "@tanstack/react-router";

export type RootRouterContext = {
    queryClient: QueryClient;
    qof: QueryOptionsFactory;
};

export const Route = createRootRouteWithContext<RootRouterContext>()({
    pendingComponent: FullPageSpinner,
    errorComponent: ({ error }) => (
        <Container maxWidth="md" sx={{ my: 2 }}>
            <ErrorCard error={error} />
        </Container>
    ),
    loader: async ({ context }) => {
        await context.queryClient.ensureQueryData(context.qof.client.list());
    },
});
