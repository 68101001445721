import type { DisasterRecoverySettingsFile } from "@/queries/disaster-recovery.ts";
import { Divider, List, type SxProps } from "@mui/material";
import { Fragment, type ReactNode } from "react";
import SettingsFileListItem from "./SettingsFileListItem.tsx";

type Props = {
    settingsFiles: DisasterRecoverySettingsFile[];
    sx?: SxProps;
};

const SettingsFileList = ({ settingsFiles, sx }: Props): ReactNode => (
    <List disablePadding sx={sx}>
        {settingsFiles.map((settingsFile, index) => (
            <Fragment key={settingsFile.id}>
                {index > 0 && <Divider />}
                <SettingsFileListItem settingsFile={settingsFile} />
            </Fragment>
        ))}
    </List>
);

export default SettingsFileList;
