import type { AuthenticatedFetch } from "@/hooks/useAuthenticatedFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { queryOptions } from "@tanstack/react-query";
import {
    createDataSelector,
    createResourceCollectionSelector,
    handleJsonApiError,
} from "jsonapi-zod-query";
import { z } from "zod";
import { zj } from "zod-joda";

const attributesSchema = z.object({
    description: z.string().min(1),
    reoccurrence: z.enum([
        "once",
        "quarter_hourly",
        "hourly",
        "daily",
        "weekly",
        "monthly",
        "quarterly",
    ]),
    requiresReboot: z.boolean(),
    status: z.enum(["scheduled", "in_progress", "pending", "completed", "failed"]),
    runsAt: zj.zonedDateTime(),
});

const hostEventsSelector = createDataSelector(
    createResourceCollectionSelector({
        type: "host_event",
        attributesSchema,
    }),
);

export type HostEvent = ReturnType<typeof hostEventsSelector>[number];

export const createHostEventQueryOptionsFactory = (authFetch: AuthenticatedFetch) => ({
    list: (clientId: string, hostId: string) =>
        queryOptions({
            queryKey: ["client", clientId, "host", hostId, "events"],
            queryFn: async ({ signal }) => {
                const url = apiUrl(`/clients/${clientId}/hosts/${hostId}/events`);
                const response = await authFetch(url, { signal });
                await handleJsonApiError(response);
                return hostEventsSelector(await response.json());
            },
        }),
});
