import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { type UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { handleJsonApiError } from "jsonapi-zod-query";

type AssignClientValues = {
    clientId: string;
};

export const useAssignClientMutation = (): UseMutationResult<void, Error, AssignClientValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(apiUrl(`/clients/${values.clientId}/self-assign`), {
                method: "POST",
            });
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["clients"],
            });
        },
    });
};

type UnassignClientValues = {
    clientId: string;
};

export const useUnassignClientMutation = (): UseMutationResult<
    void,
    Error,
    UnassignClientValues
> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(apiUrl(`/clients/${values.clientId}/self-unassign`), {
                method: "POST",
            });
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["clients"],
            });
        },
    });
};
