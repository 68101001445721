import type { ControlledDialogProps } from "@/hooks/useDialogController.tsx";
import { useMountSnapBackMutation } from "@/mutations/snapback.ts";
import { useQueryOptionsFactory } from "@/queries";
import type { SnapBack } from "@/queries/snapback.ts";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { useActiveHost } from "@/routes/$clientId/hosts/$hostId.tsx";
import { getErrorMessage } from "@/utils/api.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    ListItem,
    ListItemText,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { RhfAutocomplete } from "mui-rhf-integration";
import { useSnackbar } from "notistack";
import { type ReactNode, useCallback } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z.object({
    targetHost: z.object({
        id: z.string(),
        name: z.string(),
    }),
});

type FieldValues = z.input<typeof schema>;
type TransformedValues = z.output<typeof schema>;

type Props = {
    dialogProps: ControlledDialogProps;
    snapBack: SnapBack;
};

const MountSnapBackDialog = ({ dialogProps, snapBack }: Props): ReactNode => {
    const { id: clientId } = useActiveClient();
    const host = useActiveHost();
    const qof = useQueryOptionsFactory();
    const mountTargetsQuery = useQuery(qof.snapBack.listMountTargets(clientId, host.id));
    const mountMutation = useMountSnapBackMutation(clientId);
    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema),
        defaultValues: {
            targetHost: host,
        },
    });
    const { enqueueSnackbar } = useSnackbar();

    if (mountTargetsQuery.isError) {
        throw mountTargetsQuery.error;
    }

    const handleSubmit = useCallback(
        (values: TransformedValues) => {
            mountMutation.mutate(
                {
                    hostId: host.id,
                    snapBackId: snapBack.id,
                    targetHostId: values.targetHost.id,
                },
                {
                    onSuccess: (message) => {
                        enqueueSnackbar(message, { variant: "success" });
                        dialogProps.onClose();
                    },
                    onError: (error) => {
                        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                    },
                },
            );
        },
        [enqueueSnackbar, host.id, snapBack.id, mountMutation, dialogProps.onClose],
    );

    return (
        <Dialog
            {...dialogProps}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                component: "form",
                noValidate: true,
                onSubmit: form.handleSubmit(handleSubmit),
            }}
        >
            <DialogTitle>Mount SnapBack</DialogTitle>
            <DialogContent dividers>
                <DialogContentText sx={{ mb: 3 }}>
                    Mounting a SnapBack creates a new volume with the contents representing the
                    source volume at the point in time captured. Once created, the volume is
                    attached to the target host specified as an additional volume. You will receive
                    an email confirming availability and instructions on how to securely access the
                    SnapBack. Only one SnapBack per host can be mounted at a time.{" "}
                </DialogContentText>

                <RhfAutocomplete
                    textFieldProps={{
                        label: "Target host",
                        helperText: "Host to mount this SnapBack to",
                        required: true,
                    }}
                    loading={mountTargetsQuery.isPending}
                    options={mountTargetsQuery.data ?? []}
                    control={form.control}
                    name="targetHost"
                    disableClearable
                    freeSolo={false}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.domainName}
                    renderOption={({ key, ...optionProps }, option) => (
                        <ListItem key={key} {...optionProps}>
                            <ListItemText primary={option.domainName} secondary={option.name} />
                        </ListItem>
                    )}
                />
            </DialogContent>
            <DialogActions>
                <Button color="inherit" onClick={dialogProps.onClose}>
                    Cancel
                </Button>
                <LoadingButton loading={mountMutation.isPending} type="submit">
                    Mount
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default MountSnapBackDialog;
