import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import { acknowledgmentSchema, apiUrl } from "@/utils/api.ts";
import { type UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { handleJsonApiError } from "jsonapi-zod-query";

export type RestartComponentValues = {
    hostId: string;
    component: "scripting_engine" | "wpe" | "data_api" | "odata_api" | "otto_fms";
};

export const useRestartComponentMutation = (
    clientId: string,
): UseMutationResult<string, Error, RestartComponentValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            let endpoint: string;

            switch (values.component) {
                case "scripting_engine": {
                    endpoint = "restart-scripting-engine";
                    break;
                }

                case "wpe": {
                    endpoint = "restart-wpe";
                    break;
                }

                case "data_api": {
                    endpoint = "restart-data-api";
                    break;
                }

                case "odata_api": {
                    endpoint = "restart-odata-api";
                    break;
                }

                case "otto_fms": {
                    endpoint = "restart-otto-fms";
                    break;
                }
            }

            const response = await fetch(
                apiUrl(`/clients/${clientId}/hosts/${values.hostId}/filemaker-server/${endpoint}`),
                { method: "POST" },
            );
            await handleJsonApiError(response);
            return acknowledgmentSchema.parse(await response.json()).meta.message;
        },
        onSuccess: async (_data, values) => {
            await queryClient.invalidateQueries({
                queryKey: ["client", clientId, "host", values.hostId, "filemaker-server"],
                exact: true,
            });
        },
    });
};

export type ResetAdminPasswordValues = {
    hostId: string;
    password: string;
};

export const useResetAdminPasswordMutation = (
    clientId: string,
): UseMutationResult<string, Error, ResetAdminPasswordValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl(
                    `/clients/${clientId}/hosts/${values.hostId}/filemaker-server/admin-password-reset`,
                ),
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                    },
                    body: JSON.stringify({
                        data: {
                            type: "admin_password_reset",
                            attributes: {
                                password: values.password,
                            },
                        },
                    }),
                },
            );
            await handleJsonApiError(response);
            return acknowledgmentSchema.parse(await response.json()).meta.message;
        },
        onSuccess: async (_data, values) => {
            await queryClient.invalidateQueries({
                queryKey: ["client", clientId, "host", values.hostId, "filemaker-server"],
                exact: true,
            });
        },
    });
};

export type ToggleHostedFtpAccessValues = {
    hostId: string;
    enabled: boolean;
};

export const useToggleHostedFtpAccessMutation = (
    clientId: string,
): UseMutationResult<string, Error, ToggleHostedFtpAccessValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl(
                    `/clients/${clientId}/hosts/${values.hostId}/filemaker-server/hosted-ftp-access-toggle`,
                ),
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                    },
                    body: JSON.stringify({
                        data: {
                            type: "hosted_ftp_access_toggle",
                            attributes: {
                                enabled: values.enabled,
                            },
                        },
                    }),
                },
            );
            await handleJsonApiError(response);
            return acknowledgmentSchema.parse(await response.json()).meta.message;
        },
        onSuccess: async (_data, values) => {
            await queryClient.invalidateQueries({
                queryKey: ["client", clientId, "host", values.hostId, "filemaker-server"],
                exact: true,
            });
        },
    });
};

export type UpdateWebDirectCustomHomeUrlValues = {
    hostId: string;
    url: string | null;
};

export const useUpdateWebDirectCustomHomeUrlMutation = (
    clientId: string,
): UseMutationResult<string, Error, UpdateWebDirectCustomHomeUrlValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl(
                    `/clients/${clientId}/hosts/${values.hostId}/filemaker-server/webdirect-custom-home-url-update`,
                ),
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                    },
                    body: JSON.stringify({
                        data: {
                            type: "webdirect_custom_home_url_update",
                            attributes: {
                                url: values.url,
                            },
                        },
                    }),
                },
            );
            await handleJsonApiError(response);
            return acknowledgmentSchema.parse(await response.json()).meta.message;
        },
        onSuccess: async (_data, values) => {
            await queryClient.invalidateQueries({
                queryKey: ["client", clientId, "host", values.hostId, "filemaker-server"],
                exact: true,
            });
        },
    });
};
