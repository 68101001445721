import type { ControlledDialogProps } from "@/hooks/useDialogController.tsx";
import { useUpdateWebDirectCustomHomeUrlMutation } from "@/mutations/filemaker-server.ts";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { getErrorMessage } from "@/utils/api.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
} from "@mui/material";
import { RhfTextField } from "mui-rhf-integration";
import { useSnackbar } from "notistack";
import { type ReactNode, useCallback } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z.object({
    url: z.union([z.string().url(), z.literal("").transform(() => null)]),
});

type FieldValues = z.input<typeof schema>;
type TransformedValues = z.output<typeof schema>;

type Props = {
    hostId: string;
    url: string | null;
    dialogProps: ControlledDialogProps;
};

const ChangeWebDirectCustomHomeUrlDialog = ({ hostId, url, dialogProps }: Props): ReactNode => {
    const { id: clientId } = useActiveClient();
    const mutation = useUpdateWebDirectCustomHomeUrlMutation(clientId);
    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema),
        defaultValues: {
            url: url ?? "",
        },
    });
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = useCallback(
        (values: TransformedValues) => {
            mutation.mutate(
                {
                    hostId,
                    ...values,
                },
                {
                    onSuccess: (message) => {
                        enqueueSnackbar(message, { variant: "success" });
                        dialogProps.onClose();
                    },
                    onError: (error) => {
                        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                    },
                },
            );
        },
        [enqueueSnackbar, hostId, mutation, dialogProps.onClose],
    );

    const watchedUrl = form.watch("url");

    return (
        <Dialog
            {...dialogProps}
            maxWidth="xs"
            fullWidth
            PaperProps={{
                component: "form",
                noValidate: true,
                onSubmit: form.handleSubmit(handleSubmit),
            }}
        >
            <DialogTitle>WebDirect custom home URL</DialogTitle>
            <DialogContent dividers>
                <RhfTextField
                    control={form.control}
                    name="url"
                    label="URL"
                    fullWidth
                    slotProps={{
                        input: {
                            endAdornment: watchedUrl && (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => {
                                            form.setValue("url", "");
                                        }}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        },
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button color="inherit" onClick={dialogProps.onClose}>
                    Cancel
                </Button>
                <LoadingButton loading={mutation.isPending} type="submit">
                    Update
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default ChangeWebDirectCustomHomeUrlDialog;
