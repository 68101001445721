import type { Host } from "@/queries/host.ts";
import CheckIcon from "@mui/icons-material/Check";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ModeStandbyIcon from "@mui/icons-material/ModeStandby";
import PendingIcon from "@mui/icons-material/Pending";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import StartIcon from "@mui/icons-material/Start";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import UpdateIcon from "@mui/icons-material/Update";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Chip, Tooltip } from "@mui/material";
import type { ReactNode } from "react";

type Props = {
    status: Host["status"];
};

const StatusChip = ({ status }: Props): ReactNode => {
    switch (status) {
        case "estimate":
            return (
                <Tooltip title="Customer action required">
                    <Chip
                        icon={<WarningAmberIcon />}
                        color="warning"
                        size="small"
                        label="Estimate"
                    />
                </Tooltip>
            );

        case "staging":
            return (
                <Tooltip title="Administration setup in progress">
                    <Chip icon={<PendingIcon />} color="info" size="small" label="Staging" />
                </Tooltip>
            );

        case "queued":
            return (
                <Tooltip title="Orchestration in progress">
                    <Chip icon={<PendingIcon />} color="info" size="small" label="Queued" />
                </Tooltip>
            );

        case "active":
            return (
                <Tooltip title="Host is operational">
                    <Chip icon={<CheckIcon />} color="success" size="small" label="Active" />
                </Tooltip>
            );

        case "standby":
            return (
                <Tooltip title="Host is on standby">
                    <Chip icon={<ModeStandbyIcon />} color="info" size="small" label="Standby" />
                </Tooltip>
            );

        case "suspended":
            return (
                <Tooltip title="Host has been suspended">
                    <Chip
                        icon={<WarningAmberIcon />}
                        color="warning"
                        size="small"
                        label="Suspended"
                    />
                </Tooltip>
            );

        case "stopped":
            return (
                <Tooltip title="Host is paused/stopped">
                    <Chip icon={<StopCircleIcon />} color="info" size="small" label="Stopped" />
                </Tooltip>
            );

        case "halted":
            return (
                <Tooltip title="Host has been disabled">
                    <Chip icon={<DoDisturbOnIcon />} color="warning" size="small" label="Halted" />
                </Tooltip>
            );

        case "updating":
            return (
                <Tooltip title="Host is currently updating">
                    <Chip icon={<UpdateIcon />} color="info" size="small" label="Updating" />
                </Tooltip>
            );

        case "launching":
            return (
                <Tooltip title="Host is currently starting">
                    <Chip icon={<StartIcon />} color="info" size="small" label="Launching" />
                </Tooltip>
            );

        case "decommissioned":
            return (
                <Tooltip title="Host hash been decommissioned">
                    <Chip
                        icon={<ReportGmailerrorredIcon />}
                        color="error"
                        size="small"
                        label="Decommissioned"
                    />
                </Tooltip>
            );

        case "terminated":
            return (
                <Tooltip title="Host has been terminated">
                    <Chip
                        icon={<ReportGmailerrorredIcon />}
                        color="error"
                        size="small"
                        label="Terminated"
                    />
                </Tooltip>
            );

        case "error":
            return (
                <Tooltip title="Please contact support">
                    <Chip icon={<ErrorOutlineIcon />} color="error" size="small" label="Error" />
                </Tooltip>
            );
    }
};

export default StatusChip;
