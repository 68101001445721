import type { Announcement } from "@/queries/dashboard.ts";
import { dateFormatter } from "@/utils/format.ts";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import { Card, CardContent, CardHeader, Link, Stack, Typography } from "@mui/material";
import type { ReactNode } from "react";
import Markdown from "react-markdown";

type Props = {
    announcements: Announcement[];
};

const AnnouncementsList = ({ announcements }: Props): ReactNode => (
    <Stack spacing={2}>
        {announcements.map((announcement) => (
            <Card key={announcement.id}>
                <CardHeader
                    avatar={<AnnouncementIcon />}
                    title={announcement.title}
                    subheader={announcement.date.format(dateFormatter)}
                />
                <CardContent>
                    <Markdown
                        skipHtml
                        allowedElements={["p", "ol", "ul", "li", "a", "strong"]}
                        unwrapDisallowed
                        components={{
                            p: ({ children }) => (
                                <Typography
                                    variant="inherit"
                                    sx={{ mb: 2, "&:last-child": { mb: 0 } }}
                                >
                                    {children}
                                </Typography>
                            ),
                            a: ({ href, children }) => (
                                <Link href={href} target="_blank" rel="noreferrer">
                                    {children}
                                </Link>
                            ),
                        }}
                    >
                        {announcement.content}
                    </Markdown>
                </CardContent>
            </Card>
        ))}
    </Stack>
);

export default AnnouncementsList;
