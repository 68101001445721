import { InlineSpinner } from "@/components/Spinner";
import type { ControlledDialogProps } from "@/hooks/useDialogController.tsx";
import { useSubmitSupportRequestMutation } from "@/mutations/support-resource.ts";
import { useQueryOptionsFactory } from "@/queries";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { getErrorMessage } from "@/utils/api.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import LinkIcon from "@mui/icons-material/Link";
import { LoadingButton } from "@mui/lab";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { RhfTextField } from "mui-rhf-integration";
import { useSnackbar } from "notistack";
import { type ReactNode, useCallback } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z.object({
    details: z.string().trim().min(1),
});

type FieldValues = z.input<typeof schema>;
type TransformedValues = z.output<typeof schema>;

type Props = {
    dialogProps: ControlledDialogProps;
    type: string;
    hostId?: string;
};

const SupportResourceDialog = ({ dialogProps, type, hostId }: Props): ReactNode => {
    const { id: clientId } = useActiveClient();
    const qof = useQueryOptionsFactory();
    const query = useQuery(qof.supportResource.get(clientId, type, hostId));
    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema),
    });
    const mutation = useSubmitSupportRequestMutation(clientId);
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = useCallback(
        (values: TransformedValues) => {
            if (!query.data?.formEnabled) {
                return;
            }

            mutation.mutate(
                {
                    type,
                    hostId,
                    ...values,
                },
                {
                    onSuccess: (message) => {
                        enqueueSnackbar(message, { variant: "success" });
                        dialogProps.onClose();
                    },
                    onError: (error) => {
                        enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                    },
                },
            );
        },
        [query.data, type, hostId, enqueueSnackbar, mutation, dialogProps.onClose],
    );

    if (query.isError) {
        throw query.error;
    }

    if (query.isPending) {
        return (
            <Dialog {...dialogProps} maxWidth="sm" fullWidth>
                <DialogContent>
                    <InlineSpinner />
                </DialogContent>
            </Dialog>
        );
    }

    const resource = query.data;

    return (
        <Dialog
            {...dialogProps}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                component: "form",
                noValidate: true,
                onSubmit: form.handleSubmit(handleSubmit),
            }}
        >
            <DialogTitle>{resource.title}</DialogTitle>
            <DialogContent dividers>
                <DialogContentText sx={{ whiteSpace: "pre-line" }}>
                    {resource.message}
                </DialogContentText>

                {resource.links.length > 0 && (
                    <>
                        <Divider sx={{ my: 2 }} />
                        <Typography gutterBottom>Further links</Typography>

                        <List disablePadding sx={{ mx: -3 }} dense>
                            {resource.links.map((link) => (
                                <ListItemButton
                                    key={link.url}
                                    component="a"
                                    href={link.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    sx={{ px: 3 }}
                                >
                                    <ListItemIcon>
                                        <LinkIcon />
                                    </ListItemIcon>
                                    <ListItemText>{link.label}</ListItemText>
                                </ListItemButton>
                            ))}
                        </List>
                    </>
                )}

                {resource.formEnabled && (
                    <>
                        <Divider sx={{ my: 2 }} />
                        <Typography sx={{ mb: 2 }}>Submit request</Typography>

                        <RhfTextField
                            control={form.control}
                            name="details"
                            label="Details"
                            multiline
                            fullWidth
                            minRows={5}
                        />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="inherit" onClick={dialogProps.onClose}>
                    Close
                </Button>
                {resource.formEnabled && (
                    <LoadingButton loading={mutation.isPending} type="submit">
                        Submit
                    </LoadingButton>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default SupportResourceDialog;
