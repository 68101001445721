import ConfirmDialog from "@/components/ConfirmDialog";
import useDialogController from "@/hooks/useDialogController.tsx";
import { useUnmountSnapBackMutation } from "@/mutations/snapback.ts";
import type { SnapBack } from "@/queries/snapback.ts";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { useActiveHost } from "@/routes/$clientId/hosts/$hostId.tsx";
import { getErrorMessage } from "@/utils/api.ts";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
    IconButton,
    type IconButtonProps,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
} from "@mui/material";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { useSnackbar } from "notistack";
import { type ReactNode, useCallback } from "react";
import { useConfirm } from "react-confirm-hook";
import { match } from "ts-pattern";
import EditSnapBackDialog from "./EditSnapBackDialog.tsx";
import MountSnapBackDialog from "./MountSnapBackDialog.tsx";

type Props = {
    snapBack: SnapBack;
    edge?: IconButtonProps["edge"];
    hasOnlineSnapBack: boolean;
    hasPendingSnapBack: boolean;
};

const SnapBackActionButton = ({
    snapBack,
    edge,
    hasOnlineSnapBack,
    hasPendingSnapBack,
}: Props): ReactNode => {
    const { id: clientId } = useActiveClient();
    const { id: hostId } = useActiveHost();
    const menuState = usePopupState({
        variant: "popover",
        popupId: `snapback-${snapBack.id}-menu`,
    });
    const stateChangeDisabled =
        hasPendingSnapBack || (hasOnlineSnapBack && snapBack.status !== "online");
    const confirm = useConfirm(ConfirmDialog);
    const unmountMutation = useUnmountSnapBackMutation(clientId);
    const { enqueueSnackbar } = useSnackbar();
    const mountDialogController = useDialogController();
    const editDialogController = useDialogController();

    const handleMount = useCallback(() => {
        menuState.close();
        mountDialogController.open();
    }, [menuState, mountDialogController]);

    const handleUnmount = useCallback(() => {
        menuState.close();

        confirm({
            title: "Unmount SnapBack",
            message: (
                <>
                    <Typography sx={{ mb: 2 }}>
                        Unmounting a Snapback immediately removes access via FTP. Any active FTP
                        sessions will be disconnected. You will receive an email with a confirmation
                        of the unmount. Upon completion you may mount other Snapbacks on this
                        instance.
                    </Typography>
                    <Typography>Are you sure you want to unmount SnapBack?</Typography>
                </>
            ),
            onConfirm: async () => {
                try {
                    const message = await unmountMutation.mutateAsync({
                        hostId,
                        snapBackId: snapBack.id,
                    });
                    enqueueSnackbar(message, { variant: "success" });
                } catch (error) {
                    enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                }
            },
        });
    }, [menuState.close, confirm, hostId, snapBack.id, unmountMutation, enqueueSnackbar]);

    const handleEdit = useCallback(() => {
        menuState.close();
        editDialogController.open();
    }, [menuState, editDialogController]);

    const stateMenuItem = match(snapBack.status)
        .with("online", () => (
            <MenuItem disabled={stateChangeDisabled} onClick={handleUnmount}>
                <ListItemIcon>
                    <FileUploadIcon />
                </ListItemIcon>
                <ListItemText>Unmount</ListItemText>
            </MenuItem>
        ))
        .with("available", () => (
            <MenuItem disabled={stateChangeDisabled} onClick={handleMount}>
                <ListItemIcon>
                    <FileDownloadIcon />
                </ListItemIcon>
                <ListItemText>Mount</ListItemText>
            </MenuItem>
        ))
        .otherwise(() => null);

    return (
        <>
            <IconButton edge={edge} {...bindTrigger(menuState)}>
                <MoreVertIcon />
            </IconButton>

            <Menu {...bindMenu(menuState)}>
                {stateMenuItem}
                <MenuItem onClick={handleEdit}>
                    <ListItemIcon>
                        <EditIcon />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                </MenuItem>
            </Menu>

            {mountDialogController.mount && (
                <MountSnapBackDialog
                    dialogProps={mountDialogController.dialogProps}
                    snapBack={snapBack}
                />
            )}

            {editDialogController.mount && (
                <EditSnapBackDialog
                    dialogProps={editDialogController.dialogProps}
                    snapBack={snapBack}
                />
            )}
        </>
    );
};

export default SnapBackActionButton;
