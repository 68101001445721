import useAuthenticatedFetch from "@/hooks/useAuthenticatedFetch.ts";
import type { WeeklySchedule } from "@/queries/dynamic-docket.ts";
import { acknowledgmentSchema, apiUrl } from "@/utils/api.ts";
import { type UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { handleJsonApiError } from "jsonapi-zod-query";

export type UpdateConfigurationValues = {
    hostId: string;
    scheduleEnabled?: boolean;
    weeklySchedule?: WeeklySchedule;
};

export const useUpdateConfigurationMutation = (
    clientId: string,
): UseMutationResult<string, Error, UpdateConfigurationValues> => {
    const fetch = useAuthenticatedFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const { hostId, ...rest } = values;
            const response = await fetch(
                apiUrl(`/clients/${clientId}/hosts/${hostId}/dynamic-docket/configuration`),
                {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                    },
                    body: JSON.stringify({
                        data: {
                            id: hostId,
                            type: "dynamic_docket_configuration",
                            attributes: rest,
                        },
                    }),
                },
            );
            await handleJsonApiError(response);
            return acknowledgmentSchema.parse(await response.json()).meta.message;
        },
        onSuccess: async (_data, values) => {
            await queryClient.invalidateQueries({
                queryKey: ["client", clientId, "host", values.hostId, "dynamic-docket"],
            });
        },
    });
};
