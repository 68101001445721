import type {
    DisasterRecoveryConfiguration,
    DisasterRecoveryRestorePoint,
} from "@/queries/disaster-recovery.ts";
import { Divider, List, type SxProps } from "@mui/material";
import { Fragment, type ReactNode } from "react";
import RestorePointListItem from "./RestorePointListItem.tsx";

type Props = {
    restorePoints: DisasterRecoveryRestorePoint[];
    configuration: DisasterRecoveryConfiguration;
    sx?: SxProps;
};

const RestorePointList = ({ restorePoints, configuration, sx }: Props): ReactNode => (
    <List disablePadding sx={sx}>
        {restorePoints.map((event, index) => (
            <Fragment key={event.id}>
                {index > 0 && <Divider />}
                <RestorePointListItem restorePoint={event} configuration={configuration} />
            </Fragment>
        ))}
    </List>
);

export default RestorePointList;
