import { Stack, Typography } from "@mui/material";
import { getProperty } from "dot-prop";
import type { ReactNode } from "react";
import type { DisplayGroup } from "../../-utils/layout.ts";
import OutputsValueCardField from "./OutputsValueCardField.tsx";

type Props = {
    displayGroup: DisplayGroup<"outputs">;
    values: Record<string, unknown>;
};

const OutputsDisplayGroup = ({ displayGroup, values }: Props): ReactNode => (
    <div>
        <Typography variant="h6" sx={{ mb: 1 }}>
            {displayGroup.title}
        </Typography>

        <Stack spacing={2}>
            {displayGroup.properties.map((property) => (
                <OutputsValueCardField
                    key={property.key}
                    valueProperty={property}
                    value={getProperty(values, property.path)}
                />
            ))}
        </Stack>
    </div>
);

export default OutputsDisplayGroup;
